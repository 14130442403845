import moment from 'moment'
import { store } from './redux/store'
import {
    makeStyles,
    Theme
} from "@material-ui/core";
import { PrimaryColor, SERVER_IP } from './variables';
import axios from 'axios';

function dateFormat(date, format = "DD/MM/YYYY") {
    return !!date ? moment(date).format(format) : ""
}

function dateTimeFormat(date, format = "DD/MM/YYYY hh:mm A") {
    return !!date ? moment(date).format(format) : ""
    // return moment(date).utcOffset("+08:00").format(format)
}

function getDateTime() {
    return moment().utcOffset("+08:00").format("YYYY-MM-DD HH:mm:ss")
}

function getCurrentUserID() {
    return store.getState().userRedux.userId
}

function getSideMenu() {
    return store.getState().userRedux.sideMenu
}

function getCurrentUserName() {
    return store.getState().userRedux.userName
}

function getLoggedUserRole() {
    return store.getState().userRedux.isStaff
}

function getTitleId() {
    return store.getState().userRedux.TitleID
}

function isSuperUser() {
    return store.getState().userRedux.isStaff && (store.getState().userRedux.accessControlID === 1 || store.getState().userRedux.accessControlID === 8)
}

function isDeveloper() {
    return store.getState().userRedux.isStaff && store.getState().userRedux.accessControlID === 8
}

function isProgClaimEligible() {
    return (store.getState().userRedux.accessControlID === 1 || store.getState().userRedux.accessControlID === 9)
}

function isStatusReportEligible() {
    return store.getState().userRedux.accessControlID === 1 || store.getState().userRedux.accessControlID === 7 || store.getState().userRedux.accessControlID === 8 || store.getState().userRedux.accessControlID === 9
}

function isLoggedUserStaff() {
    return store.getState().userRedux.isStaff;
}

const base64Converter = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let result = reader.result?.toString();

        result = result?.split("base64,").pop();
        resolve(result);
    };
    reader.onerror = error => {
        console.log(error)
        reject(error)
    }
});

const removeBadgeStyle = {
    cursor: 'pointer',
    position: 'absolute'
}

const base64ConverterWithoutCleanup = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let result = reader.result?.toString();

        // result = result?.replace("data:image/jpeg;base64,", "") || "" ;
        // result = result?.replace("data:image/jpg;base64,", "") || "" ;
        // result = result?.replace("data:image/png;base64,", "") || "" ;
        // result = result?.replace("data:application/pdf;base64,", "") || "" ;
        resolve(result);
    };
    reader.onerror = error => {
        console.log(error)
        reject(error)
    }
});

function isValidUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

function cleanBase64Data(data) {
    data = data.replace("data:image/jpeg;base64,", "");
    data = data.replace("data:image/jpg;base64,", "");
    data = data.replace("data:image/png;base64,", "");
    data = data.replace("data:application/pdf;base64,", "");

    return data
}

const inputFieldVariant = "standard"
const acceptedFiles = ".png,.jpg,.jpeg,.pdf"
const imagesOnly = ".png,.jpg,.jpeg"
const siteImages = ".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .pdf"

const checkIfFileIsAccepted = (file) => {
    return file?.type?.includes("image") || file?.type?.includes("pdf")
}

const checkIfFileIsPDF = (file) => {
    return file?.type?.includes("pdf")
}

const checkIfFileIsImage = (file) => {
    return file?.type?.includes("image")
}

const camelCaseToSentence = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const ExcelHeaderStyle = {
    style: { font: { sz: "16", bold: true } },
    width: { wpx: 125 }
}

const ExcelDataStyle = {
    style: { font: { sz: "14" } }
}


const generateDays = (startDate, endDate, time = "", params = {
    weekDays: [],
    type: "",
    days: []
}) => {

    const weekDays = {
        "Sunday": 0,
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
    }
    const dateFormat = "YYYY-MM-DD"
    const timeFormat = "HH:mm:ss"
    const dateTimeFormat = `${dateFormat} ${timeFormat}`

    var arr = Array<string>();

    var start = moment(startDate);
    var init = moment(startDate)
    var end = moment(endDate);

    const addToArray = (tmp) => {
        arr.push(moment(tmp.format(dateFormat) + ' ' + time, dateTimeFormat).format(dateTimeFormat));
    }

    switch (params.type) {
        case "Weekly":
            end = moment(endDate).add(1, 'day')
            params?.weekDays?.forEach(function (day) {
                let tmp = start.clone().day(weekDays[day]);
                if (tmp.isSameOrAfter(start, 'd') && tmp.isSameOrBefore(end)) {
                    addToArray(tmp)
                }
                while (tmp.isSameOrBefore(end)) {
                    tmp.add(7, 'days');
                    tmp.isSameOrBefore(end) && addToArray(tmp)
                }
            })

            return arr;

        case "Bi-Monthly":
            params?.weekDays?.forEach(function (day) {
                let tmp = start.clone().day(weekDays[day]);
                if (tmp.isSameOrAfter(start, 'd') && tmp.isSameOrBefore(end)) {
                    addToArray(tmp)
                }
                while (tmp.isSameOrBefore(end)) {
                    tmp.add(14, 'days');
                    tmp.isSameOrBefore(end) && addToArray(tmp)
                }
            })

            return arr;

        case "Monthly1":

            while (
                end > start ||
                start.format("M") === end.format("M")
            ) {
                params?.days?.forEach(function (day) {
                    let d = moment(`${start.format("YYYY-MM")}-${day}`)
                    if (d.isSameOrAfter(init.format("YYYY-MM-DD")) && d.isSameOrBefore(end.format("YYYY-MM-DD"))) {
                        addToArray(d)
                    }
                });
                start.add(1, "month");
            }
            return arr

        case "Quarterly1":

            while (
                end > start ||
                start.format("M") === end.format("M")
            ) {
                params?.days?.forEach(function (day) {
                    let d = moment(`${start.format("YYYY-MM")}-${day}`)
                    if (d.isSameOrAfter(init.format("YYYY-MM-DD"))) {
                        addToArray(d)
                    }
                });
                start.add(3, "month");
            }
            return arr

        case "Monthly":

            end = moment(endDate).add(1, 'day')
            params?.weekDays?.forEach(function (day) {
                let tmp = start.clone().day(weekDays[day]);
                if (tmp.isSameOrAfter(start, 'd') && tmp.isSameOrBefore(end)) {
                    addToArray(tmp)
                }
                while (tmp.isSameOrBefore(end)) {
                    tmp.add(7, 'days');
                    // tmp.add(1, "month");
                    tmp.isSameOrBefore(end) && addToArray(tmp)
                }
            })

            return arr;

        case "Quarterly":

            end = moment(endDate).add(1, 'day')
            params?.weekDays?.forEach(function (day) {
                let tmp = start.clone().day(weekDays[day]);
                if (tmp.isSameOrAfter(start, 'd') && tmp.isSameOrBefore(end)) {
                    addToArray(tmp)
                }
                while (tmp.isSameOrBefore(end)) {
                    tmp.add(7, 'days');
                    // tmp.add(3, "month");
                    tmp.isSameOrBefore(end) && addToArray(tmp)
                }
            })

            return arr;

        case "Yearly":

            while (
                end > start ||
                start.format("Y") === end.format("Y")
            ) {
                params?.days?.forEach(function (day) {
                    let d = moment(`${start.format("YYYY-MM")}-${day}`)
                    if (d.isSameOrAfter(init.format("YYYY-MM-DD"))) {
                        addToArray(d)
                    }
                });
                start.add(12, "month");
            }
            return arr

        default:
            return arr;
    }
}

const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const getStatusColor = (value) => {
    if (value == 0)
        return 'lightgreen'
    if (value > 9)
        return 'red'
    else
        return 'yellow'
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxWidth: '90%',
    maxHeight: '90vh'
};

const checkIfMonthOver = (data) => {
    var d = moment(data);
    return d.isBefore(moment().format("YYYY-MM-DD"))
}

const getMax = (a1, a2) => {
    let n = a1.length > a2.length ? a1.length : a2.length
    if (n <= 4) return [1]
    else return Array.from({ length: Math.ceil(n / 4) }, (_, i) => i + 1)
}

const validateAmount = (value: string) => {
    if (value) {
        const matches = value.toString().match(
            /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
        ) || []
        return matches?.length > 0 || "Max 2 Decimals Only";
    }
    else {
        return "Enter Valid Amount"
    }
};

const useStyles = makeStyles((theme: Theme) => ({
    dropDown: {
        width: "inherit",
        height: 33,
        borderRadius: 4,
        backGround: theme.palette.background.paper,
        boxSizing: "border-box",
        fontSize: 14,
    },
    dateInput: {
        fontSize: 14,
        width: "inherit",
        padding: '0',
        '& .MuiInputBase-formControl': {
            height: 33
        }
    },
    numberInput: {
        fontSize: 14,
        width: "inherit",
        textAlign: 'right',
        padding: '0',
        '& .MuiInputBase-formControl': {
            height: 33
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'right'
        }
    },
    table: {
        borderRadius: 8,
        width: "100%",
        fontWeight: "normal",
        "& .MuiTableCell-root": {
            border: "1px solid #ddd",
            textAlign: "center",
            padding: "0px",
        },
        "& .MuiTableCell-head": {
            // background: theme.palette.info.dark,
            padding: "5px 0",
        },
        "& .MuiTableCell-body": {
            textAlign: "center",
            color: "gray",
            fontWeight: "normal",
            padding: "5px 0",
        },
    },
    container: {
        padding: theme.spacing(5, 5),
        textAlign: "center",
        maxWidth: "500px",
    },
    textField: {
        width: 250,
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            width: '80%',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: theme.shape.borderRadius * 2,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            width: '50%',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: theme.shape.borderRadius * 2,
        },
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            width: '20%',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: theme.shape.borderRadius * 2,
        },
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            width: '20%',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: theme.shape.borderRadius * 2,
        },
    },
    closebtn: {
        position: 'absolute',
        right: '25px',
        color: '#5C5C5C',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
    },
    tableCell: {
        fontSize: '20px',
        border: '2px solid'
    },
    headerCell: {
        fontSize: '24px',
        border: '2px solid',
        textAlign: 'center',
        fontWeight: 'bold',
        backgroundColor: PrimaryColor
    },
}));

function getBase64DataFromURL(url) {
    return axios.post(`${SERVER_IP}BlobToBase64/getAsABase64String`, {
        fileName: url
    })
        .then(response => {
            return response.data?.data
        })
        .catch(error => {
            console.error('Error Occured', error);
            return ""
        });

}

export {
    getDateTime,
    getCurrentUserID,
    base64Converter,
    base64ConverterWithoutCleanup,
    dateFormat,
    dateTimeFormat,
    isValidUrl,
    cleanBase64Data,
    inputFieldVariant,
    acceptedFiles,
    checkIfFileIsAccepted,
    checkIfFileIsImage,
    checkIfFileIsPDF,
    imagesOnly,
    getLoggedUserRole,
    camelCaseToSentence,
    getCurrentUserName,
    getSideMenu,
    generateDays,
    weekDays,
    getStatusColor,
    isSuperUser,
    siteImages,
    removeBadgeStyle,
    isDeveloper,
    isLoggedUserStaff,
    modalStyle,
    isProgClaimEligible,
    checkIfMonthOver,
    isStatusReportEligible,
    getMax,
    validateAmount,
    useStyles,
    getTitleId,
    getBase64DataFromURL
}