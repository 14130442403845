import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux'
import { persistor,store } from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
        <PersistGate persistor={persistor}>
          <App />       
        </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'));