// @ts-nocheck
import {

  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Badge, FormLabel, Radio, RadioGroup, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { base64Converter, getCurrentUserID, getDateTime, inputFieldVariant, isLoggedUserStaff, removeBadgeStyle } from "../../utils";
import pdfIcon from '../../assert/pdf_icon.png'
import docIcon from '../../assert/document_icon.png'
import ImageViewer from "../../assert/ImageViewer";
import { useForm, Controller } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";


export const UpdateProgressiveClaim = () => {

  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);
  const [removedFiles, setRemovedFiles] = useState<Array<any>>([]);
  const [existingFiles, setExistingFiles] = useState<Array<any>>(state.FileURL);
  const [claimDate, setClaimDate] = useState<any>(state.ReportedDate);

  const { handleSubmit, control, setValue } = useForm();

  const isStaff = isLoggedUserStaff()

  const updateProgressiveClaim = async (data) => {

    let files = await uploadedFiles?.map(async d => {
      let t = await base64Converter(d)
      return {
        data: t,
        fileName: d.name
      }
    })

    Promise.all(files).then(function (results) {
      // console.log(results)
      console.log(removedFiles)
      let temp = {
        ...state,
        ...data,
        ReportedDate : claimDate,
        ProgressiveClaimURL: results,
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        toBeRemoved: removedFiles
      }

      console.log(temp)
      setLoading(true)
      axios.put(`${SERVER_IP}progressiveClaimReport/update?ProgressiveClaimReportID=${temp.ProgressiveClaimReportID}`, temp)
        .then((res) => {
          toast.success("ProgressiveClaim Successfully Updated");
        }).then(() => navigate("/ProgressiveClaim"))
        .catch((res) => {
          toast.error("Couldn't Update ProgressiveClaim");
        }).finally(() => setLoading(false))
    })
  };


  useEffect(() => {
    let temp = existingFiles.map((d, index) => {
      return {
        ...d,
        id: index
      }
    })
    setExistingFiles(temp)
  }, []);


  const handleFileRemoval = (data) => {
    let temp = removedFiles
    temp.push(data)
    setRemovedFiles(temp)

    temp = existingFiles.filter(d => d.id !== data.id)
    setExistingFiles(temp)
    if(temp.length === 0){
      document.getElementById("ProgressiveClaimURL").value = ''
    }
  }

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(updateProgressiveClaim)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update ProgressiveClaim"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>

                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Claim Date"
                        inputFormat="dd/MM/yyyy"
                        value={claimDate}
                        onChange={(newValue) => {
                          setClaimDate(newValue)
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="ClaimDate"
                          name="ClaimDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography>ProgressiveClaim Image (Image, PDF, Word, Excel, PPT)</Typography>
                    <input
                      type="file"
                      accept={".pdf"}
                      id="ProgressiveClaimURL"
                      name="ProgressiveClaimURL"
                      multiple
                      onChange={async (e) => {
                        if (!!e.target.files?.length) {
                          let pendingFiles = []
                          let existingFiles1 = [...uploadedFiles.map(f => f.name), ...existingFiles.map(f => f.name)]
                          var inp = document.getElementById('ProgressiveClaimURL');
                          for (var i = 0; i < inp.files.length; ++i) {
                            if (!existingFiles1.includes(inp.files.item(i).name))
                              pendingFiles.push(inp.files.item(i))
                          }
                          if (pendingFiles.length === 0) {
                            toast.error("File(s) with same name already added")
                            e.stopPropagation();
                            return
                          }
                          setUploadedFiles([...uploadedFiles, ...pendingFiles])
                        }
                      }}
                    />
                  </Grid>


                  <Container>
                    <Row>
                      {
                        existingFiles.map((d, index) => {

                          if (d?.data?.toLowerCase().includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>

                  <Container>
                    <Row>

                      {
                        uploadedFiles?.map((d, index) => {


                          if (d?.type?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>


                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Update ProgressiveClaim"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/ProgressiveClaim")}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
