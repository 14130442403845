function setUserData(data){
    return {
        type : "SET_USER_DATA",
        payload : data
    }
}

function setSideMenu(data){
    return {
        type : "SET_SIDEMENU",
        payload : data
    }
}

function userLogout(data){
    return {
        type : "LOGOUT"
    }
}



export {
    setUserData,
    setSideMenu,
    userLogout
}