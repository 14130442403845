//@ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { Button, Select, MenuItem } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { dateFormat, dateTimeFormat, isSuperUser } from '../../utils';


export default function WorkOrderTable() {
  const { state } = useLocation();
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const fetchWorkOrder = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}workOrder?pageSize=${pageSize}&currentPage=${currentPage+1}&filter=${JSON.stringify(queryOptions?.filterModel?.items || [])}`
      )
      .then(async (res) => {
        let temp = res.data?.dataToDisplay || []
        if (temp.length) {
          // let workersData = await (await axios.get(`${SERVER_IP}workersbyPO`)).data

          // temp.map(data => {
          //   data["Workers"] = []
          //   workersData.map(worker => {
          //     if (worker.WorkOrderID === data.WorkOrderID)
          //       data.Workers.push(worker.StaffName)
          //   })
          // })
          temp = {
            dataToDisplay : temp,
            totalCount : res?.data?.totalCount
          }
          // console.log(temp)
          setWorkOrders(temp)
        }
        // setWorkOrders(res.data)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch WorkOrders!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'WorkOrderID',
      headerName: 'Work Order Number',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'CreatedType',
      headerName: 'Created Type',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Description',
      headerName: 'Site Zone',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'SiteName',
      headerName: 'Site',
      width: 100,
      flex: 1,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'WorkTypeName',
      headerName: 'WorkType ',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'WorkStatus',
      headerName: 'WorkStatus',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'RequestedStartDate',
      headerName: 'Requested Start Date',
      width: 100,
      renderCell: (row) => row.value ? dateFormat(row.value) : "N/A"
    },
    {
      field: 'RequestedEndDate',
      headerName: 'Requested End Date',
      width: 100,
      renderCell: (row) => row.value ? dateFormat(row.value) : "N/A"
    },
    {
      field: 'AssignedDateTime',
      headerName: 'Assigned DateTime',
      width: 150,
      renderCell: (row) => row.value ? dateTimeFormat(row.value) : "N/A"
    },
    {
      field: 'Workers',
      headerName: 'Assigned Staffs',
      width: 150,
      valueGetter: (params) => `${params.value?.map(d => d)?.join(", ")}`,
      renderCell: (row) => (
        <>
          {
            row.row.Workers?.length > 0 ?
              <Select value={100}>
                <MenuItem value={100} style={{ display: 'none' }}>Click To View</MenuItem>
                {
                  row.row.Workers?.map((data, index) => <MenuItem disabled key={index} style={{ color: '#2a2a2a', opacity: 1 }} value={data?.StaffName}>{data?.StaffName}</MenuItem>)
                }
              </Select>
              :
              "NA"
          }

        </>
      )
    },
    {
      field: 'WOInvoice',
      headerName: 'Invoices',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Action',
      headerName: 'Action Buttons',
      sortable: false,
      filterable: false,
      width: 200,
      hide: !isSuperUser(),
      disableExport: true,
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateWorkOrder', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize: pageSize,
                  currentPage: currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>Edit</Button>
            {
              row.row.WorkStatus === "Void" &&
              <Button
                style={{ marginLeft: '10px' }}
                onClick={(e) => {
                  if (window.confirm('Are You Sure to delete ?')) {
                    deleteWO(row.id)
                  }
                  e.stopPropagation()
                }} color='secondary' variant='contained'>Delete</Button>
            }
          </div>
        )
      }
    },
  ];

  const deleteWO = async (id) => {
    setLoading(true)
    axios.delete(`${SERVER_IP}workOrder?WorkOrderID=${id}`)
      .then((res) => {
        fetchWorkOrder();
        toast.success("Deleted Successfully...Table Will Refresh in Few Seconds");
      })
      .catch((res) => {
        toast.error("Couldn't Delete!");
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchWorkOrder();
  }, [currentPage, pageSize, queryOptions])



  return (


    <Box sx={{ width: '100%' }}>
      {/* {(loading) ?
        <TablePlaceholder />
        : */}
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Work Order
              </Typography>
            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                initialState={{
                  filter: queryOptions,
                }}
                onFilterModelChange={onFilterChange}
                autoHeight
                getRowId={(row) => row.WorkOrderID}
                rows={workOrders?.dataToDisplay || []}
                columns={columns}
                pageSize={pageSize}
                loading={loading}
                rowCount={workOrders?.totalCount}
                paginationMode='server'
                rowsPerPageOptions={[5, 10, 15, (workOrders?.totalCount || 0) > 100 ? 100 : (workOrders?.totalCount || 0)]}
                // components={{
                //   Toolbar: () => {
                //     return (
                //       <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
                //         <Button style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                //         >Export</Button>
                //         <GridToolbarExport
                //           style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                //           onClick={(e)=>{
                //             console.log("Send")
                //           }}
                //           csvOptions={{
                //             fileName: 'Technician Schedule',
                //             utf8WithBom: true,
                //             disableToolbarButton : true
                //           }}
                //           printOptions={{
                //             hideFooter: true,
                //             hideToolbar: true,
                //             disableToolbarButton: true
                //           }}
                //         />
                //       </GridToolbarContainer>
                //     )
                //   }
                // }}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
                page={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>

          </Paper>

        </div>
       {/* } */}
      <ToastContainer />
    </Box>

  );
}
