//@ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { Button} from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { isSuperUser } from '../../utils';

export default function DocTable() {
  const { state } = useLocation();
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [docs, setDocs] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const fetchDoc = () => {
    setLoading(true)
    const url = `${SERVER_IP}DocumentOperations/getAll`
    axios
      .get(
        url
      )
      .then(async (res) => {
        let docData = res.data
        setDocs(docData)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch Docs!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'DocName',
      headerName: 'Document Name',
      width: 200,
      flex: 1,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'DocumentDate',
      headerName: 'Document Date',
      width: 300,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Action',
      headerName: 'Action Buttons',
      sortable: false,
      filterable: false,
      disableExport: true,
      hide: !isSuperUser(),
      width: 300,
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateDocuments', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize : pageSize,
                  currentPage : currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>View / Edit</Button>
            {/* <Button
              style={{ marginLeft: '10px' }}
              disabled
              onClick={(e) => {
                if (window.confirm('Are You Sure to delete ?')) {
                  toast.success("Deleted")
                }
                e.stopPropagation()
              }} color='secondary' variant='contained'>Delete</Button> */}
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    fetchDoc();
  }, []);

  return (


    <Box sx={{ width: '100%' }}>
      {(loading) ?
        <TablePlaceholder />
        :
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Documents
              </Typography>
            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                initialState={{
                  filter: queryOptions,
                }}
                onFilterModelChange={onFilterChange}
                autoHeight
                getRowId={(row) => row.DocumentsCollectionID}
                rows={docs}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15, docs.length > 100 ? 100 : docs.length]}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
                page={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>

          </Paper>

        </div>
      }
      <ToastContainer />
    </Box>

  );
}
