import React, { useState } from "react";
import { Header } from "./LoginWidget";
import {
  Grid,
  makeStyles,
  Theme,
  Container,
  Box,
  TextField,
  Button,
  Modal,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import LogoIndex from "../assert/logo_index.jpeg";
import { CircularProgress, Typography } from "@mui/material";
import axios from 'axios'
import { SERVER_IP } from "../variables";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../utils";

export const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    navigate("/")
    setOpen(false);
  }


  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setUserName(value)
  };

  const submitForm = async (event) => {
    event.preventDefault();
    // setLoading(true)
    if (!!!userName) {
      toast.error("Enter Your Username");
      return
    }

    setLoading(true)
    axios
      .post(`${SERVER_IP}forgotPassword`, {
        email: userName
      })
      .then(async (res) => {
        setLoading(false)
        if(res.data?.message?.includes("Email is not registered")){
          toast.error(res.data?.message)
          return
        }
        handleOpen()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        toast.error("Something Went Wrong");
      });    

  };

  return (
    <div>
      <Header />
      <Container maxWidth="xs" style={{ marginTop: 100, minWidth: 400, backgroundColor: '#f1f1fa', padding: 50, borderRadius: 20 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item container className={classes.container}>
            <Grid item xs={12} style={{ minHeight: "20px", padding: 5, borderRadius: 20 }}>
              <img style={{width : '100%'}} alt="logo" src={LogoIndex} />
            </Grid>
            <Grid item xs={12} style={{ minHeight: "20px", padding: 5, backgroundColor: 'gray', borderRadius: 20 }}>
              <Typography variant="h5" style={{ color: '#fff' }}>Forgot Password</Typography>
            </Grid>
          </Grid>
          <form onSubmit={submitForm}>
            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              style={{ marginTop: 10, textAlign: "right" }}
            >
              <Grid item xs={12}>
                <TextField
                  onChange={onChange}
                  value={userName}
                  name="UserName"
                  autoFocus
                  label="Username"
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 100, borderRadius: 20 }}
                  onClick={submitForm}
                  disabled={loading}
                >
                  {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                  Send Link
                </Button>

                <Modal
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <div style={{
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%)`,
                    width:400,
                    padding : 30,
                  }} className={classes.paper}>
                    <CloseIcon onClick={handleClose} className={classes.closebtn} />
                    <Box>
                      <Typography style={{fontWeight : 'bold'}} variant="h6" component="h2">
                        Link Sent
                      </Typography>
                      <Typography sx={{ mt: 2 }}>
                        Link to Reset Password has been sent to your Registered email
                      </Typography>
                    </Box>
                  </div>
                </Modal>
              </Grid>
            </Grid>
          </form>

        </Grid>
      </Container>
      <ToastContainer />
    </div>
  );
};
