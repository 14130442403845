// @ts-nocheck
import {
  Select,
  MenuItem,
  InputLabel,
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { generateDays, getCurrentUserID, getDateTime, inputFieldVariant, weekDays } from "../../utils";
import WorkOrderData from "./WorkOrderData.json"
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import {
  FormHelperText,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Chip,
  Stack,
  Box,
  InputAdornment,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  // {
  //   field: 'StaffID',
  //   headerName: 'Worker ID',
  //   width: 100
  // },
  {
    field: 'StaffName',
    headerName: 'Worker Name',
    width: 200
  }
];

const freqs = [
  "Weekly",
  "Monthly",
  "Bi-Monthly",
  "Quarterly",
  "Yearly"
]


export const CreateBulkWorkOrder = () => {

  const navigate = useNavigate();
  const { handleSubmit, control, getValues, setValue } = useForm();

  const [loading, setLoading] = useState(false)
  const [POs, setPOs] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [siteZones, setSiteZones] = useState<any>([]);
  const [workTypes, setWorkTypes] = useState<any>([]);
  const [workStatuses, setWorkStatuses] = useState<any>([]);
  const [workOrder, setWorkOrder] = useState(WorkOrderData);
  const [workNatures, setWorkNatures] = useState<any>([]);
  const [selectedFreq, setSelectedFreq] = useState<any>("");
  const [generatedDays, setGeneratedDays] = useState<Array<any>>([]);
  const [previewClicked, setPreviewClicked] = useState<Boolean>(false);
  const [availableWorkers, setAvailableWorkers] = useState<Array<any>>([]);
  const [selectedWorkers, setSelectedWorkers] = useState<Array<any>>([]);
  const [workers, setWorkers] = useState<any>([]);
  const [linkedWorkers, setLinkedWorkers] = useState<Array<any>>([]);

  const fetchPO = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}po`
      )
      .then(async (res) => {
        let temp = res.data.message
        if (temp.length) {
          setPOs(temp);
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch POs!");
      }).finally(() => setLoading(false))
  };

  const getWorkTypes = () => {
    axios
      .get(`${SERVER_IP}worktype`)
      .then((res) => {

        setWorkTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getWorkNatures = () => {
    axios
      .get(`${SERVER_IP}worknature`)
      .then((res) => {

        setWorkNatures(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {

        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const fetchWorkers = async () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}workers`
      )
      .then((res) => {
        setWorkers(res.data)
        setAvailableWorkers(res.data);
      })
      .catch((res) => {
      }).finally(() => setLoading(false))
  };

  const getWorkStatuses = () => {
    axios
      .get(`${SERVER_IP}workstatus`)
      .then((res) => {
        setWorkStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const createWorkOrder = async (data) => {

    if (!previewClicked) {
      toast.error("Click Preview Button To Confirm")
      return
    }

    let dummy = {
      ...workOrder,
      RequestedStartDate: moment(workOrder.RequestedStartDate).format("YYYY/MM/DD"),
      RequestedEndDate: moment(workOrder.RequestedEndDate).format("YYYY/MM/DD"),
      AssignedDateTime: moment(workOrder.AssignedDateTime).format("HH:mm:ss"),
    }

    let finalData = generatedDays.map(d => {
      return {
        POID: data.POID || "",
        AssignedWorkers : linkedWorkers,
        RequestedEndDate: dummy.RequestedEndDate,
        RequestedStartDate: dummy.RequestedStartDate,
        SiteID: data.SiteID,
        SiteZoneID: data.SiteZoneID,
        WorkNatureID: data.WorkNatureID,
        WorkStatusID: data.WorkStatusID,
        WorkTypeID: data.WorkTypeID,
        AssignedDateTime: d,
        UpdatedByUserID: getCurrentUserID(),
        UpdatedDateTime: getDateTime(),
        CreatedType: "Bulk"
      }
    })

    if (finalData.length === 0) {
      toast.error("No Date Coming in this Criteria...Click Preview To Confirm")
      return
    }

    console.log(finalData)

    setLoading(true)

    axios
      .post(
        `${SERVER_IP}bulkWorkOrder`,
        finalData
      )
      .then((res) => {
        toast.success("Bulk Work Order Created");
        navigate("/WorkOrder")
      })
      .catch((res) => {
        toast.error("Couldn't Create Bulk Work Orders.. Try Later");
      }).finally(() => setLoading(false))
  };


  useEffect(() => {
    fetchPO();
    getSiteZones();
    // getSites();
    getWorkTypes();
    getWorkNatures();
    getWorkStatuses();
    fetchWorkers();
    // document.getElementById("ppp")?.click()
  }, []);


  const generateWorkorderDays = () => {
    if (!!!workOrder.RequestedStartDate) {
      toast.error("Select a Start Date")
      return
    }
    if (!!!workOrder.RequestedEndDate) {
      toast.error("Select an End Date")
      return
    }
    if (!!!workOrder.AssignedDateTime) {
      toast.error("Select Time")
      return
    }
    if (moment(workOrder.RequestedStartDate).isAfter(workOrder.RequestedEndDate)) {
      toast.error("Start Date is After End Date")
      return
    }

    setPreviewClicked(true)

    const freq = getValues('Frequency')
    const weekDays = getValues('WeekDays')
    const days = getValues('Days')


    let dummy = {
      ...workOrder,
      RequestedStartDate: moment(workOrder.RequestedStartDate).format("YYYY/MM/DD"),
      RequestedEndDate: moment(workOrder.RequestedEndDate).format("YYYY/MM/DD"),
      AssignedDateTime: moment(workOrder.AssignedDateTime).format("HH:mm:ss"),
    }

    let x = generateDays(dummy.RequestedStartDate,
      dummy.RequestedEndDate,
      dummy.AssignedDateTime,
      {
        type: freq,
        weekDays: weekDays,
        days: days
      })

    setGeneratedDays(x)
  }

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <form onSubmit={handleSubmit(createWorkOrder)}>
            <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    Create Bulk Work Orders
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto' }}>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="POID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>PO Number</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {[{POID : "", POnumber : "None"},...POs].map((e) => (
                              <MenuItem key={e.POID} value={e.POID}>{e.POnumber}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      // rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="SiteZoneID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Zone</InputLabel>
                          <Select
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              axios
                                .get(`${SERVER_IP}sitelistbyzone?SiteZoneID=${e.target.value}`)
                                .then((res) => {
                                  setSites(res.data);
                                }).catch((res) => {
                                  console.log(res)
                                });
                            }}
                          >
                            {siteZones.map((e) => (
                              <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="SiteID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="WorkTypeID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>WorkType</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {workTypes.map((e) => (
                              <MenuItem key={e.WorkTypeID} value={e.WorkTypeID}>{e.WorkTypeName}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="WorkStatusID"
                      defaultValue={1}
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Work Status</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                            disabled
                          >
                            {workStatuses.map((e) => (
                              <MenuItem key={e.WorkStatusID} value={e.WorkStatusID}>{e.WorkStatus}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="WorkNatureID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>WorkNature</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {workNatures.map((e) => (
                              <MenuItem key={e.WorkNatureID} value={e.WorkNatureID}>{e.WorkNature}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="WOInvoice"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Invoices"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Requested Start Date"
                        inputFormat="dd/MM/yyyy"
                        // disableFuture
                        value={workOrder.RequestedStartDate || null}
                        onChange={(newValue) => {
                          setPreviewClicked(false)
                          setWorkOrder({
                            ...workOrder,
                            RequestedStartDate: newValue
                          })
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="RequestedStartDate"
                          name="RequestedStartDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Requested End Date"
                        inputFormat="dd/MM/yyyy"
                        minDate={workOrder.RequestedStartDate || null}
                        value={workOrder.RequestedEndDate || null}
                        onChange={(newValue) => {
                          setPreviewClicked(false)
                          setWorkOrder({
                            ...workOrder,
                            RequestedEndDate: newValue
                          })
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="RequestedEndDate"
                          name="RequestedEndDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>


                  <Grid item xs={12} sm={4}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        value={workOrder.AssignedDateTime || null}
                        onChange={(newValue) => {
                          setPreviewClicked(false)
                          setWorkOrder({
                            ...workOrder,
                            AssignedDateTime: newValue
                          })
                        }}
                        renderInput={(params) => <TextField fullWidth variant={inputFieldVariant} {...params} />}
                      />
                    </LocalizationProvider>

                  </Grid>


                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="Frequency"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Frequency</InputLabel>
                          <Select
                            value={value}
                            onChange={(e) => {
                              onChange(e)
                              setPreviewClicked(false)
                              setSelectedFreq(e.target.value)
                            }}
                          >
                            {
                              freqs.map((e) => (
                                <MenuItem key={e} value={e}>{e}</MenuItem>
                              ))
                            }
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  {
                    (freqs.includes(selectedFreq) && selectedFreq !== "Yearly") &&
                    // (selectedFreq !== "Yearly" || selectedFreq === "Bi-Monthly") &&
                    <>
                      <Grid item xs={12} sm={3}>
                        <Controller
                          name="WeekDays"
                          control={control}
                          render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
                            <MuiFormControl fullWidth variant="standard">
                              <MuiInputLabel style={{ color: error && 'red' }}>Select WeekDays</MuiInputLabel>
                              <MuiSelect
                                style={{ color: error && 'red' }}
                                multiple
                                value={value || []}
                                onChange={(e) => {
                                  onChange(e)
                                  setPreviewClicked(false)
                                }}
                              >
                                {weekDays.map((e) => (
                                  <MuiMenuItem key={e} value={e}>{e}</MuiMenuItem>
                                ))}
                              </MuiSelect>
                              {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                            </MuiFormControl>
                          )}
                          rules={{ required: 'This field is required' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <Button
                          id="ppp"
                          style={{ marginTop: 10 }}
                          variant="contained"
                          onClick={generateWorkorderDays}
                        >
                          Preview
                        </Button>
                      </Grid>
                    </>

                  }

                  {
                    (freqs.includes(selectedFreq) && selectedFreq === "Yearly") &&
                    // freqs.includes(selectedFreq) && selectedFreq !== "Weekly" && selectedFreq !== "Bi-Monthly" &&
                    <>
                      <Grid item xs={12} sm={3}>
                        <Controller
                          name="Days"
                          control={control}
                          render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
                            <MuiFormControl fullWidth variant="standard">
                              <MuiInputLabel style={{ color: error && 'red' }}>Select Days</MuiInputLabel>
                              <MuiSelect
                                style={{ color: error && 'red' }}
                                multiple
                                value={value || []}
                                onChange={(e) => {
                                  onChange(e)
                                  setPreviewClicked(false)
                                }}
                              >
                                {[...Array(31).keys()].map((e) => (
                                  <MuiMenuItem key={e + 1} value={e + 1}>{e + 1}</MuiMenuItem>
                                ))}
                              </MuiSelect>
                              {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                            </MuiFormControl>
                          )}
                          rules={{ required: 'This field is required' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <Button
                          style={{ marginTop: 10 }}
                          variant="contained"
                          onClick={generateWorkorderDays}
                        >
                          Preview
                        </Button>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>

              {
                !!generatedDays.length &&
                <Grid container style={{ padding: 10 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Work Orders To Be Created On
                    </Typography>
                  </Grid>
                  <Row>
                    <Col xl={12}>
                      {
                        // generatedDays.map((d, index) => <Grid key={index} style={{ fontSize: 18 }} item xs={12}>{index + 1} . {d}</Grid>)
                        generatedDays.map((d, index) => <Chip
                          style={{ fontSize: 18, margin: 10 }}
                          key={index}
                          label={d}
                          color="success"
                        />)
                      }
                    </Col>
                  </Row>


                </Grid>
              }

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                      Selected Workers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                      <Box
                        sx={{
                          height: 300,
                          width: 1,
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: PrimaryColor,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.StaffID}
                          rows={selectedWorkers}
                          columns={columns}
                          pageSize={4}
                          onSelectionModelChange={(newSelection) => {
                            console.log(newSelection)
                            if (!!newSelection.length) {
                              setValue("WorkStatusID",2)
                              console.log(newSelection)
                              let temp = selectedWorkers.filter(worker => newSelection.includes(worker.StaffID))
                              setSelectedWorkers(temp)

                              let linked = temp.map(worker => worker.StaffID)
                              setLinkedWorkers(linked)

                              temp = workers.filter(worker => !linked.includes(worker.StaffID))
                              setAvailableWorkers(temp)
                            }
                            else {
                              setValue("WorkStatusID",1)
                              setSelectedWorkers([])
                              setLinkedWorkers([])
                              setAvailableWorkers(workers)
                            }
                          }}
                          loading={loading}
                          rowsPerPageOptions={[4]}
                          checkboxSelection
                          selectionModel={linkedWorkers}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                      Available Workers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                      <Box
                        sx={{
                          height: 300,
                          width: 1,
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: PrimaryColor,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.StaffID}
                          rows={availableWorkers}
                          columns={columns}
                          pageSize={4}
                          onSelectionModelChange={(newSelection) => {
                            // console.log(newSelection)
                            if (!!newSelection.length) {
                              setValue("WorkStatusID",2)
                              // console.log(newSelection)
                              let temp = workers.filter(worker => worker.StaffID === newSelection[0])
                              // console.log(temp)
                              let data = [...selectedWorkers]
                              // console.log(data)
                              data.push(temp[0])
                              setSelectedWorkers(data)

                              let linked = data.map(worker => worker.StaffID)
                              setLinkedWorkers(linked)
                              temp = workers.filter(worker => !linked.includes(worker.StaffID))
                              setAvailableWorkers(temp)
                            }
                          }}
                          loading={loading}
                          rowsPerPageOptions={[4]}
                          checkboxSelection
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    Create
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/WorkOrder")}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>

              <ToastContainer />
            </Grid>
          </form>
        </Grid>

      </div >
    </>

  );
};
