// @ts-nocheck
import { useState, useEffect } from 'react';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import Typography from '@mui/material/Typography';
import {
    Grid,
    Button,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TableBody,
} from "@material-ui/core";
import { Sidebar } from "../Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../../assert/logo_index.jpeg'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { dateFormat, getBase64DataFromURL, getMax, isLoggedUserStaff, isValidUrl, useStyles } from '../../utils';

const docRefNo = "Common Pest Report"

export const CommonPestReport = () => {

    const classes = useStyles();
    const { state } = useLocation();
    const filteredState = state.reportData
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [reportData, setReportData] = useState<any>(filteredState || {});

    useEffect(() => {
        async function urlToBase64Conversion() {

            let duplicate = reportData

            await setLoading(true);


            reportData?.images?.beforeImages?.map(async (image, index) => {
                let temp = await getBase64DataFromURL(image?.ImageURL)
                duplicate.images.beforeImages[index] = {
                    ...image,
                    base64: temp
                }
            })

            reportData?.images?.afterImages?.map(async (image, index) => {
                let temp = await getBase64DataFromURL(image?.ImageURL)
                duplicate.images.afterImages[index] = {
                    ...image,
                    base64: temp
                }
            })

            let sign = await getBase64DataFromURL(duplicate?.ContactAckSignImageURL)
            duplicate.ContactAckSignImageBase64 = sign

            setReportData({ ...duplicate })


        }

        if (reportData) {

            urlToBase64Conversion().then(()=>setLoading(false))
        }
    }, [])

    useEffect(() => {
        // document.getElementById("ppp")?.click()
    }, []);

    const exportData = () => {

        setLoading(true)

        const doc = new jsPDF("landscape")

        function addFooters() {
            const pageCount = doc.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.addImage(logo, 'jpeg', 10, 0, 80, 12)
                doc.text(docRefNo, width - 10, 10, { align: 'right' });
                doc.text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
                doc.text(`Page ${i}`, width - 20, height - 5);
            }
        }

        const columns = [
            { title: "BEFORE", field: "before", },
            { title: "AFTER", field: "after", },
        ]

        doc.text(docRefNo, 110, 25)

        const imageSpace = "\n\n\n\n\n\n\n\n\n"
        const imageSpace2 = "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"
        const imageHeight = 32
        const imageWidth = 54

        doc.autoTable({
            theme: "grid",
            startY: 30,
            breakAtY: 100,
            bodyStyles: { lineColor: [0, 0, 0] },
            styles: {
                halign: 'left',
                border: '2px solid',
                valign: 'middle'
            },
            headStyles: {
                halign: 'center',
                fillColor: [241, 90, 41],
                lineColor: [0, 0, 0],
                border: '2px solid'
            },
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            columnStyles: {
                0: { cellWidth: 135 },
                1: { cellWidth: 135 },
            },
            body: [
                [
                    {
                        content: `DATE AND TIME OF VISIT :${dateFormat(reportData?.WOstartDateTime, "DD/MM/YYYY hh:mm A")}\n
ACTION BY                         : ${reportData?.actionBy?.join(",\n                                              ") || "None"}`,

                        styles: { minCellHeight: 40 }
                    },
                    {
                        content: `DATE AND TIME OF COMPLETION :${dateFormat(reportData?.WOendDateTime, "DD/MM/YYYY hh:mm A")}\n
ACTION BY                                        : ${reportData?.actionBy?.join(",\n                                                             ") || "None"}\n
PURCHASE ORDER NO                   : ${reportData?.POnumber || ""}\n
WORKORDER NO                             : ${reportData?.WorkOrderID}`,
                        styles: { minCellHeight: 40 }
                    }
                ],
                [
                    {
                        content: `WORK NATURE                 : ${reportData?.WorkNature}\n
LOCATION                          : ${reportData?.Location || ""}\n
SITE                                    : ${reportData?.SiteName || "NA"}`,
                        styles: { minCellHeight: 35 }
                    },
                    {
                        content: `SERVICES PERFORMED                : ${[...reportData?.serviceTypes, ...reportData?.serviceTypeOthers].filter(d => d !== "Others").join(",\n                                                            ")}`,
                        styles: { minCellHeight: 35 }
                    }
                ],
                ...getMax(reportData?.images?.beforeImages, reportData?.images?.afterImages).slice(0, 5).map(e => {
                    return [
                        {
                            content: `PHOTOGRAPH OF FINDINGS${imageSpace2}${(e == 3 || e == 5) ? '\n\n\n' : ''}`,
                            styles: { minCellHeight: 80 }
                        },
                        {
                            content: `PHOTOGRAPH OF SERVICE PERFORMED${imageSpace2}${(e == 3 || e == 5) ? '\n\n\n' : ''}`,
                            styles: { minCellHeight: 80 }
                        }
                    ]
                }),
                [
                    {
                        content: `FINDINGS : \n${reportData?.Findings}\n`,
                        colSpan: 2,
                    }
                ],
                {
                    before: `REQUESTED ON               : ${dateFormat(reportData?.PODate, "DD/MM/YYYY hh:mm A")}\n
TECHNICIAN                     : ${reportData?.Requestor || ""}`,
                    after: `${isValidUrl(reportData?.ContactAckSignImageURL) ? `SIGNATURE OF ACKNOWLEDGEMENT${imageSpace}
ACKNOWLEDGED ON                      : ${dateFormat(reportData?.ContactAckDateTime, "DD/MM/YYYY hh:mm A")}\n
ACKNOWLEDGED BY SCHOOL       : ${reportData?.AckContact}` : "Signature of Acknowledgement Not Available"}`,
                },
            ],
            didDrawCell: async function (data) {
                if (data?.row?.index === 2 && data?.column?.index == 0 && data.cell.section === 'body') {
                    reportData?.images?.beforeImages?.slice(0, 4).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 2 && data?.column?.index == 1 && data.cell.section === 'body') {
                    reportData?.images?.afterImages?.slice(0, 4).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 3 && data?.column?.index == 0 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF FINDINGS")) {
                    reportData?.images?.beforeImages?.slice(4, 8).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 3 && data?.column?.index == 1 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF SERVICE PERFORMED")) {
                    reportData?.images?.afterImages?.slice(4, 8).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 4 && data?.column?.index == 0 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF FINDINGS")) {
                    reportData?.images?.beforeImages?.slice(8, 12).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 4 && data?.column?.index == 1 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF SERVICE PERFORMED")) {
                    reportData?.images?.afterImages?.slice(8, 12).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 5 && data?.column?.index == 0 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF FINDINGS")) {
                    reportData?.images?.beforeImages?.slice(12, 16).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 5 && data?.column?.index == 1 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF SERVICE PERFORMED")) {
                    reportData?.images?.afterImages?.slice(12, 16).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 6 && data?.column?.index == 0 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF FINDINGS")) {
                    reportData?.images?.beforeImages?.slice(16, 20).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                else if (data?.row?.index === 6 && data?.column?.index == 1 && data.cell.section === 'body' && data?.cell?.text[0]?.includes("PHOTOGRAPH OF SERVICE PERFORMED")) {
                    reportData?.images?.afterImages?.slice(16, 20).map((image, index) => {
                        let xValue = data.cell.x + 10 + (index % 2 === 0 ? 0 : (1 * (imageWidth + 5)))
                        let yValue = data.cell.y + 8 + (index > 1 ? (imageHeight + 4) : 0)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }

                if (data?.column?.index == 1 && data?.row?.index === data?.table?.body?.length - 1 && data.cell.section === 'body') {
                    isValidUrl(reportData?.ContactAckSignImageURL) && doc.addImage(reportData?.ContactAckSignImageBase64, "jpeg", data.cell.x + 10, data.cell.y + 7, imageWidth, imageHeight);
                }
            }
        })


        let finalY = doc.lastAutoTable.finalY + 10;

        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()

        doc.setFontSize(10).text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
        finalY = finalY + 5
        doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
        finalY = finalY + 5
        doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
        addFooters()

        doc.save(`${docRefNo}.pdf`)

        setLoading(false)
    }

    return (
        <>
            <div style={{ marginTop: 80, display: 'flex' }}>
                <Sidebar />
                <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>

                    <Grid container spacing={3} style={{ margin: "auto", padding: '20px' }}>
                        {
                            isLoggedUserStaff() && <div style={{ margin: 10, padding: 5, border: `2px solid ${PrimaryColor}`, backgroundColor: PrimaryColor, color: 'white' }}>
                                <Grid container direction="row" style={{ fontSize: 24 }}>
                                    <Grid item>
                                        <b>Notes To Office</b>
                                    </Grid>
                                    <Grid item>
                                        <b>: &nbsp;</b>{reportData?.NotesToOffice || "NA"}
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <Grid item xs={12}>
                            <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                                <Grid container direction="row">
                                    <Grid item>
                                        {docRefNo}
                                    </Grid>
                                    <Grid item style={{ marginLeft: 'auto' }}>
                                        <Button color="secondary"
                                            style={{ marginRight: '10px' }}
                                            size="small"
                                            variant="contained"
                                            onClick={() => navigate('/ReportsByPO', {
                                                state: state
                                            })}
                                        >
                                            Back
                                        </Button>
                                        <Button color="primary"
                                            disabled={loading}
                                            size="small"
                                            variant="contained"
                                            id="ppp" onClick={() => exportData()}
                                        >
                                            {loading ? "Loading" : "Export"}
                                        </Button>
                                    </Grid>
                                </Grid>


                            </Typography>

                        </Grid>
                        <Grid item container spacing={1} direction="row">
                            <TableContainer>
                                <Table id="ddd" style={{ tableLayout: 'fixed' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headerCell}>BEFORE</TableCell>
                                            <TableCell className={classes.headerCell}>AFTER</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell width={"50%"} className={classes.tableCell}>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Date and Time of Visit</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b>{dateFormat(reportData?.WOstartDateTime, "DD/MM/YYYY hh:mm A")}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Action By </b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {
                                                                reportData?.actionBy?.length === 0 && <><b>: &nbsp;&nbsp;</b>None</>
                                                            }
                                                            {
                                                                reportData?.actionBy?.map((w, index) => <div key={index}> {index === 0 && <b>: &nbsp;</b>} {index !== 0 && <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}{w}</div>)
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                            </TableCell>
                                            <TableCell width={"50%"} className={classes.tableCell}>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Date and Time of Completion</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b>{dateFormat(reportData?.WOendDateTime, "DD/MM/YYYY hh:mm A")}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Action By</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {
                                                                reportData?.actionBy?.length === 0 && <><b>: &nbsp;&nbsp;</b>None</>
                                                            }
                                                            {
                                                                reportData?.actionBy?.map((w, index) => <div key={index}> {index === 0 && <b>: &nbsp;</b>} {index !== 0 && <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}{w}</div>)
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Purchase Order No</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {reportData?.POnumber || "NA"}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Work Order No</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {reportData?.WorkOrderID || "NA"}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Work Nature</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {reportData?.WorkNature || "NA"}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item>
                                                            <b>Findings</b>
                                                            <div style={{ lineBreak: 'anywhere' }}>&nbsp;&nbsp;{reportData?.Findings || "NA"}</div>
                                                        </Grid>
                                                        {/* <Grid item xs={6}>
                                                            <div style={{ display: 'flex' }}>
                                                                <b>: &nbsp;</b>
                                                                <div>&nbsp;&nbsp;{reportData?.Findings}</div>
                                                            </div>
                                                        </Grid> */}
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Location</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {reportData?.Location || "NA"}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Site</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {reportData?.SiteName || "NA"}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Services Performed &nbsp;</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {
                                                                [...reportData?.serviceTypes, ...reportData?.serviceTypeOthers].map((w, index) => w !== "Others" && <div key={index}> {index === 0 && <b>: &nbsp;</b>} {index !== 0 && <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}{w}</div>)
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                {/* <div>
                                                    <b>Services Performed:</b> {reportData?.serviceTypes?.join(",")}
                                                </div> */}
                                                {
                                                    // reportData?.serviceTypes?.includes('Others') &&
                                                    // <div>
                                                    //     <b>Others</b> - {reportData?.ServiceTypeOther}
                                                    // </div>
                                                }

                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <b>Photographs of Findings</b>
                                                </div>
                                                <Grid container>
                                                    {
                                                        reportData?.images?.beforeImages?.map((data, index) => (
                                                            <Grid item xs={6} key={index} style={{ maxHeight: '90%', marginBottom: 5 }}>
                                                                <img
                                                                    src={data?.ImageURL}
                                                                    // src={logo}
                                                                    alt='findings'
                                                                    style={{ height: 'auto', width: '100%', padding: 2 }}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <b>Photographs of Service Performed</b>
                                                </div>
                                                <Grid container>
                                                    {
                                                        reportData?.images?.afterImages?.map((data, index) => (
                                                            <Grid item xs={6} key={index} style={{ maxHeight: '90%', marginBottom: 5 }}>
                                                                <img
                                                                    src={data?.ImageURL}
                                                                    // src={logo}
                                                                    alt='findings'
                                                                    style={{ height: 'auto', width: '100%', padding: 2 }}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>

                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Requested On</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {dateFormat(reportData?.PODate, "DD/MM/YYYY hh:mm A")}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Technician</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b> {reportData?.Requestor}
                                                        </Grid>
                                                    </Grid>
                                                </div>


                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <b>Signature of Acknowledgement</b>
                                                </div>
                                                <div style={{ maxHeight: '90%', marginBottom: 10 }}>
                                                    <img
                                                        src={reportData?.ContactAckSignImageURL}
                                                        alt='signature'
                                                        style={{ height: '200px', width: '50%' }}
                                                    />
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Acknowledged On</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b>  {dateFormat(reportData?.ContactAckDateTime, "DD/MM/YYYY hh:mm A")}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div>
                                                    <Grid container direction="row">
                                                        <Grid item xs={6}>
                                                            <b>Acknowledged By</b>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <b>: &nbsp;</b>  {reportData?.AckContact}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                            </TableCell>

                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>


            </div>
        </>
    );
};

