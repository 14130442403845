// @ts-nocheck
import {
  Grid,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect} from "react";
import { ToastContainer, toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { getCurrentUserID, getDateTime, inputFieldVariant } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import {
  CircularProgress,
} from "@mui/material";


export const UpdateCompany = () => {

  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false)

  const updateCompany = async (data) => {

    let temp = {
      ...state,
      ...data,
      UpdatedByUserID: getCurrentUserID(),
      UpdatedDateTime: getDateTime(),
    }

    console.log(temp)
    setLoading(true)
    axios
      .put(`${SERVER_IP}company/update?CompanyID=${temp.CompanyID}`, temp)
      .then((res) => {
        toast.success("Company Successfully Updateed");
        navigate('/Company')
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't Update Company!");
      }).finally(() => setLoading(false))
  };

  useEffect(()=>{
    !!!state && navigate("/Company")
  },[])

  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container direction="column" spacing={1} style={{ padding: '10px 50px 10px 30px', marginRight : '25%' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(updateCompany)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update Company"}
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto', paddingBottom: '10px' }} >

                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="CompanyName"
                      control={control}
                      defaultValue={state?.CompanyName}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Company Name"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="BillingAddress1"
                      control={control}
                      defaultValue={state?.BillingAddress1}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Billing Address 1"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="BillingAddress2"
                      control={control}
                      defaultValue={state?.BillingAddress2}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Billing Address 2"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="BillingPostCode"
                      control={control}
                      defaultValue={state?.BillingPostCode}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Post Code"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          type={"number"}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value: /^\d+(\.\d{0,2})?$/,
                          message: "Max 2 digits Only"
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  </Grid>

                  <Grid container style={{ marginBottom: '10px' }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                        {"Update Company"}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button color="secondary" size="small" variant="contained" onClick={(e) => navigate("/Company")}>
                        Cancel
                      </Button>
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
            </form>
          </Grid>
          <ToastContainer />
        </Grid>
      </div>
    </>
  );
};
