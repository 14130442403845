//@ts-nocheck
import { Grid, Typography } from "@material-ui/core";
import { PieChart } from 'react-minimal-pie-chart';
import { Sidebar } from "../Sidebar";
import { useState, useEffect } from "react";
import { PrimaryColor, SERVER_IP } from "../../variables";
import { camelCaseToSentence, dateFormat, dateTimeFormat, getCurrentUserID, isLoggedUserStaff } from "../../utils";
import axios from 'axios'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from "moment";
import { Button, Select, MenuItem } from '@material-ui/core';
import { Box, Paper } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useLocation } from "react-router-dom";
import history from 'history'


export const HomeWidget = () => {

  const [selected, setSelected] = useState<number | undefined>(0);
  const [total, setTotal] = useState<number | undefined>(0);
  const [chartData, setChartData] = useState<any>([]);
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(5)

  const dataMock = [
    {
      title: "No of Work Orders Created", value: 0, color: '#78E6E0'
    },
    {
      title: "No Of PO Created", value: 0, color: '#EEA26B'
    },
    {
      title: "No Of Reports", value: 0, color: '#E5EB59'
    },
  ]

  const colors = [
    '#78E6E0',
    '#EEA26B',
    '#E5EB59',
    'lightgreen',
    'yellow'
  ]

  const shiftSize = 5;
  const legendSize = 25;
  const isStaff = isLoggedUserStaff()

  const fetchWorkOrder = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}getHomeWorkOrder?CurrentDate="${moment().format('YYYY-MM-DD')}"&ContactID=${getCurrentUserID()}&Staff=${isStaff}`
      )
      .then(async (res) => {
        let temp = res.data
        if (temp.length) {
          let workersData = await (await axios.get(`${SERVER_IP}workersbyPO`)).data

          temp.map(data => {
            data["Workers"] = []
            workersData.map(worker => {
              if (worker.WorkOrderID === data.WorkOrderID)
                data.Workers.push(worker.StaffName)
            })
          })
          setWorkOrders(temp)
        }
      })
      .catch((err) => {
        console.log(err)
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'WorkOrderID',
      headerName: 'Work Order Number',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Description',
      headerName: 'Site Zone',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'SiteName',
      headerName: 'Site',
      width: 100,
      flex: 1,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'WorkTypeName',
      headerName: 'WorkType ',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'WorkStatus',
      headerName: 'WorkStatus',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'RequestedStartDate',
      headerName: 'Requested Start Date',
      width: 100,
      renderCell: (row) => row.value ? dateFormat(row.value) : "N/A"
    },
    {
      field: 'RequestedEndDate',
      headerName: 'Requested End Date',
      width: 100,
      renderCell: (row) => row.value ? dateFormat(row.value) : "N/A"
    },
    {
      field: 'AssignedDateTime',
      headerName: 'Assigned DateTime',
      width: 150,
      renderCell: (row) => row.value ? dateTimeFormat(row.value) : "N/A"
    },
    {
      field: 'Workers',
      headerName: 'Assigned Staffs',
      width: 150,
      renderCell: (row) => (
        <>
          {
            row.value.length > 0 ?
              <Select value={100}>
                <MenuItem value={100} style={{ display: 'none' }}>Click To View</MenuItem>
                {
                  row.value.map((data, index) => <MenuItem disabled key={index} style={{ color: '#2a2a2a', opacity: 1 }} value={data}>{data}</MenuItem>)
                }
              </Select>
              :
              "NA"
          }

        </>
      )
    }
  ];

  const fetchChartData = () => {
    axios
      .get(`${SERVER_IP}getHomeData?CurrentDate="${moment().format('YYYY-MM-DD')}"`)
      .then((res) => {
        setTotal(res?.data?.filter(x => x.title === "totalWorkOrders")[0]?.Value)
        let temp = res?.data?.filter(x => x.title !== "totalWorkOrders" && !!x.Value).map((x, index) => {
          return {
            ...x,
            title: camelCaseToSentence(x?.title),
            value: x?.Value,
            color: colors[index]
          }
        })
        setChartData(temp);
      }).catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    fetchChartData();
    fetchWorkOrder();
  }, []);

  return (

    <div style={{ marginTop: 80, display: 'flex' }}>
      <Sidebar />
      <Grid container>
        {
          false && <>
            <Grid item xs={12}>
              <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                Total WorkOrders (Today) - {total}
              </Typography>
            </Grid>
            <Grid item container direction="row" spacing={3} >
              <Grid item xs={6} style={{ maxHeight: 350 }}>
                <PieChart
                  radius={PieChart.defaultProps.radius - shiftSize}
                  segmentsShift={(index) => (0.5)}
                  label={({ dataEntry }) => dataEntry.value}
                  data={chartData}
                  totalValue={total}
                  labelStyle={{
                    fontSize: '5px',
                    fontFamily: 'sans-serif',
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                  animate
                  onClick={(event, index) => {
                    setSelected(index);
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ margin: 'auto', fontSize: legendSize }}>
                {
                  chartData.map((data, index) => (
                    <div key={index} style={{ display: 'flex', marginBottom: 10 }}> <div style={{ backgroundColor: data.color, width: legendSize, height: legendSize, marginRight: 10 }}></div><b>{data.title}</b></div>
                  ))
                }
              </Grid>
            </Grid>
          </>
        }

        <Grid item xs={12}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                loading={loading}
                autoHeight
                getRowId={(row) => row.WorkOrderID}
                rows={workOrders}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15]}
                // checkboxSelection
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
              />
            </Box>

          </Paper>
        </Grid>

      </Grid>

    </div>

  );
};
