import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import 'semantic-ui-css/semantic.min.css'

export default function TablePlaceholder() {
    return (
        <div style={{ padding: '10px 20px' }} >
            <Box sx={{ width: '100%' }}>
                <Typography component="div" variant={"h1"}>
                    <Skeleton animation="wave" />
                </Typography>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
        </div>
    )

}
