//@ts-nocheck
import {
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { Button as MuiButton } from '@mui/material';
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {  Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { NumericFormat } from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserID, getDateTime, inputFieldVariant } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

export const UpdateQuotation = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data

  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(null)
  const [quotation, setQuotation] = useState(filteredState);
  const [contacts, setContacts] = useState<Array<any>>([])
  const [sites, setSites] = useState<Array<any>>([])

  const { handleSubmit, control, setValue } = useForm();


  const getSites = () => {
    axios
      .get(`${SERVER_IP}site`)
      .then((res) => {
        setSites(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getContactsForSite = (id) => {
    axios
      .get(`${SERVER_IP}contactbysite?SiteID="${id}"`)
      .then((res) => {
        setContacts(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const updateQuotation = async (data) => {

    let temp = {
      ...quotation,
      ...data,
      AddedByUserID: getCurrentUserID(),
      AddedDateTime: getDateTime(),
      Amount:amount
    }
    console.log(temp)
    delete temp["ContactName"]
    delete temp["SiteName"]
    setLoading(true)
    axios.put(`${SERVER_IP}SalesQuotation/update?SalesQuotationID=${temp.SalesQuotationID}`, temp)
      .then((res) => {
        toast.success("Quotation Successfully Updated");
      }).then(() => navigate("/Quotation", {
        state: state
      }))
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(error?.response?.data?.message || "Couldn't Update Quotation");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          toast.error(error?.message || "Couldn't Update Quotation");
        }
      }).finally(() => setLoading(false))
  };


  useEffect(() => {
    getSites()
    getContactsForSite(quotation.SiteID)
  }, []);


  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(updateQuotation)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update Quotation"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="QuotationNumber"
                      control={control}
                      defaultValue={quotation.QuotationNumber}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Quotation Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteID"
                      control={control}
                      defaultValue={quotation.SiteID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            label="Site"
                            onChange={(e) => {
                              getContactsForSite(e.target.value);
                              onChange(e);
                              setValue("ContactID", null)
                            }}
                          >
                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="ContactID"
                      control={control}
                      defaultValue={quotation.ContactID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Contact</InputLabel>
                          <Select
                            value={value}
                            label="Contact"
                            onChange={onChange}
                          >
                            {contacts.map((e) => (
                              <MenuItem key={e.ContactID} value={e.ContactID}>{e.ContactName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Validity"
                      control={control}
                      defaultValue={quotation.Validity}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Validity"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          inputProps={{ maxLength: 15 }}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="AccountNumber"
                      control={control}
                      defaultValue={quotation.AccountNumber}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Account Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          inputProps={{ maxLength: 15 }}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required', maxLength : { value : 15, message : "Max 15 Chars"} }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Description"
                      control={control}
                      defaultValue={quotation.Description}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextareaAutosize
                          placeholder="Description"
                          minRows={5}
                          maxLength={1024}
                          style={{ width: '100%' }}
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ margin: 'auto' }}>
                    <label>Amount</label>
                    <NumericFormat
                      required
                      className="custom-number-input"
                      placeholder="Enter the Amount"
                      defaultValue={quotation.Amount}
                      onValueChange={(e)=>setAmount(e.formattedValue)}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="QuotationDate"
                      control={control}
                      defaultValue={quotation.QuotationDate}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Quotation Date"
                            inputFormat="dd/MM/yyyy"
                            // disableFuture
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              variant={inputFieldVariant}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              {...params} />
                            }
                          />
                        </LocalizationProvider>

                      )}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Update Quotation"}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <MuiButton
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault()
                      setLoading(true)
                      axios
                        .post(`${SERVER_IP}SalesQuotation/email`, {
                          SalesQuotationID: quotation.SalesQuotationID
                        })
                        .then((res) => {
                          toast.success("Email Sent Successfully to Client")
                        }).catch((err) => {
                          console.log(err)
                          toast.error("Couldn't Send Email...Check if Contact's Email is Correct or Try After Sometime")
                        }).finally(() => setLoading(false))
                    }}
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Send Email"}
                  </MuiButton>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/Quotation", {
                      state: state
                    })}>
                    Back
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
