// @ts-nocheck
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  Typography,
  TableRow,
  TableHead,
  TableBody,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { base64Converter, dateFormat, dateTimeFormat, getCurrentUserID, getDateTime, inputFieldVariant, removeBadgeStyle, useStyles } from "../../utils";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { useForm, Controller } from 'react-hook-form';
import {
  FormHelperText,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Badge,
  TableSortLabel,
  Box
} from "@mui/material";
import ImageViewer from "../../assert/ImageViewer";
import pdfIcon from '../../assert/pdf_icon.png';
import docIcon from '../../assert/document_icon.png'
import workOrderDummyData from './WorkOrderDummyData.json'
import TablePlaceholder from "../TablePlaceholder";
import { Container, Row, Col } from 'react-bootstrap'
import invoiceDummyData from './InvoiceDummyData.json';
import { visuallyHidden } from '@mui/utils';


export const UpdatePO = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data
  const classes = useStyles();

  const { handleSubmit, control, setValue } = useForm();

  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState<any>([]);
  const [staffs, setStaffs] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);
  const [removedFiles, setRemovedFiles] = useState<Array<any>>([]);
  const [existingFiles, setExistingFiles] = useState<Array<any>>(JSON.parse(filteredState.POImageURL));
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [workers, setWorkers] = useState<any>([]);
  const [showImagePreview, setShowImagePreview] = useState<Boolean>(false);
  const [sites, setSites] = useState<any>([]);
  const [siteZones, setSiteZones] = useState<any>([]);
  const [workTypes, setWorkTypes] = useState<any>([]);
  const [tableKey, setTableKey] = useState(0);
  const [tableKey1, setTableKey1] = useState(0);
  const [workStatuses, setWorkStatuses] = useState<any>([]);
  const [workNatures, setWorkNatures] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [POStatuses, setPOStatuses] = useState<any>([]);
  const [PO, setPO] = useState(filteredState);


  const [orderBy, setOrderBy] = useState('');
  const [InvNoState, setInvNoState] = useState('');
  const [InvDateState, setInvDateState] = useState('');
  const [InvBillState, setInvBillState] = useState('');

  const [workOrders, setWorkOrders] = useState([
    { ...workOrderDummyData, id: 0 },
  ]);

  const [invoices, setInvoices] = useState([
    { ...invoiceDummyData, id: 0 },
  ]);

  const cumulativeBill = invoices.reduce((total, val) => total + (parseInt(val.BilledAmount) | 0), 0)
  setValue('BilledAmount', cumulativeBill)


  const getContactsForCompany = (id) => {
    axios
      .get(`${SERVER_IP}contactcompany?CompanyID="${id}"`)
      .then((res) => {

        setContacts(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getCompanies = () => {
    axios
      .get(`${SERVER_IP}company/getAll`)
      .then((res) => {

        setCompanies(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const fetchWorkers = async () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}workers`
      )
      .then((res) => {
        setWorkers(res.data)
      })
      .catch((res) => {
      }).finally(() => setLoading(false))
  };

  const getPOStatuses = () => {
    axios
      .get(`${SERVER_IP}POStatus`)
      .then((res) => {

        setPOStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getStaffs = () => {
    axios
      .get(`${SERVER_IP}staffdetails`)
      .then((res) => {

        setStaffs(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {

        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSites = () => {
    axios
      .get(`${SERVER_IP}sitedetails`)
      .then((res) => {

        setSites(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getWorkTypes = () => {
    axios
      .get(`${SERVER_IP}worktype`)
      .then((res) => {

        setWorkTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getWorkStatuses = () => {
    axios
      .get(`${SERVER_IP}workstatus`)
      .then((res) => {

        setWorkStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getWorkNatures = () => {
    axios
      .get(`${SERVER_IP}worknature`)
      .then((res) => {

        setWorkNatures(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const handleTableInput = async (e, index) => {
    let { name, value } = e.target;
    let workOrder = workOrders;
    if (name === "SiteZoneID") {
      axios
        .get(`${SERVER_IP}sitelistbyzone?SiteZoneID=${e.target.value}`)
        .then((res) => {
          workOrder[index] = {
            ...workOrder[index],
            Sites: res.data,
          };
          setWorkOrders([...workOrder]);
        }).catch((err) => {
          console.log(err)
        });
    }
    workOrder[index] = {
      ...workOrder[index],
      [name]: value,
    }
    setWorkOrders([...workOrder]);
  }

  const handleTableInput1 = async (e, index) => {
    let { name, value } = e.target;
    let invoice = invoices;
    // console.log(name,value)
    if(name === "BilledAmount"){
      if (!value.match(/^\d+(\.\d{0,2})?$/)) {
        return
      }
    }
    invoice[index] = {
      ...invoice[index],
      [name]: value,
    }
    setInvoices([...invoice]);
  }

  const fetchWorkOrders = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}poworkorder?POID=${filteredState.POID}`)
      .then((res) => {
        let certs = res.data?.map((data, index) => {
          return {
            ...workOrderDummyData,
            id: index,
            ...data
          }
        })
        setWorkOrders([...certs])
      }).catch((error) => {
        console.log(error)
      }).finally(() => setLoading(false))
  };

  const updatePO = async (data) => {

    let files = await uploadedFiles?.map(async d => {
      let t = await base64Converter(d)
      return {
        data: t,
        fileName: d.name
      }
    })

    Promise.all(files).then(function (results) {
      // console.log(results)
      // console.log(removedFiles)
      let WorkOrders = workOrders.filter(c => checkTableInputRow(c.id))
      let Invoices = invoices.filter(c => checkTableInputRow1(c.id))

      WorkOrders = WorkOrders.map(workOrder => {
        workOrder["UpdatedByUserID"] = getCurrentUserID();
        workOrder["UpdatedDateTime"] = getDateTime();
        workOrder["RequestedStartDate"] = dateFormat(workOrder["RequestedStartDate"], "YYYY/MM/DD");
        workOrder["RequestedEndDate"] = dateFormat(workOrder["RequestedEndDate"], "YYYY/MM/DD");
        workOrder["AssignedDateTime"] = dateTimeFormat(workOrder["AssignedDateTime"], "YYYY/MM/DD HH:mm:ss");
        delete workOrder["id"]
        delete workOrder["Sites"]
        return workOrder
      })

      Invoices = Invoices.map(invoice => {
        invoice["UpdatedByUserID"] = getCurrentUserID();
        invoice["UpdatedDateTime"] = 2022;
        invoice["POID"] = filteredState.POID;
        delete invoice["id"]
        return invoice
      })

      let temp = {
        ...PO,
        ...data,
        POImageURL: results,
        POdate: dateFormat(data.POdate, "YYYY/MM/DD"),
        toBeRemoved: removedFiles,
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        WorkOrders: WorkOrders,
        Invoices: Invoices
      }
      delete temp["CompanyName"]
      delete temp["ContactName"]
      delete temp["StaffName"]
      delete temp["POStatus"]
      delete temp["Workers"]
      delete temp["POID"]
      delete temp["poInvoiceDetails"]

      // console.log(temp)
      setLoading(true)
      axios.put(`${SERVER_IP}po?POID=${filteredState.POID}`, temp)
        .then((res) => {
          toast.success("updated successfully!");
          navigate("/POSummary", {
            state: state
          })
        })
        .catch((res) => {
          toast.error("updation Failed!");
        }).finally(() => setLoading(false))
    })
  }

  useEffect(() => {
    let temp = existingFiles.map((d, index) => {
      return {
        ...d,
        id: index
      }
    })
    setExistingFiles(temp)
    getCompanies()
    getStaffs();
    getSites();
    fetchWorkers()
    getSiteZones()
    getWorkTypes();
    getWorkStatuses();
    getWorkNatures();
    fetchWorkOrders();
    getPOStatuses();
    getContactsForCompany(PO.CompanyID)
    temp = filteredState.poInvoiceDetails.map((data, index) => {
      return {
        ...invoiceDummyData,
        id: index,
        ...data
      }
    })
    if (temp.length)
      setInvoices([...temp])
  }, []);

  const checkTableInputRow = (id) => {
    let workOrder = workOrders.filter((workOrder) => workOrder.id === id)[0];
    return (
      !!workOrder.SiteID &&
      !!workOrder.WorkStatusID &&
      !!workOrder.WorkNatureID &&
      !!workOrder.WorkTypeID &&
      !!workOrder.RequestedEndDate &&
      !!workOrder.AssignedDateTime &&
      !!workOrder.RequestedStartDate
    );
  };

  const checkTableInputRow1 = (id) => {
    let invoice = invoices.filter((invoice) => invoice.id === id)[0];
    return (
      !!invoice?.InvoiceNo &&
      !!invoice?.InvoiceDate &&
      !!invoice?.BilledAmount
    );
  };

  const handleFileRemoval = (data) => {
    let temp = removedFiles
    temp.push(data)
    setRemovedFiles(temp)

    temp = existingFiles.filter(d => d.id !== data.id)
    setExistingFiles(temp)

    if (temp.length === 0) {
      document.getElementById("POImageURL").value = ''
    }
  }

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <form style={{ width: '85%' }} onSubmit={handleSubmit(updatePO)}>
            <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}`,   width: '98%' }}>

              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    {"Update PO"}
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="POnumber"
                      control={control}
                      defaultValue={PO.POnumber}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="PO Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: 'This field is required', pattern: {
                          value: /PO(\d{6})$/,
                          message: "Invalid Format(Should be POxxxxxx where x are numbers)",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="Description"
                      control={control}
                      defaultValue={PO.Description}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Description"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="POdate"
                      control={control}
                      defaultValue={PO.POdate}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="PO Date"
                            inputFormat="dd/MM/yyyy"
                            // disableFuture
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              style={{ borderBottom: !!error && '2px solid red' }}
                              variant={inputFieldVariant}
                              fullWidth
                              {...params} />
                            }
                          />
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </LocalizationProvider>

                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="Amount"
                      control={control}
                      defaultValue={PO.Amount}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Amount"
                          fullWidth
                          type={"number"}
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value: /^\d+(\.\d{0,2})?$/,
                          message: "Max 2 digits Only"
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="BilledAmount"
                      control={control}
                      defaultValue={PO.BilledAmount}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Billed Amount"
                          fullWidth
                          disabled
                          type={"number"}
                          variant={inputFieldVariant}
                          value={cumulativeBill}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="BalanceAmount"
                      control={control}
                      defaultValue={PO.BalanceAmount}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Balance Amount"
                          fullWidth
                          type={"number"}
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value: /^\d+(\.\d{0,2})?$/,
                          message: "Max 2 digits Only"
                        }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="StaffID"
                      control={control}
                      defaultValue={PO.StaffID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Staff In Charge</InputLabel>
                          <Select
                            value={value}
                            label="Staff In Charge"
                            onChange={onChange}
                          >

                            {staffs.map((e) => (
                              <MenuItem key={e.StaffID} value={e.StaffID}>{e.StaffName}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="CompanyID"
                      control={control}
                      defaultValue={PO.CompanyID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Company</InputLabel>
                          <Select
                            value={value}
                            label="Staff In Charge"
                            onChange={(e) => {
                              getContactsForCompany(e.target.value);
                              onChange(e);
                            }}
                          >
                            {companies.map((e) => (
                              <MenuItem key={e.CompanyID} value={e.CompanyID}>{e.CompanyName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="ContactID"
                      control={control}
                      defaultValue={PO.ContactID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Contact</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {contacts.map((e) => (
                              <MenuItem key={e.ContactID} value={e.ContactID}>{e.ContactName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="POStatusID"
                      control={control}
                      defaultValue={PO.POStatusID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {POStatuses.map((e) => (
                              <MenuItem key={e.POStatusID} value={e.POStatusID}>{e.POStatus}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>


                  <Grid item xs={12}>
                    <Typography>PO Image (PDF Only)</Typography>
                    <input
                      type="file"
                      accept={".pdf"}
                      name="POImageURL"
                      id="POImageURL"
                      multiple
                      onChange={async (e) => {
                        if (!!e.target.files?.length) {
                          let pendingFiles = []
                          let existingFiles = uploadedFiles.map(f => f.name)
                          var inp = document.getElementById('POImageURL');
                          for (var i = 0; i < inp.files.length; ++i) {
                            if (!existingFiles.includes(inp.files.item(i).name))
                              pendingFiles.push(inp.files.item(i))
                          }
                          if (pendingFiles.length === 0) {
                            toast.error("File(s) with same name already added")
                            e.stopPropagation();
                            return
                          }
                          setUploadedFiles([...uploadedFiles, ...pendingFiles])
                        }
                      }}
                    />
                  </Grid>

                  <Container>
                    <Row>
                      {
                        existingFiles.map((d, index) => {

                          if (d?.data?.includes("jpg")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={d.data}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(d.data)
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">

                                  </Badge>
                                </Grid>

                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else if (d?.data?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">

                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Download and View"
                                    src={docIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">

                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>

                  <Container>
                    <Row>

                      {
                        uploadedFiles?.map((d, index) => {

                          if (d?.type?.includes("image")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={URL.createObjectURL(d)}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(URL.createObjectURL(d))
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else if (d?.type?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Download and View"
                                    src={docIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>

                </Grid>
              </Grid>

            </Grid>

            {(loading) ?
              <TablePlaceholder />
              :
              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  padding: 10,
                  border: `2px solid ${PrimaryColor}`,
                   width: '98%'
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      Invoice
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid item container spacing={1} direction="row">
                      <TableContainer style={{ maxWidth: '98%', overflowX: 'auto' }}>
                        <Table
                          aria-label="customized table"
                          className={classes.table}
                          key={tableKey1}
                        >
                          <TableHead>
                            <TableRow color="primary">
                              <TableCell >
                                <TableSortLabel
                                  active={orderBy === 'InvoiceNo'}
                                  direction={orderBy === 'InvoiceNo' ? InvNoState : 'asc'}
                                  onClick={() => {
                                    let state = InvNoState
                                    let temp = invoices
                                    temp.sort((a, b) => {
                                      let a1 = parseInt(a.InvoiceNo)
                                      let b1 = parseInt(b.InvoiceNo)
                                      if (a1 > b1) {
                                        return state === 'desc' ? 1 : -1;
                                      }
                                      if (a1 < b1) {
                                        return state === 'desc' ? -1 : 1;
                                      }
                                      return 0;
                                    });
                                    setInvoices([...temp])
                                    setTableKey1(Math.random());
                                    setInvNoState(InvNoState !== 'desc' ? 'desc' : 'asc')
                                    console.log(InvNoState)
                                    setOrderBy('InvoiceNo')
                                  }}
                                >
                                  {'Invoice Number'}
                                  {orderBy === 'InvoiceNo' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {InvNoState === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell>
                                <TableSortLabel
                                  active={orderBy === 'InvoiceDate'}
                                  direction={orderBy === 'InvoiceDate' ? InvDateState : 'asc'}
                                  onClick={() => {
                                    let state = InvDateState
                                    let temp = invoices
                                    temp.sort((a, b) => {
                                      let a1 = new Date(a.InvoiceDate)
                                      let b1 = new Date(b.InvoiceDate)
                                      return state === 'desc' ? b1-a1 : a1-b1;
                                    });
                                    setInvoices([...temp])
                                    setTableKey1(Math.random());
                                    setInvDateState(InvDateState !== 'desc' ? 'desc' : 'asc')
                                    setOrderBy('InvoiceDate')
                                  }}
                                >
                                  {'Invoice Date'}
                                  {orderBy === 'InvoiceDate' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {InvDateState === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell>
                                <TableSortLabel
                                  active={orderBy === 'BilledAmount'}
                                  direction={orderBy === 'BilledAmount' ? InvBillState : 'asc'}
                                  onClick={() => {
                                    let state = InvBillState
                                    let temp = invoices
                                    temp.sort((a, b) => {
                                      let a1 = parseInt(a.BilledAmount)
                                      let b1 = parseInt(b.BilledAmount)
                                      if (a1 > b1) {
                                        return state === 'desc' ? 1 : -1;
                                      }
                                      if (a1 < b1) {
                                        return state === 'desc' ? -1 : 1;
                                      }
                                      return 0;
                                    });
                                    setInvoices([...temp])
                                    setTableKey1(Math.random());
                                    setInvBillState(InvBillState !== 'desc' ? 'desc' : 'asc')
                                    setOrderBy('BilledAmount')
                                  }}
                                >
                                  {'Billed Amount'}
                                  {orderBy === 'BilledAmount' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {InvBillState === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              invoices.map((invoice, index) => (
                                <TableRow key={index}>

                                  <TableCell style={{ width: 250 }}>
                                    <TextField
                                      variant="outlined"
                                      value={invoices[index].InvoiceNo}
                                      name="InvoiceNo"
                                      className={classes.dateInput}
                                      onChange={(e) => handleTableInput1(e, index)}
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 250 }}>
                                    <TextField
                                      variant="outlined"
                                      name="InvoiceDate"
                                      id="InvoiceDate"
                                      value={!!invoices[index].InvoiceDate ? moment(invoices[index].InvoiceDate).format('YYYY-MM-DD') : invoices[index].InvoiceDate}
                                      onChange={(e) => handleTableInput1(e, index)}
                                      className={classes.dateInput}
                                      type={"date"}
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 250 }}>
                                    <TextField
                                      type={'number'}
                                      variant="outlined"
                                      value={invoices[index].BilledAmount}
                                      name="BilledAmount"
                                      className={classes.numberInput}
                                      onChange={(e) => handleTableInput1(e, index)}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                      {invoices.length !== 0 && (
                                        <RemoveCircle
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => {
                                            setTableKey1(Math.random());
                                            if (invoices.length === 1) {
                                              let temp = {
                                                ...invoiceDummyData,
                                                id: 0,
                                              };
                                              setInvoices([temp]);
                                            } else {
                                              let temp = invoices;
                                              temp.splice(index, 1);
                                              setInvoices([...temp]);
                                            }
                                          }}
                                        />
                                      )}

                                      {index === invoices.length - 1 &&
                                        checkTableInputRow1(invoice.id) && (
                                          <AddCircle
                                            style={{ cursor: "pointer", color: PrimaryColor }}
                                            onClick={() => {
                                              let temp = invoices;
                                              let newId =
                                                invoices[
                                                  invoices.length - 1
                                                ].id + 1;
                                              temp.push({
                                                ...invoiceDummyData,
                                                id: newId,
                                              });
                                              setInvoices([...temp]);
                                            }}
                                          />
                                        )}
                                    </div>

                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }

            {(loading) ?
              <TablePlaceholder />
              :
              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`,
                   width: '98%'
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      Work Orders
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto", overflow: 'hidden' }}>
                    <Grid item container spacing={1} direction="row">
                      <TableContainer style={{ overflow: 'auto hidden' }}>
                        <Table
                          className={classes.table}
                          key={tableKey}

                        >
                          <TableHead>
                            <TableRow color="primary">
                              <TableCell>Order No</TableCell>
                              <TableCell>Created Type</TableCell>
                              <TableCell>Site Zone</TableCell>
                              <TableCell>Site</TableCell>
                              <TableCell>Work Type</TableCell>
                              <TableCell>Work Status</TableCell>
                              <TableCell>Work Nature</TableCell>
                              <TableCell>Workers</TableCell>
                              <TableCell>Invoices</TableCell>
                              <TableCell>Requested Start Date</TableCell>
                              <TableCell>Requested End Date</TableCell>
                              <TableCell>Assigned Date Time</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              workOrders.map((workOrder, index) => (
                                <TableRow key={index}>
                                  <TableCell style={{ width: '100px' }}>
                                    <TextField
                                      variant="outlined"
                                      value={workOrders[index].WorkOrderID}
                                      className={classes.dateInput}
                                      disabled
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 100 }}>
                                    <TextField
                                      variant="outlined"
                                      value={workOrders[index].CreatedType}
                                      className={classes.dateInput}
                                      disabled
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={workOrders[index].SiteZoneID}
                                      name="SiteZoneID"
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {siteZones.map((e) => (
                                        <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={workOrders[index].SiteID}
                                      name="SiteID"
                                      // disabled={!!workOrders[index].WorkOrderID}
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {(!!workOrders[index].Sites && !!workOrders[index].Sites.length) ? workOrders[index].Sites.map((e) => (
                                        <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                                      )) :
                                        sites.map((e) => (
                                          <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                                        ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={workOrders[index].WorkTypeID}
                                      name="WorkTypeID"
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {workTypes.map((e) => (
                                        <MenuItem key={e.WorkTypeID} value={e.WorkTypeID}>{e.WorkTypeName}</MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={workOrders[index].WorkStatusID}
                                      name="WorkStatusID"
                                      disabled
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {workStatuses.map((e) => (
                                        <MenuItem key={e.WorkStatusID} value={e.WorkStatusID}>{e.WorkStatus}</MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={workOrders[index].WorkNatureID}
                                      name="WorkNatureID"
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {workNatures.map((e) => (
                                        <MenuItem key={e.WorkNatureID} value={e.WorkNatureID}>{e.WorkNature}</MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <Select
                                      className={classes.dropDown}
                                      multiple
                                      variant="outlined"
                                      value={workOrders[index].AssignedWorkers}
                                      name="AssignedWorkers"
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {workers.map((e) => (
                                        <MenuItem key={e.StaffID} value={e.StaffID}>{e.StaffName}</MenuItem>
                                      ))}
                                    </Select>

                                  </TableCell>
                                  <TableCell style={{ width: 200 }}>
                                    <TextField
                                      variant="outlined"
                                      value={workOrders[index].WOInvoice}
                                      name="WOInvoice"
                                      className={classes.dateInput}
                                      onChange={(e) => handleTableInput(e, index)}
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <TextField
                                      variant="outlined"
                                      name="RequestedStartDate"
                                      id="RequestedStartDate"
                                      value={!!workOrders[index].RequestedStartDate ? moment(workOrders[index].RequestedStartDate).format('YYYY-MM-DD') : workOrders[index].RequestedStartDate}
                                      onChange={(e) => handleTableInput(e, index)}
                                      className={classes.dateInput}
                                      type={"date"}
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}>
                                    <TextField
                                      variant="outlined"
                                      name="RequestedEndDate"
                                      id="RequestedEndDate"
                                      InputProps={{ inputProps: { min: !!workOrders[index].RequestedStartDate ? moment(workOrders[index].RequestedStartDate).format('YYYY-MM-DD') : undefined } }}
                                      value={!!workOrders[index].RequestedEndDate ? moment(workOrders[index].RequestedEndDate).format('YYYY-MM-DD') : workOrders[index].RequestedEndDate}
                                      onChange={(e) => handleTableInput(e, index)}
                                      className={classes.dateInput}
                                      type={"date"}
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 200 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DateTimePicker
                                        className={classes.dateInput}
                                        inputFormat="dd/MM/yyyy hh:mm a"
                                        value={workOrders[index].AssignedDateTime || null}
                                        onChange={(e) => handleTableInput({
                                          target: {
                                            name: "AssignedDateTime",
                                            value: e,
                                          }
                                        }, index)}
                                        renderInput={(params) => <TextField
                                          variant={"outlined"}
                                          fullWidth
                                          style={{ width: 'inherit' }}
                                          className={classes.dateInput}
                                          {...params} />
                                        }
                                      />
                                    </LocalizationProvider>
                                  </TableCell>

                                  <TableCell>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                      {false && workOrders.length !== 0 && (
                                        <RemoveCircle
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => {
                                            setTableKey(Math.random());
                                            if (workOrders.length === 1) {
                                              let temp = {
                                                ...workOrderDummyData,
                                                id: 0,
                                              };
                                              setWorkOrders([temp]);
                                            } else {
                                              let temp = workOrders;
                                              temp.splice(index, 1);
                                              setWorkOrders([...temp]);
                                            }
                                          }}
                                        />
                                      )}

                                      {index === workOrders.length - 1 &&
                                        checkTableInputRow(workOrder.id) && (
                                          <AddCircle
                                            style={{ cursor: "pointer", color: PrimaryColor }}
                                            onClick={() => {
                                              let temp = workOrders;
                                              let newId =
                                                workOrders[
                                                  workOrders.length - 1
                                                ].id + 1;
                                              temp.push({
                                                ...workOrderDummyData,
                                                id: newId,
                                              });
                                              setWorkOrders([...temp]);
                                            }}
                                          />
                                        )}
                                    </div>

                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid container style={{ marginBottom: '10px' }}>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: "center" }}
              >
                <Button
                  disabled={loading}
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                  {"Update PO"}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: "center" }}
              >
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={(e) => navigate("/POSummary", {
                    state: state
                  })}>
                  Back
                </Button>
              </Grid>

            </Grid>
          </form>
        </Grid>
        <ToastContainer />
        <ImageViewer
          src={imagePreview}
          show={showImagePreview}
          handleClose={() => setShowImagePreview(false)}
          fileName="POImage.jpg"
        />
      </div>
    </>

  );
};
