import ECSReportsTable from "./ECSReportTable";
import { Sidebar } from "../Sidebar";

export const ECSReportPage = () => {

  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <ECSReportsTable />
      </div>
    </>
  );
};
