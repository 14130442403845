// @ts-nocheck
import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { useState, FormEvent, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { inputFieldVariant, getCurrentUserID, getDateTime, dateTimeFormat, dateFormat } from "../../utils";
import WorkOrderData from "./WorkOrderData.json"
import { DatePicker, LocalizationProvider, DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useForm, Controller } from 'react-hook-form';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  FormHelperText,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Box,
  InputAdornment,
  Stack,
  Chip,
} from "@mui/material";

const columns: GridColDef[] = [
  // {
  //   field: 'StaffID',
  //   headerName: 'Worker ID',
  //   width: 100
  // },
  {
    field: 'StaffName',
    headerName: 'Worker Name',
    width: 200
  }
];


export const UpdateWorkOrder = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data
  const filterQuery = state.filterQuery

  const { handleSubmit, control } = useForm();


  const [loading, setLoading] = useState(false)
  const [workers, setWorkers] = useState<any>([]);
  const [workTypes, setWorkTypes] = useState<any>([]);
  const [workStatuses, setWorkStatuses] = useState<any>([]);
  const [availableWorkers, setAvailableWorkers] = useState<Array<any>>([]);
  const [selectedWorkers, setSelectedWorkers] = useState<Array<any>>([]);
  const [linkedWorkers, setLinkedWorkers] = useState<Array<any>>([]);
  const [workOrder, setWorkOrder] = useState(filteredState || WorkOrderData);
  const [POs, setPOs] = useState<any>([]);
  const [rescheduleReason, setRescheduleReason] = useState<any>('');

  const getWorkTypes = () => {
    axios
      .get(`${SERVER_IP}worktype`)
      .then((res) => {
        // console.log(res)
        setWorkTypes(res.data);
      }).catch((res) => {
        console.log(res)
      });
  };

  const fetchPO = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}po`
      )
      .then(async (res) => {
        let temp = res.data.message
        if (temp.length) {
          setPOs(temp);
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch POs!");
      }).finally(() => setLoading(false))
  };

  const fetchLinkedWorkers = (workers = workers) => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}assignedworker?WorkOrderID=${filteredState?.WorkOrderID}`)
      .then((res) => {
        // console.log(res.data)
        let workerIds = res.data.map((data) => data.StaffID)
        // console.log(workerIds)
        setLinkedWorkers(workerIds)

        let temp = workers.filter(worker => workerIds.includes(worker.StaffID))

        // console.log(temp)
        setSelectedWorkers([...temp])

        temp = workers.filter(worker => !workerIds.includes(worker.StaffID))
        // console.log(temp)
        setAvailableWorkers([...temp])


      }).catch((res) => {
        console.log(res)
        setAvailableWorkers(workers)
      }).finally(() => setLoading(false))
  };



  const getWorkStatuses = () => {
    axios
      .get(`${SERVER_IP}workstatus`)
      .then((res) => {
        // console.log(res)
        setWorkStatuses(res.data);
      }).catch((res) => {
        console.log(res)
      });
  };

  const fetchWorkers = async () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}workers`
      )
      .then((res) => {
        setWorkers(res.data);
        fetchLinkedWorkers(res.data)
      })
      .catch((res) => {
      }).finally(() => setLoading(false))
  };

  const updateWorkOrder = async (data) => {
    // console.log(linkedWorkers)
    let temp = {
      ...workOrder,
      ...data,
      UpdatedByUserID: getCurrentUserID(),
      UpdatedDateTime: getDateTime(),
      AssignedWorkers: linkedWorkers,
      AssignedDateTime: dateTimeFormat(workOrder.AssignedDateTime, "YYYY/MM/DD HH:mm:ss"),
      RequestedStartDate: dateFormat(workOrder.RequestedStartDate, "YYYY/MM/DD"),
      RequestedEndDate: dateFormat(workOrder.RequestedEndDate, "YYYY/MM/DD")
    }
    delete temp['WorkerName']
    delete temp['Workers']
    delete temp['WorkStatus']
    delete temp['WorkTypeName']
    delete temp['Description']
    delete temp['StaffName']
    delete temp['StaffID']
    delete temp['SiteName']
    console.log(temp)
    setLoading(true)
    axios.put(`${SERVER_IP}workOrder?WorkOrderID=${temp.WorkOrderID}`, temp)
      .then((res) => {
        console.log(res)
        toast.success("updated successfully!");
        navigate("/WorkOrder", {
          state: state
        })
      })
      .catch((res) => {
        console.log(res)
        toast.error("Updation Failed!");
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    getWorkTypes();
    getWorkStatuses();
    fetchWorkers();
    fetchPO();
    if (filteredState.WorkStatusID === 6) {
      setLoading(true)
      axios
        .get(
          `${SERVER_IP}getRescheduleReason?WorkOrderID=${filteredState.WorkOrderID}`
        )
        .then((res) => {
          console.log(res.data)
        })
        .catch((res) => {
        }).finally(() => setLoading(false))
    }
  }, []);


  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <form onSubmit={handleSubmit(updateWorkOrder)}>
            <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    {"Update WorkOrder"}
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="WorkOrderID"
                      control={control}
                      defaultValue={workOrder.WorkOrderID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Work Order ID"
                          fullWidth
                          disabled
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="POID"
                      control={control}
                      defaultValue={workOrder.POID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>PO Number</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {[{ POID: "", POnumber: "None" }, ...POs].map((e) => (
                              <MenuItem key={e.POID} value={e.POID}>{e.POnumber}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    // rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="WorkTypeID"
                      control={control}
                      defaultValue={workOrder.WorkTypeID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Work Type</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                            disabled
                          >

                            {workTypes.map((e) => (
                              <MenuItem key={e.WorkTypeID} value={e.WorkTypeID}>{e.WorkTypeName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="WorkStatusID"
                      control={control}
                      defaultValue={workOrder.WorkStatusID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Work Status</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >

                            {workStatuses.map((e) => (
                              <MenuItem key={e.WorkStatusID} value={e.WorkStatusID}>{e.WorkStatus}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="RequestedStartDate"
                      control={control}
                      defaultValue={workOrder.RequestedStartDate}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Requested Start Date"
                            inputFormat="dd/MM/yyyy"
                            disabled
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              variant={inputFieldVariant}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              {...params} />
                            }
                          />
                        </LocalizationProvider>

                      )}
                    // rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="RequestedEndDate"
                      control={control}
                      defaultValue={workOrder.RequestedEndDate}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Requested End Date"
                            inputFormat="dd/MM/yyyy"
                            disabled
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              variant={inputFieldVariant}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              {...params} />
                            }
                          />
                        </LocalizationProvider>

                      )}
                    // rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Assigned Date Time"
                        inputFormat="yyyy/MM/dd hh:mm a"
                        value={workOrder.AssignedDateTime || null}
                        onChange={(newValue) => {
                          setWorkOrder({
                            ...workOrder,
                            AssignedDateTime: newValue
                          })
                        }}
                        renderInput={(params) => <TextField
                          variant={inputFieldVariant}
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="WOInvoice"
                      control={control}
                      defaultValue={workOrder.WOInvoice}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Invoices"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  {
                    filteredState.WorkStatusID === 6 &&
                    <Grid item xs={12} sm={9} style={{ fontWeight: 'bold', fontSize: 20, margin: 'auto' }}>
                      Reschedule Reason : {filteredState.RescheduledReason}
                    </Grid>
                  }


                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                      Selected Workers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                      <Box
                        sx={{
                          height: 300,
                          width: 1,
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: PrimaryColor,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.StaffID}
                          rows={selectedWorkers}
                          columns={columns}
                          pageSize={4}
                          onSelectionModelChange={(newSelection) => {
                            console.log(newSelection)
                            if (!!newSelection.length) {
                              console.log(newSelection)
                              let temp = selectedWorkers.filter(worker => newSelection.includes(worker.StaffID))
                              setSelectedWorkers(temp)

                              let linked = temp.map(worker => worker.StaffID)
                              setLinkedWorkers(linked)

                              temp = workers.filter(worker => !linked.includes(worker.StaffID))
                              setAvailableWorkers(temp)
                            }
                            else {
                              setSelectedWorkers([])
                              setLinkedWorkers([])
                              setAvailableWorkers(workers)
                            }
                          }}
                          selectionModel={linkedWorkers}
                          loading={loading}
                          rowsPerPageOptions={[4]}
                          checkboxSelection
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                      Available Workers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                      <Box
                        sx={{
                          height: 300,
                          width: 1,
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: PrimaryColor,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.StaffID}
                          rows={availableWorkers}
                          columns={columns}
                          pageSize={4}
                          onSelectionModelChange={(newSelection) => {
                            // console.log(newSelection)
                            if (!!newSelection.length) {
                              // console.log(newSelection)
                              let temp = workers.filter(worker => worker.StaffID === newSelection[0])
                              // console.log(temp)
                              let data = [...selectedWorkers]
                              // console.log(data)
                              data.push(temp[0])
                              setSelectedWorkers(data)

                              let linked = data.map(worker => worker.StaffID)
                              setLinkedWorkers(linked)
                              temp = workers.filter(worker => !linked.includes(worker.StaffID))
                              setAvailableWorkers(temp)
                            }
                          }}
                          // selectionModel={linkedWorkers}
                          loading={loading}
                          rowsPerPageOptions={[4]}
                          checkboxSelection
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {!!filteredState ? "Update WorkOrder" : "Create WorkOrder"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/WorkOrder", {
                      state: state
                    })}>
                    Back
                  </Button>
                </Grid>

              </Grid>

              <ToastContainer />
            </Grid>
          </form>
        </Grid>

      </div >
    </>

  );
};