// @ts-nocheck
import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { TextField } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { useForm, Controller } from 'react-hook-form';

export const AssignWorker = () => {

  const { handleSubmit, control } = useForm();

  const { state } = useLocation();
  const [job, setPO] = useState(state || {
    JONumber: "fgt",
    PONumber: "",
    PODate: "",
    Company: "",
    Contact: "",
    StaffInCharge: "",
    POImage: ""
  });



  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    // console.log(name, value)
    setPO({
      ...job,
      [name]: value
    })
  };

  const assignWorker = async (data): Promise<void> => {
    toast.info("Submitted")
    // setLoading(true)
    // let temp = {
    //   ...job,
    //   POMapImageURL: await base64Converter(job.POMapImageURL),
    //   AddedByUserID: getCurrentUserID(),
    //   AddedDateTime: getDateTime(),
    // }
    // console.log(temp)
    // axios
    //   .post(
    //     `${SERVER_IP}job`,
    //     temp
    //   )
    //   .then((res) => {
    //     toast.success("created success!");
    //     // console.log(res, "res");
    //     navigate("/PO");
    //   })
    //   .catch((res) => {
    //     toast.error("creation Failed!");
    //   }).finally(() => setLoading(false))
  };



  useEffect(() => {
    // getPOZones();
    // getPOTypes();
  }, []);

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

            <Grid container>
              <form onSubmit={handleSubmit(assignWorker)}>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Assign Worker"}
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="JONumber"
                      control={control}
                      defaultValue={job.JONumber}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="First Name"
                          variant="standard"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'First name required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <TextField
                      id="time"
                      label="Assigned Time"
                      type="time"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="POFrequency"
                      name="POFrequency"
                      label="PO Frequency"
                      fullWidth
                      onChange={onChange}
                      value={job.POFrequency}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="SiteZone"
                      name="SiteZone"
                      label="Site Zone"
                      fullWidth
                      onChange={onChange}
                      value={job.SiteZone}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Start Date"
                        inputFormat="dd/MM/yyyy"
                        // disableFuture
                        value={job.POStartDate || null}
                        onChange={(newValue) => setStaff({
                          ...job,
                          POStartDate: newValue
                        })}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="POStartDate"
                          name="POStartDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="SiteName"
                      name="SiteName"
                      label="Site Name"
                      fullWidth
                      onChange={onChange}
                      value={job.SiteName}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="End Date"
                        inputFormat="dd/MM/yyyy"
                        // disableFuture
                        value={job.POStartDate || null}
                        onChange={(newValue) => setStaff({
                          ...job,
                          POStartDate: newValue
                        })}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="POStartDate"
                          name="POStartDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="POType"
                      name="POType"
                      label="PO Type"
                      fullWidth
                      onChange={onChange}
                      value={job.POType}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Assigned Date"
                        inputFormat="dd/MM/yyyy"
                        // disableFuture
                        value={job.POStartDate || null}
                        onChange={(newValue) => setStaff({
                          ...job,
                          POStartDate: newValue
                        })}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="POStartDate"
                          name="POStartDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="POStatus"
                      name="POStatus"
                      label="PO Status"
                      fullWidth
                      onChange={onChange}
                      value={job.POStatus}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <TextField
                      id="time"
                      label="Assigned Time"
                      type="time"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      fullWidth
                    />

                  </Grid> */}

                </Grid>
                <Grid container style={{ marginBottom: '10px' }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      type="submit"
                    >
                      {"Assign PO"}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                    // onClick={(e) => navigate("/PO_Summary")}
                    >
                      Cancel
                    </Button>
                  </Grid>

                </Grid>
              </form>

            </Grid>



            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
