import axios from "axios";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
    Select,
    MenuItem,
    InputLabel,
    Grid,
    FormControl,
} from "@material-ui/core";
import {
    LocalizationProvider,
    DatePicker,
    DateTimePicker
} from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Backdrop, Fade, Modal, CircularProgress, FormHelperText } from '@mui/material';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { Sidebar } from "../Sidebar";
import EditIcon from '@mui/icons-material/Edit';
import { Box, Paper, Toolbar, Typography, TextField } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { dateFormat, dateTimeFormat, getCurrentUserID, getDateTime, getLoggedUserRole, inputFieldVariant, isLoggedUserStaff, isSuperUser } from "../../utils";
import TablePlaceholder from "../TablePlaceholder";
import { Controller, useForm } from "react-hook-form";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxWidth: '90%',
    maxHeight: '90vh'
};

const SampleData = [
    // {
    //     "ScanID": 84190,
    //     "UID": "040b1522e37381",
    //     "PointID": 202,
    //     "UserID": 14,
    //     "ScanDateTime": "2022-04-04 07:37:02",
    //     "PointNumber": "1"
    // },
    // {
    //     "ScanID": 84191,
    //     "UID": "040b1522e37381",
    //     "PointID": 203,
    //     "UserID": 14,
    //     "ScanDateTime": "2022-04-04 08:37:02",
    //     "PointNumber": "1"
    // },
    // {
    //     "ScanID": 84192,
    //     "UID": "040b1522e37381",
    //     "PointID": 204,
    //     "UserID": 14,
    //     "ScanDateTime": "2022-04-04 09:37:02",
    //     "PointNumber": "1"
    // },
]

export const UpdateECSReport = () => {

    const [loading, setLoading] = useState(false)
    const [addButtonClicked, setAddButtonClicked] = useState(false)
    const [selectedSiteZone, setSelectedSiteZone] = useState<any>('')
    const [selectedSiteType, setSelectedSiteType] = useState<any>('')
    const [selectedSiteName, setSelectedSiteName] = useState<any>('')
    const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
    const [selectedPoint, setSelectedPoint] = useState<any>('')
    const [editButtonClicked, setEditButtonClicked] = useState(false)
    const [pageSize, setPageSize] = useState(5)
    const [reports, setReports] = useState<Array<any>>(SampleData);
    const [siteZones, setSiteZones] = useState<any>([]);
    const [siteTypes, setSiteTypes] = useState<any>([]);
    const [siteNames, setSiteNames] = useState<any>([]);
    const [pointNumbers, setPointNumbers] = useState<any>([]);
    // const [pointNumbers, setPointNumbers] = useState<any>(Array.from({length: 20}, (_, i) => i + 1));

    const [selectedReport, setSelectedReport] = useState<any>({});

    const { handleSubmit, control, setValue, reset } = useForm();

    const isStaff = isLoggedUserStaff()

    const getSiteNames = (type = selectedSiteType, zone = selectedSiteZone) => {

        setValue("SiteID", "")
        if (!!!type || !!!zone) {
            setSiteNames([])
            return
        }

        axios
        .get(`${SERVER_IP}ecssitename?ContactID=${getCurrentUserID()}&Staff=${isStaff}&SiteTypeID=${type}&SiteZoneID=${zone}`)
        .then((res) => {
          setSiteNames(res.data);
        }).catch((err) => {
          console.log(err)
        });
    };

    const fetchECSReport = (siteID = selectedSiteName, month = selectedMonth, pointID = selectedPoint) => {

        if (!selectedSiteZone || !selectedSiteType || !siteID || !pointID) {
            return
        }
        setLoading(true)
        axios
            .get(
                `${SERVER_IP}ECSreportsByPointNumber?SiteZoneID=${selectedSiteZone || null}&SiteTypeID=${selectedSiteType || null}&SiteID=${siteID || null}&ScanDateTime=${dateFormat(month, "YYYY-MM-DD")}&PointID=${pointID || null}&Staff=${getLoggedUserRole()}`,
            )
            .then((res) => {
                setReports(res.data)
                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Couldn't fetch ECSReports!");
            }).finally(() => {
                setLoading(false)
            })
    };

    const columns: GridColDef[] = [
        {
            field: 'PointID',
            headerName: 'Point ID',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            field: 'PointNumber',
            headerName: 'Point Number',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            field: 'ScanDateTime',
            headerName: 'Scan DateTime',
            width: 200,
            editable: true,
            flex: 1,
            renderCell: (row) => row.value || "NA"
        },
        {
            field: 'Action',
            headerName: 'Action Buttons',
            sortable: false,
            filterable: false,
            width: 300,
            hide: !isSuperUser(),
            align: 'center',
            headerAlign: 'center',
            renderCell: (row) => {
                return (
                    <div key={row.id} style={{ display: 'flex', margin: 'auto' }}>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            style={{ marginRight: '15px' }}
                            startIcon={<EditIcon />}
                            onClick={() => {
                                setSelectedReport(row.row)
                                setEditButtonClicked(true)
                            }}
                        >
                            Edit
                        </Button>
                        {/* <Button
                            color="error"
                            size="small"
                            variant="contained"
                            style={{ }}
                            onClick={() => {
                                if (window.confirm("Are You Sure?")) {
                                    deleteReport(row.id)
                                }
                            }}
                        >
                            Delete
                        </Button> */}
                    </div>
                )
            }
        },
    ];

    const createECSReport = (data) => {

        let temp = {
            PointID : selectedPoint,
            UID : "",
            ScanDateTime: dateTimeFormat(data.ScanDateTime, "YYYY-MM-DD HH:mm:ss"),
            UserID: getCurrentUserID(),
            AddedDateTime: getDateTime(),
        }
        console.log(temp)
        setLoading(true)
        axios
            .post(
                `${SERVER_IP}saveScanDetails`,
                temp
            )
            .then((res) => {
                toast.success("Created Successfully!");
                fetchECSReport();
                reset();
                setAddButtonClicked(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Creation Failed!");
            }).finally(() => {
                setLoading(false)
            })
    };

    const updateReport = async () => {

        console.log(selectedReport)

        setLoading(true)
        let temp = {
            ScanDateTime: dateTimeFormat(selectedReport.ScanDateTime, "YYYY-MM-DD HH:mm:ss")
        }
        // console.log(temp)
        axios.put(`${SERVER_IP}updatePointDetails?ScanID=${selectedReport.ScanID}`, temp)
            .then((res) => {
                toast.success("Updated Successfully!");
                fetchECSReport()
            })
            .catch((res) => {
                toast.error("Updation Failed!");
            }).finally(() => {
                handleModalClose()
                setLoading(false)
            })

    }

    const deleteReport = async (id) => {
        setLoading(true)
        axios.delete(`${SERVER_IP}ecsreport?ScanID=${id}`)
            .then((res) => {
                toast.success("Deleted Successfully!");
            })
            .catch((res) => {
                toast.error("Couldn't Delete!");
            }).finally(() => setLoading(false))
    }

    const handleModalClose = () => {
        reset()
        setAddButtonClicked(false)
        setEditButtonClicked(false)
    }

    const getSiteZones = () => {
        axios
            .get(`${SERVER_IP}sitezone`)
            .then((res) => {

                setSiteZones(res.data);
            }).catch((err) => {
                console.log(err)
            });
    };

    const getPointNumbers = (siteId) => {
        if (!selectedSiteZone || !siteId) {
            return
        }
        axios
            .get(`${SERVER_IP}getPointNumber?SiteZoneID=${selectedSiteZone}&SiteID=${siteId}`)
            .then((res) => {

                setPointNumbers(res.data);
            }).catch((err) => {
                console.log(err)
            });
    };

    const getSiteTypes = () => {
        axios
            .get(`${SERVER_IP}sitetype`)
            .then((res) => {

                setSiteTypes(res.data);
            }).catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        getSiteZones();
        getSiteTypes();
    }, []);

    return (
        <>
            <div style={{ marginTop: 80, display: 'flex' }}>
                <Sidebar />
                <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>


                    <Grid container spacing={3} style={{ padding: '10px' }}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Site Zone</InputLabel>
                                <Select
                                    id="SiteZone"
                                    label="Site Zone"
                                    value={selectedSiteZone}
                                    onChange={function (event) {
                                        setReports([]);
                                        setSelectedSiteZone(event.target.value);
                                        !!selectedSiteType && getSiteNames(undefined, event.target.value)
                                    }}
                                >
                                    {siteZones.map((e) => (
                                        <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Site Type</InputLabel>
                                <Select
                                    id="SiteTypeID"
                                    value={selectedSiteType}
                                    label="Site Type"
                                    onChange={function (event) {
                                        setReports([]);
                                        setSelectedSiteType(event.target.value);
                                        !!selectedSiteZone && getSiteNames(event.target.value, undefined)
                                    }}
                                >

                                    {siteTypes.map((e) => (
                                        <MenuItem key={e.SiteTypeID} value={e.SiteTypeID}>{e.Description}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Site Name</InputLabel>
                                <Select
                                    value={selectedSiteName}
                                    label="Site Name"
                                    onChange={function (e) {
                                        setSelectedSiteName(e.target.value);
                                        setReports([]);
                                        getPointNumbers(e.target.value);
                                    }}
                                >

                                    {siteNames.map((e) => (
                                        <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Month"
                                    views={['month', 'year']}
                                    value={selectedMonth}
                                    onChange={(newValue) => {
                                        setSelectedMonth(newValue)
                                        fetchECSReport(undefined, newValue, undefined)
                                    }}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        id="selectedMonth"
                                        name="selectedMonth"
                                        fullWidth
                                        {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Point Number</InputLabel>
                                <Select
                                    id="SelectedPoint"
                                    value={selectedPoint}
                                    label="Point Number"
                                    onChange={(e) => {
                                        setSelectedPoint(e.target.value);
                                        setReports([]);
                                        fetchECSReport(undefined, undefined, e.target.value);
                                    }}
                                >

                                    {pointNumbers.map((e) => (
                                        <MenuItem key={e.PointID} value={e.PointID}>{e.PointNumber}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>


                    <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

                        <Grid container style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                            <Box sx={{ width: '100%' }}>
                                {(loading) ?
                                    <TablePlaceholder />
                                    :
                                    <div style={{ padding: '10px 20px' }}>
                                        <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
                                            <Toolbar
                                                sx={{
                                                    pl: { sm: 2 },
                                                    pr: { xs: 1, sm: 1 },
                                                    backgroundColor: '#d9dffd',
                                                }}
                                            >
                                                <Typography
                                                    sx={{ flex: '1 1 50%' }}
                                                    variant="h6"
                                                    id="tableTitle"
                                                    component="div"
                                                >
                                                    ECS Reports
                                                </Typography>
                                                <Typography
                                                    sx={{ marginLeft: 'auto' }}
                                                >
                                                    <Button
                                                        color="success"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => setAddButtonClicked(true)}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add ECS Entry
                                                    </Button>
                                                </Typography>
                                            </Toolbar>
                                            <Box
                                                sx={{
                                                    '& .MuiDataGrid-columnHeaders': {
                                                        backgroundColor: PrimaryColor,
                                                        color: 'white'
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    autoHeight
                                                    getRowId={(row) => row.ScanID}
                                                    rows={reports}
                                                    columns={columns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5, 10, 15, reports.length > 100 ? 100 : reports.length]}
                                                    disableSelectionOnClick
                                                    onPageSizeChange={(size) => setPageSize(size)}
                                                />
                                            </Box>

                                        </Paper>

                                    </div>
                                }
                                <ToastContainer />
                            </Box>

                        </Grid>

                        <ToastContainer />
                    </Grid>
                </Grid>

                <Modal
                    open={editButtonClicked}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={editButtonClicked}>
                        <Box sx={modalStyle}>
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    margin: "10px auto",
                                    borderRadius: "10px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        style={{ backgroundColor: PrimaryColor, color: "white" }}
                                        variant="h4"
                                        gutterBottom
                                    >
                                        {"Update ECS Report"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Point ID"
                                        variant={inputFieldVariant}
                                        value={selectedReport.PointID}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Point Number"
                                        variant={inputFieldVariant}
                                        value={selectedReport.PointNumber}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <h3 style={{ textAlign: 'right', paddingTop: 10 }}>Scan Date Time</h3>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            value={selectedReport.ScanDateTime || null}
                                            onChange={(value) => {
                                                setSelectedReport({
                                                    ...selectedReport,
                                                    ScanDateTime: value
                                                })
                                            }}
                                            renderInput={(params) => <TextField
                                                variant={"outlined"}
                                                fullWidth
                                                style={{ width: 'inherit' }}
                                                {...params} />
                                            }
                                        />
                                    </LocalizationProvider>

                                </Grid>
                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Button
                                            disabled={loading}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => updateReport()}
                                        >
                                            {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                            Update
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Button
                                            color="secondary"
                                            size="small"
                                            variant="contained"
                                            onClick={(e) => handleModalClose()}>
                                            Cancel
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    open={addButtonClicked}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={addButtonClicked}>
                        <Box sx={modalStyle}>
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    margin: "10px auto",
                                    borderRadius: "10px",
                                }}
                            >

                                <Grid item xs={12}>
                                    <Typography
                                        style={{ backgroundColor: PrimaryColor, color: "white" }}
                                        variant="h4"
                                        gutterBottom
                                    >
                                        {"Add ECS Data"}
                                    </Typography>
                                </Grid>
                                <form style={{ width: "90%" }} onSubmit={handleSubmit(createECSReport)}>

                                    <Grid container spacing={3} style={{ margin: 'auto' }}>
 

                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="ScanDateTime"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (

                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DateTimePicker
                                                            label="Scan Date Time"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value || null}
                                                            onChange={onChange}
                                                            renderInput={(params) => <TextField
                                                                style={{ borderBottom: !!error ? '2px solid red' : '' }}
                                                                variant={inputFieldVariant}
                                                                fullWidth
                                                                {...params} />
                                                            }
                                                        />
                                                        {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                                                    </LocalizationProvider>
                                                )}
                                                rules={{ required: 'This field is required' }}
                                            />
                                        </Grid>

                                        <Grid container style={{ marginBottom: '10px' }}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    disabled={loading}
                                                    color="primary"
                                                    size="small"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                                    Add
                                                </Button>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    variant="contained"
                                                    onClick={(e) => handleModalClose()}>
                                                    Cancel
                                                </Button>
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>

                        </Box>
                    </Fade>
                </Modal>

            </div>
        </>

    );
};
