// @ts-nocheck
import {
    Grid,
    TextField,
    Typography,
    Button
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserID, getDateTime, inputFieldVariant } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from 'moment'

export const UpdateDSSDReport = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false)
    const [sites, setSites] = useState<any>([]);
    const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date(state?.Report_Month));

    const { handleSubmit, control, watch } = useForm();


    const updateReport = async (data) => {
        let temp = {
            ...state,
            ...data,
            SS_A: parseFloat(data.SS_A),
            SS_B: parseFloat(data.SS_B),
            SS_C: parseFloat(data.SS_C),
            SS_D: parseFloat(data.SS_D),
            SS_E: parseFloat(data.SS_E),
            SS_F: parseFloat(data.SS_F),
            D_A: parseFloat(data.D_A),
            D_B: parseFloat(data.D_B),
            D_C: parseFloat(data.D_C),
            D_D: parseFloat(data.D_D),
            D_E: parseFloat(data.D_E),
            D_F: parseFloat(data.D_F),
            Monthly_Rate: parseFloat(data.Monthly_Rate),
            Total_D: parseInt(DAValue) + parseInt(DBValue) + parseInt(DCValue) + parseInt(DDValue) + parseInt(DEValue) + parseInt(DFValue),
            Report_Month: moment(selectedMonth).format('YYYY-MM-DD'),
            UpdatedUserID: getCurrentUserID(),
            UpdateDateTime: getDateTime(),
        }
        delete temp['S_No']
        delete temp['SiteName']
        console.log(temp)
        setLoading(true)
        axios
            .put(
                `${SERVER_IP}reportDSSD/update?ReportDSSDID=${temp.ReportDSSDID}`,
                temp
            )
            .then(() => navigate("/ViewStatusReports", {
                state: "DSSD Report Successfully Updated"
            }))
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Couldn't Update Report");
            }).finally(() => setLoading(false))
    };

    const getSites = () => {
        axios
            .get(`${SERVER_IP}sitedetails`)
            .then((res) => {
                setSites(res.data);
            }).catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        !!!state && navigate('/ViewStatusReports')
        getSites();
    }, []);

    const DAValue = watch("D_A", state?.D_A);
    const DBValue = watch("D_B", state?.D_B);
    const DCValue = watch("D_C", state?.D_C);
    const DDValue = watch("D_D", state?.D_D);
    const DEValue = watch("D_E", state?.D_E);
    const DFValue = watch("D_F", state?.D_F);

    return (

        <>
            <div style={{ marginTop: 80, display: 'flex' }}>
                <Sidebar />
                <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
                    <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
                        <form style={{ width: '100%' }} onSubmit={handleSubmit(updateReport)}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                                        <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                                        {"Update DSSD Report"}
                                    </Typography>
                                </Grid>

                                <Grid container spacing={3} style={{ margin: 'auto' }}>

                                    <Grid item xs={12} sm={3}>

                                        <Controller
                                            name="SiteID"
                                            control={control}
                                            defaultValue={state?.SiteID}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                                    <InputLabel>Site </InputLabel>
                                                    <Select
                                                        value={value}
                                                        label="Site"
                                                        onChange={onChange}
                                                    >

                                                        {sites.map((e) => (
                                                            <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                                                        ))}

                                                    </Select>
                                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Controller
                                            name="Monthly_Rate"
                                            control={control}
                                            defaultValue={state?.Monthly_Rate}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Monthly Rate"
                                                    variant={inputFieldVariant}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Month"
                                                views={['month', 'year']}
                                                // disableFuture
                                                // disabled
                                                value={selectedMonth}
                                                onChange={(newValue) => {
                                                    setSelectedMonth(newValue)
                                                }}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    name="selectedMonth"
                                                    fullWidth
                                                    {...params} />
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Controller
                                            name="Total_D"
                                            control={control}
                                            defaultValue={DAValue + DBValue + DCValue}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Total"
                                                    type={'number'}
                                                    variant={inputFieldVariant}
                                                    value={
                                                        parseInt(DAValue) +
                                                        parseInt(DBValue) +
                                                        parseInt(DCValue) +
                                                        parseInt(DDValue) +
                                                        parseInt(DEValue) +
                                                        parseInt(DFValue)
                                                    }
                                                    fullWidth
                                                    disabled
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="SS_A"
                                            control={control}
                                            defaultValue={state?.SS_A}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Status (A)"
                                                    type={'number'}
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="D_A"
                                            defaultValue={state?.D_A}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Deduction (A)"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type={'number'}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="SS_B"
                                            control={control}
                                            defaultValue={state?.SS_B}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Status (B)"
                                                    type={'number'}
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="D_B"
                                            defaultValue={state?.D_B}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Deduction (B)"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type={'number'}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="SS_C"
                                            control={control}
                                            defaultValue={state?.SS_C}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Status (C)"
                                                    type={'number'}
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="D_C"
                                            defaultValue={state?.D_C}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Deduction (C)"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type={'number'}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="SS_D"
                                            control={control}
                                            defaultValue={state?.SS_D}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Status (D)"
                                                    type={'number'}
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="D_D"
                                            defaultValue={state?.D_D}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Deduction (D)"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type={'number'}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="SS_E"
                                            control={control}
                                            defaultValue={state?.SS_E}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Status (E)"
                                                    type={'number'}
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="D_E"
                                            defaultValue={state?.D_E}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Deduction (E)"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type={'number'}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="SS_F"
                                            control={control}
                                            defaultValue={state?.SS_F}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Status (F)"
                                                    type={'number'}
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="D_F"
                                            defaultValue={state?.D_F}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Deduction (F)"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type={'number'}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginBottom: '10px' }}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ textAlign: "center" }}
                                >
                                    <Button
                                        disabled={loading}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                    >
                                        {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                        {"Update Report"}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ textAlign: "center" }}
                                >
                                    <Button
                                        color="secondary"
                                        size="small"
                                        variant="contained"
                                        onClick={(e) => navigate("/ViewStatusReports")}>
                                        Cancel
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>

                        <ToastContainer />
                    </Grid>
                </Grid>


            </div>
        </>

    );
};
