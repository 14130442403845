// @ts-nocheck
import {

  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Badge } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { base64Converter, dateFormat, getCurrentUserID, getDateTime, inputFieldVariant, removeBadgeStyle, siteImages } from "../../utils";
import pdfIcon from '../../assert/pdf_icon.png'
import docIcon from '../../assert/document_icon.png'
import ImageViewer from "../../assert/ImageViewer";
import { useForm, Controller } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const imageExts = [".jpg", ".jpeg", ".png"]

export const UpdateDoc = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data

  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);
  const [removedFiles, setRemovedFiles] = useState<Array<any>>([]);
  const [existingFiles, setExistingFiles] = useState<Array<any>>(JSON.parse(filteredState.Documents));
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [showImagePreview, setShowImagePreview] = useState<Boolean>(false);
  const [doc, setDoc] = useState(filteredState);
  const [documentDate, setDocumentDate] = useState<any>(filteredState.DocumentDate || null);

  const { handleSubmit, control } = useForm();


  const updateDoc = async (data) => {

    let files = await uploadedFiles?.map(async d => {
      let t = await base64Converter(d)
      return {
        data: t,
        fileName: d.name
      }
    })

    if (!!!documentDate) {
      toast.error("Select a Date")
      return
    }

    Promise.all(files).then(function (results) {
      // console.log(results)
      console.log(removedFiles)
      let temp = {
        ...doc,
        ...data,
        DocumentDate: dateFormat(documentDate, "YYYY-MM-DD"),
        toBeAdded: results,
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        toBeDeleted: removedFiles
      }

      console.log(temp)
      setLoading(true)
      axios.put(`${SERVER_IP}DocumentOperations/update?DocumentsCollectionID=${temp.DocumentsCollectionID}`, temp)
        .then((res) => {
          toast.success("Doc Successfully Updated");
        }).then(() => navigate("/Documents", {
          state: state
        }))
        .catch((res) => {
          toast.error("Couldn't Update Doc");
        }).finally(() => setLoading(false))
    })
  };


  useEffect(() => {
    let temp = existingFiles.map((d, index) => {
      return {
        ...d,
        id: index
      }
    })
    setExistingFiles(temp)
  }, []);


  const handleFileRemoval = (data) => {
    let temp = removedFiles
    temp.push(data)
    setRemovedFiles(temp)

    temp = existingFiles.filter(d => d.id !== data.id)
    setExistingFiles(temp)
    if (temp.length === 0) {
      document.getElementById("Documents").value = ''
    }
  }

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(updateDoc)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update Doc"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="DocName"
                      control={control}
                      defaultValue={doc.DocName}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Doc Name"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Document Date"
                        inputFormat="dd/MM/yyyy"
                        value={documentDate}
                        onChange={(newValue) => {
                          setDocumentDate(newValue)
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="DocumentDate"
                          name="DocumentDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography>Upload Doc (Image, PDF, Word, Excel, PPT)</Typography>
                    <input
                      type="file"
                      accept={siteImages}
                      id="Documents"
                      name="Documents"
                      multiple
                      onChange={async (e) => {
                        if (!!e.target.files?.length) {
                          let pendingFiles = []
                          let existingFiles = uploadedFiles.map(f => f.name)
                          var inp = document.getElementById('Documents');
                          for (var i = 0; i < inp.files.length; ++i) {
                            if (!existingFiles.includes(inp.files.item(i).name))
                              pendingFiles.push(inp.files.item(i))
                          }
                          if (pendingFiles.length === 0) {
                            toast.error("File(s) with same name already added")
                            e.stopPropagation();
                            return
                          }
                          setUploadedFiles([...uploadedFiles, ...pendingFiles])
                        }
                      }}
                    />
                  </Grid>


                  <Container>
                    <Row>
                      {
                        existingFiles.map((d, index) => {

                          if (imageExts.some(el => d?.data?.includes(el))) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={d.data}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(d.data)
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>

                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else if (d?.data?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Download and View"
                                    src={docIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>

                  <Container>
                    <Row>

                      {
                        uploadedFiles?.map((d, index) => {

                          if (d?.type?.includes("image")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={URL.createObjectURL(d)}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(URL.createObjectURL(d))
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else if (d?.type?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Download and View"
                                    src={docIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>


                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Update Entry"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/Documents", {
                      state: state
                    })}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

        <ImageViewer
          src={imagePreview}
          show={showImagePreview}
          handleClose={() => setShowImagePreview(false)}
          fileName="Documents.jpg"
        />

      </div>
    </>

  );
};
