import React, { useEffect, useState } from 'react'
import { Sidebar } from "../Sidebar";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import ViewADCReports from './ViewADCReport';
import ViewDSSDReports from './ViewDSSDReport';
import ViewSDPCReports from './ViewSDPCReport';
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from 'react-router-dom';

export const ViewStatusReports = () => {

  const { state } = useLocation();

  const ReportTypes = [
    "ADC",
    "DSSD",
    "SDPC"
  ]

  const [selectedReportType, setSelectedReportType] = useState<any>("");

  useEffect(()=>{
    !!state && toast.success(state)
  },[])

  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Box style={{ width: '95%' }}>

          <div style={{ padding: '10px 20px', maxWidth: 'inherit' }}>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Report Type</InputLabel>
                <Select
                  label="Report Type"
                  value={selectedReportType}
                  onChange={function (event) {
                    setSelectedReportType(event.target.value);
                  }}
                >
                  {ReportTypes.map((e, index) => (
                    <MenuItem key={index} value={e}>{e}</MenuItem>
                  ))}

                </Select>
              </FormControl>

            </Grid>
            {
              selectedReportType === "ADC" &&
              <ViewADCReports />
            }
            {
              selectedReportType === "DSSD" &&
              <ViewDSSDReports />
            }
            {
              selectedReportType === "SDPC" &&
              <ViewSDPCReports />
            }
          </div>

        </Box>

        <ToastContainer />
      </div>
    </>
  );
};
