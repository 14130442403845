// @ts-nocheck
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TextField,
  Typography,
  TableRow,
  TableHead,
  TableBody,
  Button,
  makeStyles,
  Theme
} from "@material-ui/core";
import MuiTextField from '@mui/material/TextField';
import axios from "axios";
import React, { useState, FormEvent, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Visibility, VisibilityOff, AddCircle, Remove, RemoveCircle } from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { Sidebar } from "../Sidebar";
import { useNavigate } from "react-router-dom";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { inputFieldVariant, base64Converter, getCurrentUserID, getDateTime, acceptedFiles, checkIfFileIsAccepted, imagesOnly, checkIfFileIsImage, checkIfFileIsPDF } from "../../utils";
import moment from "moment";
import StaffData from './StaffData.json'
import { useForm, Controller } from 'react-hook-form';
import {
  FormHelperText,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import pdfIcon from '../../assert/pdf_icon.png'
import ImageViewer from "../../assert/ImageViewer";

const useStyles = makeStyles((theme: Theme) => ({
  dropDown: {
    width: "100%",
    height: 33,
    borderRadius: 4,
    backGround: theme.palette.background.paper,
    boxSizing: "border-box",
    fontSize: 14,
  },
  dateInput: {
    fontSize: 14,
    width: "100%",
    padding: '0',
    '& .MuiInputBase-formControl': {
      height: 33
    }
  },
  table: {
    borderRadius: 8,
    width: "100%",
    fontWeight: "normal",
    "& .MuiTableCell-root": {
      border: "1px solid #ddd",
      textAlign: "center",
      padding: "0px",
    },
    "& .MuiTableCell-head": {
      // background: theme.palette.info.dark,
      padding: "5px 0",
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      color: "gray",
      fontWeight: "normal",
      padding: "5px 0",
    },
  },
}));


let certificateDummyData = {
  CertTypeID: "",
  CertBodyID: "",
  ValidityStartDate: "",
  ValidityEndDate: "",
  CertificateImageURL: "",
  base64Data: "",
  CertFileName: ""
}

export const CreateStaff = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { handleSubmit, control, watch, setValue } = useForm();

  const [loading, setLoading] = useState(false)

  const [genders, setGenders] = useState<any>([]);
  const [staffTypes, setStaffTypes] = useState<any>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<any>([]);
  const [salutations, setSalutations] = useState<any>([]);
  const [staffTitles, setStaffTitles] = useState<any>([]);
  const [accessControls, setAccessControls] = useState<any>([]);
  const [idTypes, setIdTypes] = useState<any>([]);
  const [relationships, setRelationships] = useState<any>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<any>([]);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [showImagePreview, setShowImagePreview] = useState<Boolean>(false);
  const [certificateBodys, setCertificateBodys] = useState<Array<any>>([]);
  const [certificateTypes, setCertificateTypes] = useState<Array<any>>([]);

  const [staff, setStaff] = useState(StaffData);


  const [certificateDetails, setCertificateDetails] = useState([
    { ...certificateDummyData, id: 0 },
  ]);
  const [tableKey, setTableKey] = useState(0);

  const handleTableInput = async (e, index) => {
    let { name, value } = e.target;
    let certificate = certificateDetails;

    if (name === "CertificateImageURL" && !!e.target?.files?.length) {
      if (checkIfFileIsPDF(e.target.files[0])) {
        value = e.target.files[0]
        let d = await base64Converter(!!e.target?.files?.length ? e.target.files[0] : e.target.files)
        certificate[index] = {
          ...certificate[index],
          CertFileName: e.target.files[0].name,
          base64Data: d,
        };
      }
      else {
        toast.error("Upload a PDF File")
        return
      }
    }

    certificate[index] = {
      ...certificate[index],
      [name]: value,
    };
    setCertificateDetails([...certificate]);
  };

  const checkTableInputRow = (id) => {
    let certificate = certificateDetails.filter((certificate) => certificate.id === id)[0];
    return (
      !!certificate.CertTypeID &&
      !!certificate.CertBodyID &&
      !!certificate.ValidityStartDate &&
      !!certificate.ValidityEndDate &&
      !!certificate.CertificateImageURL
    );
  };


  const createStaff = async (data) => {
    if (!!!uploadedFile) {
      toast.error("Staff Image Not Uploaded")
      return
    }

    let CertificateDetails = certificateDetails.filter(c => checkTableInputRow(c.id))

    CertificateDetails = CertificateDetails.map(certificate => {
      certificate["AddedByUserID"] = getCurrentUserID();
      certificate["AddedDateTime"] = getDateTime();
      certificate["CertificateImageURL"] = certificate["base64Data"]
      delete certificate["id"]
      delete certificate["base64Data"]
      return certificate
    })

    let temp = {
      ...staff,
      ...data,
      StaffImageURL: !!uploadedFile ? await base64Converter(uploadedFile) : "",
      AddedByUserID: getCurrentUserID(),
      AddedDateTime: getDateTime(),
      PasswordHash: "password",
      CertificateDetails: CertificateDetails
    }
    // console.log(temp)
    setLoading(true)
    axios
      .post(`${SERVER_IP}staff`, temp)
      .then((res) => {
        toast.success("Staff Successfully Created");
        navigate("/Staff");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Couldn't Create Staff");
      }).finally(() => setLoading(false))
  };


  const getCertificateTypes = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}certificatetype`)
      .then((res) => {

        setCertificateTypes(res.data);
      }).catch((err) => {
        console.log(err)
      }).finally(() => setLoading(false))
  };

  const getCertificateBodys = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}certificatebody`)
      .then((res) => {

        setCertificateBodys(res.data);
      }).catch((err) => {
        console.log(err)
      }).finally(() => setLoading(false))
  };

  const getGenders = () => {
    axios
      .get(`${SERVER_IP}Gender`)
      .then((res) => {

        setGenders(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getIdTypes = () => {
    axios
      .get(`${SERVER_IP}idtype`)
      .then((res) => {

        setIdTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const getEmploymentStatuses = () => {
    axios
      .get(`${SERVER_IP}staffemploymentstatus`)
      .then((res) => {

        setEmploymentStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const getRelationships = () => {
    axios
      .get(`${SERVER_IP}relationship`)
      .then((res) => {

        setRelationships(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getMaritalStatuses = () => {
    axios
      .get(`${SERVER_IP}Maritalstatus`)
      .then((res) => {

        setMaritalStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const getStaffTypes = () => {
    axios
      .get(`${SERVER_IP}staffEmploymentType`)
      .then((res) => {

        setStaffTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getEmploymentTitle = () => {
    axios
      .get(`${SERVER_IP}staffTitle`)
      .then((res) => {

        setStaffTitles(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSalutations = () => {
    axios
      .get(`${SERVER_IP}saluation`)
      .then((res) => {

        setSalutations(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getAccessControls = () => {
    axios
      .get(`${SERVER_IP}AccessControl`)
      .then((res) => {

        setAccessControls(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    getGenders();
    getStaffTypes();
    getEmploymentStatuses()
    getSalutations();
    getEmploymentTitle();
    getAccessControls();
    getIdTypes();
    getRelationships();
    getMaritalStatuses();
    getCertificateBodys();
    getCertificateTypes();
  }, []);

  // const NetValue = (parseInt(watch("BasicPay", 0)) || 0) +
  //   (parseInt(watch("TotalAllowances", 0)) || 0) -
  //   (parseInt(watch("TotalDeductions", 0)) || 0) +
  //   (parseInt(watch("TotalOvertimePay", 0)) || 0) +
  //   (parseInt(watch("OtherAdditionalPayments", 0)) || 0)
  // setValue('NetPay', NetValue)

  return (
    <>
      <div style={{ marginTop: 80, display: "flex" }}>
        <Sidebar />
        <div>
          <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
            <form onSubmit={handleSubmit(createStaff)}>
              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                      gutterBottom
                    >
                      <AccountCircleIcon style={{ margin: "auto 5px" }} />
                      {"Add New Staff"}
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>

                    <Grid item xs={12} sm={4}>

                      <Controller
                        name="SalutationID"
                        control={control}
                        defaultValue={staff.SalutationID}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                            <InputLabel>Salutation</InputLabel>
                            <Select
                              id="Salutation"
                              value={value}
                              label="Salutation"
                              onChange={onChange}
                            >

                              {salutations.map((e) => (
                                <MenuItem key={e.SalutationID} value={e.SalutationID}>{e.Salutation}</MenuItem>
                              ))}

                            </Select>
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                          </FormControl>
                        )}
                        rules={{ required: 'This field is required' }}
                      />

                    </Grid>
                    <Grid item xs={12} sm={4}>

                      <Controller
                        name="StaffName"
                        control={control}
                        defaultValue={staff.StaffName}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Staff Name"
                            fullWidth
                            variant={inputFieldVariant}
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>

                      <Controller
                        name="GenderID"
                        control={control}
                        defaultValue={staff.GenderID}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                            <InputLabel>Gender</InputLabel>
                            <Select
                              id="GenderID"
                              value={value}
                              label="Gender"
                              onChange={onChange}
                            >

                              {genders.map((e) => (
                                <MenuItem key={e.GenderID} value={e.GenderID}>{e.Gender}</MenuItem>
                              ))}

                            </Select>
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                          </FormControl>
                        )}
                        rules={{ required: 'This field is required' }}
                      />

                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Mobile"
                        control={control}
                        defaultValue={staff.Mobile}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Mobile Number"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Address1"
                        control={control}
                        defaultValue={staff.Address1}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Address 1"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Telephone"
                        control={control}
                        defaultValue={staff.Telephone}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Telephone"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Address2"
                        control={control}
                        defaultValue={staff.Address2}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Address 2"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Email"
                        control={control}
                        defaultValue={staff.Email}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Email"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="PostCode"
                        control={control}
                        defaultValue={staff.PostCode}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Post Code"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Nationality"
                        control={control}
                        defaultValue={staff.Nationality}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Nationality"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="Passport"
                        control={control}
                        defaultValue={staff.Passport}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Passport Number"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>

                      <Controller
                        name="IDTypeID"
                        control={control}
                        defaultValue={staff.IDTypeID}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                            <InputLabel>ID Type</InputLabel>
                            <Select
                              value={value}
                              label="ID Type"
                              onChange={onChange}
                            >

                              {idTypes.map((e) => (
                                <MenuItem key={e.IDTypeID} value={e.IDTypeID}>{e.IDType}</MenuItem>
                              ))}

                            </Select>
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                          </FormControl>
                        )}
                        rules={{ required: 'This field is required' }}
                      />

                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="ID"
                        control={control}
                        defaultValue={staff.ID}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="ID"
                            variant={inputFieldVariant}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={3} style={{ margin: "auto" }}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="NextOfKin"
                      control={control}
                      defaultValue={staff.NextOfKin}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Next of Kin Name"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="RelationshipID"
                      control={control}
                      defaultValue={staff.RelationshipID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Next of Kin Relationship</InputLabel>
                          <Select
                            value={value}
                            label="Next of Kin Relationship"
                            onChange={onChange}
                          >

                            {relationships.map((e) => (
                              <MenuItem key={e.RelationshipID} value={e.RelationshipID}>{e.Relationship}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="NextOfKinMobile"
                      control={control}
                      defaultValue={staff.NextOfKinMobile}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Next of Kin Mobile"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      <AccountCircleIcon style={{ margin: "auto 5px" }} />
                      Personal Particulars
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid container spacing={3} style={{ margin: "auto" }}>

                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="DOB"
                          control={control}
                          defaultValue={staff.DOB}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date Of Birth"
                                inputFormat="dd/MM/yyyy"
                                // disableFuture
                                value={value || null}
                                onChange={onChange}
                                renderInput={(params) => <TextField
                                  variant={inputFieldVariant}
                                  fullWidth
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  {...params} />
                                }
                              />
                            </LocalizationProvider>

                          )}
                        // rules={{ required: 'This field is required' }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>

                        <Controller
                          name="MartialStatusID"
                          control={control}
                          defaultValue={staff.MartialStatusID}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                              <InputLabel>Marital Status</InputLabel>
                              <Select
                                value={value}
                                label="Marital Status"
                                onChange={onChange}
                              >

                                {maritalStatuses.map((e) => (
                                  <MenuItem key={e.MaritalStatusID} value={e.MaritalStatusID}>{e.MaritalStatus}</MenuItem>
                                ))}

                              </Select>
                              {error && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                          )}
                          rules={{ required: 'This field is required' }}
                        />

                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="Religion"
                          control={control}
                          defaultValue={staff.Religion}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Religion"
                              variant={inputFieldVariant}
                              value={value}
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                          rules={{ required: 'This field is required' }}
                        />
                      </Grid>


                      <Grid item xs={12}>
                        <Typography>Staff Photo (Images Only)</Typography>
                        <input
                          type="file"
                          accept={imagesOnly}
                          name="StaffImageURL"
                          onChange={async (e) => {
                            if (checkIfFileIsImage(e.target.files[0])) {
                              setStaff({
                                ...staff,
                                StaffImageFileName: e.target.files[0].name
                              })
                              setUploadedFile(e.target.files[0])
                            }
                            else {
                              toast.error("Invalid File Type")
                              setUploadedFile(null)
                              e.target.value = null
                            }
                          }}
                        />
                      </Grid>

                      {
                        !!uploadedFile && uploadedFile?.type?.includes("image") &&
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid item xs={12}>
                              <img
                                title="Click to Enlarge"
                                src={URL.createObjectURL(uploadedFile)}
                                onClick={() => {
                                  setShowImagePreview(true)
                                  setImagePreview(URL.createObjectURL(uploadedFile))
                                }}
                                style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                alt="preview"
                              />
                            </Grid>
                            <strong>File Name : {staff.StaffImageFileName}</strong>
                          </div>
                        </Grid>
                      }

                      {
                        !!uploadedFile && uploadedFile?.type?.includes("pdf") &&
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid item xs={12}>
                              <img
                                title="Click To View"
                                src={pdfIcon}
                                style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                onClick={() => {
                                  window.open(URL.createObjectURL(uploadedFile))
                                }}
                                alt="preview"
                              />
                            </Grid>
                            <strong>File Name : {staff.StaffImageFileName}</strong>
                          </div>
                        </Grid>
                      }

                    </Grid>
                  </Grid>
                </Grid>


              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      <AccountCircleIcon style={{ margin: "auto 5px" }} />
                      Employment
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid item container spacing={1} direction="row">
                      <Grid container spacing={3} style={{ margin: "auto" }}>

                        <Grid item xs={12} sm={4}>

                          <Controller
                            name="StaffTitleID"
                            control={control}
                            defaultValue={staff.StaffTitleID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Staff Title</InputLabel>
                                <Select
                                  value={value}
                                  label="Staff Title"
                                  onChange={onChange}
                                >
                                  {staffTitles.map((e) => (
                                    <MenuItem key={e.StaffTitleID} value={e.StaffTitleID}>{e.StaffTitle}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />

                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="AccessControlID"
                            control={control}
                            defaultValue={staff.AccessControlID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Access Control</InputLabel>
                                <Select
                                  value={value}
                                  label="Access Control"
                                  onChange={onChange}
                                >
                                  {accessControls.map((e) => (
                                    <MenuItem key={e.AccessControlID} value={e.AccessControlID}>{e.AccessControl}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="StaffEmploymentType"
                            control={control}
                            defaultValue={staff.StaffEmploymentType}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Employment Type</InputLabel>
                                <Select
                                  value={value}
                                  label="Employment Type"
                                  onChange={onChange}
                                >
                                  {staffTypes.map((e) => (
                                    <MenuItem key={e.StaffEmploymentTypeID} value={e.StaffEmploymentTypeID}>{e.StaffEmploymentType}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="EntitledLeave"
                            control={control}
                            defaultValue={staff.EntitledLeave}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Entitled Leave"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="UsedLeave"
                            control={control}
                            defaultValue={staff.UsedLeave}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Used Leave"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="BalanceLeave"
                            control={control}
                            defaultValue={staff.BalanceLeave}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Balance Leave"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="StaffEmploymentStatusID"
                            control={control}
                            defaultValue={staff.StaffEmploymentStatusID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Employment Status</InputLabel>
                                <Select
                                  value={value}
                                  label="Employment Status"
                                  onChange={onChange}
                                >
                                  {employmentStatuses.map((e) => (
                                    <MenuItem key={e.StaffEmploymentStatusID} value={e.StaffEmploymentStatusID}>{e.StaffEmploymentStatus}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="JobStartDate"
                            control={control}
                            defaultValue={staff.JobStartDate}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label="Joined Date"
                                  inputFormat="dd/MM/yyyy"
                                  // disableFuture
                                  value={value || null}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  renderInput={(params) => <TextField
                                    variant={inputFieldVariant}
                                    fullWidth
                                    {...params} />
                                  }
                                />
                              </LocalizationProvider>

                            )}
                          // rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="JobEndDate"
                            control={control}
                            defaultValue={staff.JobEndDate}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label="Job End Date"
                                  inputFormat="dd/MM/yyyy"
                                  // disableFuture
                                  value={value || null}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  renderInput={(params) => <TextField
                                    variant={inputFieldVariant}
                                    fullWidth
                                    {...params} />
                                  }
                                />
                              </LocalizationProvider>

                            )}
                          // rules={{ required: 'This field is required' }}
                          />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="Department"
                            control={control}
                            defaultValue={staff.Department}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Department"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {false && <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      <AccountCircleIcon style={{ margin: "auto 5px" }} />
                      Generate Payslip
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid item container spacing={1} direction="row">
                      <Grid container spacing={3} style={{ margin: "auto" }}>

                        <Grid item xs={12} sm={3}>
                          <Controller
                            name="Employer"
                            control={control}
                            defaultValue={staff.Employer}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Employer"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Controller
                            name="Employee"
                            control={control}
                            defaultValue={staff.Employee}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Employee"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Controller
                            name="BasicPay"
                            control={control}
                            defaultValue={staff.BasicPay}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="BasicPay (A)"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                type={"number"}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Controller
                            name="ModeOfPay"
                            control={control}
                            defaultValue={staff.ModeOfPay}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Mode Of Pay</InputLabel>
                                <Select
                                  value={value}
                                  label="Mode Of Pay"
                                  onChange={onChange}
                                >
                                  {["Cash", "Cheque", "Bank Deposit"].map((e) => (
                                    <MenuItem key={e} value={e}>{e}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="TotalAllowances"
                            control={control}
                            defaultValue={staff.TotalAllowances}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Total Allowances (B)"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                type={"number"}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="TotalAllowancesBreakdown"
                            control={control}
                            defaultValue={staff.TotalAllowancesBreakdown}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                placeholder="Total Allowances Breakdown"
                                minRows={3}
                                style={{ width: '100%' }}
                                variant={inputFieldVariant}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="TotalDeductions"
                            control={control}
                            defaultValue={staff.TotalDeductions}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Total Deductions (C)"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                type={"number"}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="TotalDeductionsBreakdown"
                            control={control}
                            defaultValue={staff.TotalDeductionsBreakdown}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                placeholder="Total Deductions Breakdown"
                                minRows={3}
                                style={{ width: '100%' }}
                                variant={inputFieldVariant}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Controller
                            name="TotalOvertimePay"
                            control={control}
                            defaultValue={staff.TotalOvertimePay}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Total Overtime Pay (D)"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                type={"number"}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Controller
                            name="OtherAdditionalPayments"
                            control={control}
                            defaultValue={staff.OtherAdditionalPayments}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Other Additional Payments (E)"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                type={"number"}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="OtherAdditionalPaymentsBreakdown"
                            control={control}
                            defaultValue={staff.OtherAdditionalPaymentsBreakdown}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                placeholder="Other Additional Payments Breakdown"
                                minRows={3}
                                style={{ width: '100%' }}
                                variant={inputFieldVariant}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="OvertimePaymentPeriod"
                            control={control}
                            defaultValue={staff.OvertimePaymentPeriod}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Overtime Payment Period"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="OvertimeHoursWorked"
                            control={control}
                            defaultValue={staff.OvertimeHoursWorked}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Overtime Hours Worked"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="EmployerCPFContributions"
                            control={control}
                            defaultValue={staff.EmployerCPFContributions}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Employer’s CPF Contributions"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="DateofPayment"
                            control={control}
                            defaultValue={staff.DateofPayment}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label="Date of Payment"
                                  inputFormat="dd/MM/yyyy"
                                  // disableFuture
                                  value={value || null}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  renderInput={(params) => <TextField
                                    variant={inputFieldVariant}
                                    fullWidth
                                    {...params} />
                                  }
                                />
                              </LocalizationProvider>

                            )}
                          // rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="NetPay"
                            control={control}
                            defaultValue={staff.NetPay}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Net Pay (A+B–C+D+E)"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                disabled
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}

              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      <AccountCircleIcon style={{ margin: "auto 5px" }} />
                      Qualification
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid item container spacing={1} direction="row">
                      <Grid container spacing={3} style={{ margin: "auto" }}>


                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="HighestQualification"
                            control={control}
                            defaultValue={staff.HighestQualification}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Highest Qualification"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  border: `2px solid ${PrimaryColor}`
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      <AccountCircleIcon style={{ margin: "auto 5px" }} />
                      Certificate Details
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid item container spacing={1} direction="row">
                      <TableContainer>
                        <Table
                          aria-label="customized table"
                          className={classes.table}
                          key={tableKey}
                        >
                          <TableHead>
                            <TableRow color="primary">
                              <TableCell>Certificate Type</TableCell>
                              <TableCell>Certification Body</TableCell>
                              <TableCell>Validity Start Date</TableCell>
                              <TableCell>Validity End Date</TableCell>
                              <TableCell>Upload</TableCell>
                              <TableCell>View</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              certificateDetails.map((cert, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={certificateDetails[index].CertTypeID}
                                      name="CertTypeID"
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {certificateTypes.map((e) => (
                                        <MenuItem key={e.CertTypeID} value={e.CertTypeID}>{e.CertTypeName}</MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell>
                                    <Select
                                      variant="outlined"
                                      className={classes.dropDown}
                                      value={certificateDetails[index].CertBodyID}
                                      name="CertBodyID"
                                      onChange={(e) => handleTableInput(e, index)}
                                    >
                                      {certificateBodys.map((e) => (
                                        <MenuItem key={e.CertBodyID} value={e.CertBodyID}>{e.CertBodyName}</MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell width={"20%"}>
                                    <TextField
                                      variant="outlined"
                                      name="ValidityStartDate"
                                      id="ValidityStartDate"
                                      value={!!certificateDetails[index].ValidityStartDate ? moment(certificateDetails[index].ValidityStartDate).format('YYYY-MM-DD') : certificateDetails[index].ValidityStartDate}
                                      onChange={(e) => handleTableInput(e, index)}
                                      className={classes.dateInput}
                                      type={"date"}
                                    />
                                  </TableCell>
                                  <TableCell width={"20%"}>
                                    <TextField
                                      variant="outlined"
                                      name="ValidityEndDate"
                                      id="ValidityEndDate"
                                      value={!!certificateDetails[index].ValidityEndDate ? moment(certificateDetails[index].ValidityEndDate).format('YYYY-MM-DD') : certificateDetails[index].ValidityEndDate}
                                      onChange={(e) => handleTableInput(e, index)}
                                      className={classes.dateInput}
                                      type={"date"}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <input
                                      style={{ display: 'none' }}
                                      accept={".pdf"}
                                      type="file"
                                      id="CertificateImageURL"
                                      name="CertificateImageURL"
                                      onChange={(e) => handleTableInput(e, index)}
                                    />
                                    <Button
                                      color="primary"
                                      onClick={(e) => document.getElementsByName('CertificateImageURL')[index].click()}
                                    >Upload</Button>
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      disabled={!!!certificateDetails[index].CertificateImageURL}
                                      color="secondary"
                                      onClick={() => {
                                        if (!!certificateDetails[index].CertificateImageURL && certificateDetails[index].CertificateImageURL?.type?.includes("image")) {
                                          setShowImagePreview(true)
                                          setImagePreview(URL.createObjectURL(certificateDetails[index].CertificateImageURL))
                                        }
                                        else if (!!certificateDetails[index].CertificateImageURL && certificateDetails[index].CertificateImageURL?.type?.includes("pdf")) {
                                          window.open(URL.createObjectURL(certificateDetails[index].CertificateImageURL))
                                        }
                                      }}
                                    >{certificateDetails[index].CertFileName || "View"}</Button>
                                  </TableCell>
                                  <TableCell>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                      {certificateDetails.length !== 0 && (
                                        <RemoveCircle
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => {
                                            setTableKey(Math.random());
                                            if (certificateDetails.length === 1) {
                                              let temp = {
                                                ...certificateDummyData,
                                                id: 0,
                                              };
                                              setCertificateDetails([temp]);
                                            } else {
                                              let temp = certificateDetails;
                                              temp.splice(index, 1);
                                              setCertificateDetails([...temp]);
                                            }
                                          }}
                                        />
                                      )}

                                      {index === certificateDetails.length - 1 &&
                                        checkTableInputRow(cert.id) && (
                                          <AddCircle
                                            style={{ cursor: "pointer", color: PrimaryColor }}
                                            onClick={() => {
                                              let temp = certificateDetails;
                                              let newId =
                                                certificateDetails[
                                                  certificateDetails.length - 1
                                                ].id + 1;
                                              temp.push({
                                                ...certificateDummyData,
                                                id: newId,
                                              });
                                              setCertificateDetails([...temp]);
                                            }}
                                          />
                                        )}
                                    </div>

                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                    id="qqq"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Create Staff"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button color="secondary" size="small" variant="contained" onClick={(e) => navigate("/Staff")}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>
            </form>
          </Grid>

        </div>

        {/* <Modal
          open={showCertificateImageModal}
          onClose={() => setShowCertificateImageModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showCertificateImageModal}>
            <Box sx={modalStyle}>
              <TransformWrapper
                initialScale={1}
                // initialPositionX={200}
                // initialPositionY={100}
              >
                {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                  <React.Fragment>
                    <div style={{marginBottom : '10px',justifyContent : 'space-around'}}>
                      <MuiButton style={{marginRight : '20px'}} variant="contained" color="success" onClick={() => zoomIn()}>Zoom In +</MuiButton>
                      <MuiButton style={{marginRight : '20px'}} variant="contained" color="warning" onClick={() => zoomOut()}>Zoom Out -</MuiButton>
                      <MuiButton variant="contained" color="error" onClick={() => resetTransform()}>Reset</MuiButton>
                    </div>
                    <TransformComponent>
                      <img src={showCertificateImageURL} alt="uploaded certificate" />
                      <div>Example text</div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Box>
          </Fade>
        </Modal> */}

        <ImageViewer
          src={imagePreview}
          show={showImagePreview}
          handleClose={() => setShowImagePreview(false)}
          fileName="StaffImage.jpg"
        />

        <ToastContainer />
      </div>
    </>
  );
};
