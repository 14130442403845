// @ts-nocheck
import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import Logo from '../assert/logo_top.png'
import { makeStyles } from '@material-ui/core';
import { alpha as fade } from '@material-ui/core/styles'
import InfoIcon from '@mui/icons-material/Info';
import classNames from 'classnames';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
    Grid,
    Container,
} from "@material-ui/core";
import Logout from '@mui/icons-material/Logout';
import AddCardIcon from '@mui/icons-material/AddCard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BadgeIcon from '@mui/icons-material/Badge';
import { useNavigate } from "react-router-dom";
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Notifications as NotificationIcon,
    Description,
    FileCopy,
    CalendarTodayOutlined
} from '@mui/icons-material';
import { PrimaryColor } from '../variables';
import { getCurrentUserName, getLoggedUserRole, getSideMenu, getTitleId, isDeveloper, isLoggedUserStaff, isProgClaimEligible, isStatusReportEligible, isSuperUser } from '../utils';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { useDispatch } from 'react-redux';
import { setSideMenu, userLogout } from '../redux/reducers/users/usersActions';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import LogoIndex from "../assert/logo_index_1.jpeg";

const drawerWidth = 240;
const themeSpacing = 10;
const breadcrumbColor = "rgb(255 255 255 / 50%)"

const useStyles = makeStyles((theme) => ({
    user: {
        justifyContent: 'center'
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 80,
        height: 80,
    },
    appBar: {
        // position: 'absolute',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: PrimaryColor,
        boxShadow: 'none !important',
        '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
            color: 'rgba(255,255,255,.3)'
        },
        '& ::-moz-placeholder': { /* Firefox 19+ */
            color: 'rgba(255,255,255,.3)'
        },
        '& :-ms-input-placeholder': { /* IE 10+ */
            color: 'rgba(255,255,255,.3)'
        },
        '& :-moz-placeholder': { /* Firefox 18- */
            color: 'rgba(255,255,255,.3)'
        },
        '& $menuButton': {
            marginLeft: themeSpacing * 2
        }
    },
    profile: {
        height: 120,
        display: 'flex',
        fontSize: 14,
        padding: 10,
        alignItems: 'center',
        '& h4': {
            fontSize: 18,
            marginBottom: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 110
        },
        '& span': {
            fontSize: 12,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 110,
            display: 'block',
            overflow: 'hidden'
        }
    },
    flex: {
        flex: 1,
        textAlign: 'right'
    },
    appBarShift: {
        transition: theme.transitions.create(['width', 'margin', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('lg')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
        '& $menuButton': {
            marginLeft: 0
        }
    },
    menuButton: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
        }
    },
    hide: {
        display: 'none',
    },
    textField: {
        marginLeft: themeSpacing,
        marginRight: themeSpacing,
        width: 200,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    wrapper: {
        fontFamily: theme.typography.fontFamily,
        position: 'relative',
        marginRight: themeSpacing * 2,
        marginLeft: themeSpacing,
        borderRadius: 2,
        background: fade(theme.palette.common.white, 0.15),
        display: 'inline-block',
        '&:hover': {
            background: fade(theme.palette.common.white, 0.25),
        },
        '& $input': {
            transition: theme.transitions.create('width'),
            width: 180,
            '&:focus': {
                width: 350,
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            },
        },
    },
    search: {
        width: themeSpacing * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    input: {
        padding: `${themeSpacing}px ${themeSpacing}px ${themeSpacing}px ${themeSpacing * 9}px`,
        border: 0,
        display: 'block',
        verticalAlign: 'middle',
        whiteSpace: 'normal',
        background: 'none',
        margin: 0, // Reset for Safari
        color: '#fff',
        width: '100%',
        '&:focus': {
            outline: 0,
        },
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#fff'
        }
    },
    darker: {
        background: theme.palette.primary.dark,
        '&:after': {
            content: '""',
            left: -240,
            width: 'calc(100% + 240px)',
            position: 'absolute',
            bottom: -2,
            height: 1,
            background: '#000',
            filter: 'blur(3px)'
        }
    },
    separatorV: {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        height: 20,
        margin: '0 10px',
        opacity: 0.4
    },
    notifMenu: {
        '& li': {
            height: 'auto',
            '& h3': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }
        }
    },
    badgeMenu: {
        '& span': {
            top: -12,
            right: -30
        }
    },
    textNotif: {
        '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    drawerHeader: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '0',
        ...theme.mixins.toolbar,
        '& h3': {
            color: theme.palette.primary.contrastText,
        }
    },
}))

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export function Sidebar() {
    const classes = useStyles();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(true);
    const [page, setPage] = React.useState<any>("");
    const isStaff = getLoggedUserRole();
    const superUser = isSuperUser();
    const developer = isDeveloper();

    const togglePage = (data) => {
        if (page === data) {
            setPage("")
            dispatch(setSideMenu(""))
        }
        else {
            setPage(data)
            dispatch(setSideMenu(data))
        }
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getBreadcrumb = () => {
        let parts = window.location.pathname.split('/');
        const page = parts[parts.length - 1];
        switch (page.toLowerCase()) {
            case "contact":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Contacts</Typography>
                    </Breadcrumbs>
                )
            case "createcontact":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Contact")}>
                            Contacts
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Add Contact</Typography>
                    </Breadcrumbs>
                )
            case "updatecontact":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Contact")}>
                            Contacts
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Update Contact</Typography>
                    </Breadcrumbs>
                )
            case "staff":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Staff</Typography>
                    </Breadcrumbs>
                )
            case "createstaff":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Staff")}>
                            Staffs
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Add Staff</Typography>
                    </Breadcrumbs>
                )
            case "updatestaff":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Staff")}>
                            Staffs
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Update Staff</Typography>
                    </Breadcrumbs>
                )
            case "certificate_type":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Staff")}>
                            Staffs
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Certificate Type</Typography>
                    </Breadcrumbs>
                )
            case "certificate_body":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Staff")}>
                            Staffs
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Certificate Type</Typography>
                    </Breadcrumbs>
                )
            case "site":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Sites</Typography>
                    </Breadcrumbs>
                )
            case "createsite":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Site")}>
                            Sites
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Add Site</Typography>
                    </Breadcrumbs>
                )
            case "updatesite":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Site")}>
                            Sites
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Update Site</Typography>
                    </Breadcrumbs>
                )
            case "reports":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Reports</Typography>
                    </Breadcrumbs>
                )
            case "reportsbypo":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Reports By PO</Typography>
                    </Breadcrumbs>
                )
            case "job_summary":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>PO Summary</Typography>
                    </Breadcrumbs>
                )
            case "add_job":
                return (
                    <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                            Home
                        </Typography>
                        <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/POSummary")}>
                            PO
                        </Typography>
                        <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>Add PO</Typography>
                    </Breadcrumbs>
                )
            default:
                let p = page.replace(/([A-Z])/g, ' $1').trim().split(' ')
                if (p[p.length - 1] === "Report")
                    return (
                        <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                            <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                            <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/Home")}>
                                Home
                            </Typography>
                            <Typography style={{ color: "#fff", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => navigate("/ReportsByPO")}>
                                Reports
                            </Typography>
                            <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>{page.replace(/([A-Z])/g, ' $1').trim()}</Typography>
                        </Breadcrumbs>
                    )
                else
                    return (
                        <Breadcrumbs style={{ fontSize: '14px', color: breadcrumbColor }} separator="›" aria-label="breadcrumb">
                            <Typography style={{ fontSize: '14px' }} color={breadcrumbColor}>You're in</Typography>
                            <Typography style={{ fontSize: '14px' }} color="#fff">{page.replace(/([A-Z])/g, ' $1').trim()}</Typography>
                        </Breadcrumbs>
                    )
        }
    }

    React.useEffect(() => {
        let temp = getSideMenu();
        setPage(temp)
    }, [])

    const user = getCurrentUserName();
    const TitleID = getTitleId();

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <AppBar style={{ background: PrimaryColor }} position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{
                                marginRight: '36px',
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <MenuIcon onClick={toggleDrawer} />
                            </div>

                        </IconButton>

                        {getBreadcrumb()}


                        <div style={{
                            flex: 1,
                            textAlign: 'right',
                        }}>
                            {/* <div className={classes.wrapper}>
                                <div className={classes.search}>
                                    <SearchIcon />
                                </div>
                                <input className={classes.input} placeholder="Search" />
                            </div> */}
                            {/* <Badge badgeContent={4} color="secondary">
                                <NotificationIcon />
                            </Badge> */}
                            <Tooltip title="Logout">

                                <IconButton
                                    // onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2, backgroundColor: 'white', fontWeight: 'bold' }}
                                    aria-controls={openMenu ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                >
                                    {/* <Avatar sx={{ width: 32, height: 32 }}>

                                    </Avatar> */}
                                    <MenuItem onClick={() => {
                                        if (window.confirm("Are You Sure to Logout?")) {
                                            dispatch(userLogout())
                                            navigate("/")
                                        }

                                    }}>
                                        <ListItemIcon>
                                            <Logout fontSize="medium" />
                                        </ListItemIcon>
                                        <b>Logout</b>
                                    </MenuItem>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={openMenu}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem>
                                    <Avatar /> My account
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => {
                                    dispatch(userLogout())
                                    navigate("/")
                                }}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>

                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={open}
                >
                    <DrawerHeader style={{ padding: 0, display: 'block' }}>
                        <div style={{ background: PrimaryColor }} className={classes.drawerHeader}>
                            <div style={{ paddingLeft: '14%', paddingTop: '10%' }}>
                                {/* <img src={Logo} alt={"Konnect"} style={{ width: '150px' }} /> */}
                                {<h3 style={{ width: '150px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    <img style={{ width: '100%' }} alt="logo" src={LogoIndex} />
                                    {/* {open ? "Electronic Pest Control Management System " : "Pest Control System"} */}
                                </h3>}
                            </div>
                            <div style={{ height: 'fit-content' }} className={classNames(classes.profile, classes.user)}>
                                <Avatar
                                    style={{ width: !open ? '40px' : '60px', height: !open ? '40px' : '60px', marginTop: !open && '30px' }}
                                    className={classNames(classes.avatar, classes.bigAvatar)}
                                />
                                {
                                    open &&
                                    <div >
                                        <Tooltip title={user || "Konnector"}>
                                            <h4>{user}</h4>
                                        </Tooltip>
                                        {/* <span>{"Admin"}</span> */}
                                    </div>
                                }

                            </div>
                        </div>
                    </DrawerHeader>
                    <Divider />
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', overflowY: 'scroll', overflowX: 'hidden' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton style={{ color: PrimaryColor }} onClick={() => {
                            togglePage("")
                            navigate("/Home")
                        }}>
                            <ListItemIcon style={{ color: 'inherit' }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>

                        {
                            isStaff &&
                            <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("staff")}>
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Staff" />
                                {page === "staff" ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={page === "staff"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Staff')}>
                                    <ListItemIcon>
                                        <PreviewIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Staff" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/CreateStaff')}>
                                            <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add Staff" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Certificate_Type')}>
                                            <ListItemIcon>
                                                <CardMembershipIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Certificate Type" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Certificate_Body')}>
                                            <ListItemIcon>
                                                <BadgeIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Certificate Body" />
                                        </ListItemButton>
                                    </>
                                }

                            </List>
                        </Collapse>

                        {
                            isStaff &&
                            <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("contact")}>
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contacts" />
                                {page === "contact" ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={page === "contact"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Contact')}>
                                    <ListItemIcon>
                                        <PreviewIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Contacts" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/CreateContact')}>
                                        <ListItemIcon>
                                            <AddIcCallIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Contact" />
                                    </ListItemButton>
                                }
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Company')}>
                                    <ListItemIcon>
                                        <EmojiTransportationIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Companies" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddCompany')}>
                                        <ListItemIcon>
                                            <AddBusinessIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Company" />
                                    </ListItemButton>
                                }
                            </List>
                        </Collapse>

                        <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("site")}>
                            <ListItemIcon style={{ color: 'inherit' }}>
                                <ApartmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sites" />
                            {page === "site" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={page === "site"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Site')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Sites" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/CreateSite')} >
                                            <ListItemIcon>
                                                <AddLocationAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add Site" />
                                        </ListItemButton>

                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/SiteZone')} >
                                            <ListItemIcon>
                                                <DomainAddIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Site Zones" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/SiteType')} >
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Site Types" />
                                        </ListItemButton>

                                    </>
                                }

                            </List>
                        </Collapse>

                        <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("report")}>
                            <ListItemIcon style={{ color: 'inherit' }}>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Report" />
                            {page === "report" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={page === "report"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ReportsByPO')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Reports" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ECSReports')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View ECS Reports" />
                                </ListItemButton>
                                {
                                    isProgClaimEligible() &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ProgressiveClaim')} >
                                        <ListItemIcon>
                                            <VisibilityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="View Progressive Claims" />
                                    </ListItemButton>
                                }
                                {
                                    isProgClaimEligible() &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ECSSummaryReport')} >
                                        <ListItemIcon>
                                            <VisibilityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="ECS Summary Report" />
                                    </ListItemButton>
                                }

                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ManualReports')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Manual Reports" />
                                </ListItemButton>

                                {
                                    isStatusReportEligible() &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ViewStatusReports')} >
                                        <ListItemIcon>
                                            <VisibilityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="View Status Reports" />
                                    </ListItemButton>
                                }

                                {
                                    isLoggedUserStaff() && isProgClaimEligible() &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddProgressiveClaim')} >
                                        <ListItemIcon>
                                            <AddCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Progressive Claim" />
                                    </ListItemButton>
                                }

                                {
                                    superUser &&
                                    <>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddManualReport')} >
                                            <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add Manual Reports" />
                                        </ListItemButton>
                                        {
                                            developer &&
                                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/UpdateECSReport')} >
                                                <ListItemIcon>
                                                    <BrowserUpdatedIcon />
                                                </ListItemIcon>
                                                <ListItemText style={{ color: PrimaryColor }} secondary="Developer Only" primary="Update ECS Reports" />
                                            </ListItemButton>
                                        }

                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddADCReport')}>
                                            <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add ADC Report" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddDSSDReport')}>
                                            <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add DSSD Report" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddSDPCReport')}>
                                            <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add SDPC Report" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/ReportNotification')}>
                                            <ListItemIcon>
                                                <NotificationIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Notifications" />
                                        </ListItemButton>
                                    </>
                                }
                            </List>
                        </Collapse>

                        {
                            isStaff &&
                            <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("accounts")}>
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    <FormatListBulletedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Accounts" />
                                {page === "accounts" ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={page === "accounts"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/POSummary')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View PO" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddPO')} >
                                        <ListItemIcon>
                                            <AddCardIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add PO" />
                                    </ListItemButton>
                                }

                            </List>
                        </Collapse>

                        {
                            isStaff &&
                            <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("operations")}>
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    <FormatListBulletedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Operations" />
                                {page === "operations" ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={page === "operations"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    superUser &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/CreateWorkorder')}>
                                        <ListItemIcon>
                                            <AddCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Create Work Order" />
                                    </ListItemButton>
                                }
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/WorkOrder')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Work Order" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/CreateBulkWorkorder')}>
                                        <ListItemIcon>
                                            <AddCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Bulk Work Order" />
                                    </ListItemButton>
                                }
                                {
                                    superUser &&
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/CalenderMgmt')} >
                                        <ListItemIcon>
                                            <CalendarTodayOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Calendar Mgmt" />
                                    </ListItemButton>
                                }
                            </List>
                        </Collapse>

                        <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("sales")}>
                            <ListItemIcon style={{ color: 'inherit' }}>
                                <LoyaltyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sales" />
                            {page === "sales" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={page === "sales"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Invoice')} >
                                    <ListItemIcon>
                                        <Description />
                                    </ListItemIcon>
                                    <ListItemText primary="Invoice" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/GenerateInvoice')} >
                                            <ListItemIcon>
                                                <FileCopy />
                                            </ListItemIcon>
                                            <ListItemText primary="Generate Invoice" />
                                        </ListItemButton>
                                    </>
                                }
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Quotation')} >
                                    <ListItemIcon>
                                        <Description />
                                    </ListItemIcon>
                                    <ListItemText primary="Quotation" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/GenerateQuotation')} >
                                            <ListItemIcon>
                                                <FileCopy />
                                            </ListItemIcon>
                                            <ListItemText primary="Generate Quotation" />
                                        </ListItemButton>
                                    </>
                                }

                            </List>
                        </Collapse>


                        {false && <ListItemButton style={{ color: PrimaryColor }} onClick={() => {
                            togglePage("")
                            navigate("/About")
                        }}>
                            <ListItemIcon style={{ color: 'inherit' }}>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary="About" />
                        </ListItemButton>}

                        {
                            ((isStaff && (TitleID === 8)) || (!isStaff && (TitleID === 2))) &&
                            <ListItemButton style={{ color: PrimaryColor }} onClick={() => togglePage("documents")}>
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Others" />
                                {page === "documents" ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }


                        <Collapse in={page === "documents"} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/Documents')} >
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="View Documents" />
                                </ListItemButton>
                                {
                                    superUser &&
                                    <>
                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/AddDocuments')} >
                                            <ListItemIcon>
                                                <AddLocationAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Add Documents" />
                                        </ListItemButton>
                                    </>
                                }

                            </List>
                        </Collapse>

                    </List>
                    <Divider />
                    <Container
                        maxWidth="xs"
                        style={{
                            marginTop: 'auto',
                            minWidth: 400,
                            backgroundColor: "transparent",
                            overflow: 'hidden'
                        }}
                    >
                        {
                            open &&
                            <Grid>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Grid container>
                                                <strong>Version - 20230531 v1</strong>
                                            </Grid>
                                            <Grid container direction='row'>
                                                <Grid style={{
                                                    marginTop: 'auto',
                                                    marginBottom: 'auto'
                                                }} item>
                                                    <div style={{ color: '#000' }}>
                                                        Powered by
                                                    </div>
                                                </Grid>
                                                <Grid style={{ paddingTop: '5px' }}>
                                                    <img src={Logo} alt="Konnect" width={"100px"} height={"40px"} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        }

                    </Container>
                </Drawer>
            </Box>
        </div>

    );
}
