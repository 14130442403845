// @ts-nocheck
import {
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Button
} from "@material-ui/core";
import axios from "axios";
import React, { useState, FormEvent, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FormLabel, Radio, RadioGroup, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserID, getDateTime, getStatusColor, inputFieldVariant } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

export const AddADCReport = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [sites, setSites] = useState<any>([]);
    const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());

    const { handleSubmit, control, watch } = useForm();


    const createReport = async (data) => {
        let temp = {
            ...data,
            Report_Month : selectedMonth,
            UpdateUserID: getCurrentUserID(),
            UpdateDateTime: getDateTime(),
        }
        console.log(temp)
        setLoading(true)
        axios
            .post(
                `${SERVER_IP}reportADC/save`,
                temp
            )
            .then(() => navigate("/ViewStatusReports",{
                state : "ADC Report Successfully Created"
            }))
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Couldn't Create Report");
            }).finally(() => setLoading(false))
    };

    const getSites = () => {
        axios
            .get(`${SERVER_IP}sitedetails`)
            .then((res) => {
                setSites(res.data);
            }).catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        getSites();
    }, []);

    // React.useEffect(() => {
    //     const subscription = watch((value, { name, type }) => console.log(value, name, type));
    //     return () => subscription.unsubscribe();
    // }, [watch]);

    const week1ADCValue = watch("ADC_W1", "0");
    const week2ADCValue = watch("ADC_W2", "0");
    const week3ADCValue = watch("ADC_W3", "0");
    const week4ADCValue = watch("ADC_W4", "0");
    const week5ADCValue = watch("ADC_W5", "0");


    return (

        <>
            <div style={{ marginTop: 80, display: 'flex' }}>
                <Sidebar />
                <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
                    <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
                        <form style={{ width: '100%' }} onSubmit={handleSubmit(createReport)}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                                        <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                                        {"Add ADC Report"}
                                    </Typography>
                                </Grid>

                                <Grid container spacing={3} style={{ margin: 'auto' }}>

                                    <Grid item xs={12} sm={4}>

                                        <Controller
                                            name="SiteID"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                                    <InputLabel>Site </InputLabel>
                                                    <Select
                                                        value={value}
                                                        label="Site"
                                                        onChange={onChange}
                                                    >

                                                        {sites.map((e) => (
                                                            <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                                                        ))}

                                                    </Select>
                                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="Remarks"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    label="Remarks"
                                                    variant={inputFieldVariant}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Month"
                                                views={['month', 'year']}
                                                // disableFuture
                                                // disabled
                                                value={selectedMonth}
                                                onChange={(newValue) => {
                                                    setSelectedMonth(newValue)
                                                }}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    name="selectedMonth"
                                                    fullWidth
                                                    {...params} />
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="ADC_W1"
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    style={{ backgroundColor: getStatusColor(week1ADCValue)}}
                                                    label="Week 1 ADC"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type='number'
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="HAP_W1"
                                            control={control}
                                            defaultValue="No"
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl>
                                                    <FormLabel>Week 1 HAP</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="ADC_W2"
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    style={{ backgroundColor: getStatusColor(week2ADCValue) }}
                                                    label="Week 2 ADC"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type='number'
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="HAP_W2"
                                            control={control}
                                            defaultValue="No"
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl>
                                                    <FormLabel>Week 2 HAP</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="ADC_W3"
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    style={{ backgroundColor: getStatusColor(week3ADCValue) }}
                                                    label="Week 3 ADC"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type='number'
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="HAP_W3"
                                            control={control}
                                            defaultValue="No"
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl>
                                                    <FormLabel>Week 3 HAP</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="ADC_W4"
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    style={{ backgroundColor: getStatusColor(week4ADCValue) }}
                                                    label="Week 4 ADC"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type='number'
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="HAP_W4"
                                            control={control}
                                            defaultValue="No"
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl>
                                                    <FormLabel>Week 4 HAP</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="ADC_W5"
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <TextField
                                                    style={{ backgroundColor: getStatusColor(week5ADCValue) }}
                                                    label="Week 5 ADC"
                                                    variant={'outlined'}
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    type='number'
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="HAP_W5"
                                            control={control}
                                            defaultValue="No"
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FormControl>
                                                    <FormLabel>Week 5 HAP</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            rules={{ required: 'This field is required' }}
                                        />

                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid container style={{ marginBottom: '10px' }}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ textAlign: "center" }}
                                >
                                    <Button
                                        disabled={loading}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                    >
                                        {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                        {"Create Report"}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ textAlign: "center" }}
                                >
                                    <Button
                                        color="secondary"
                                        size="small"
                                        variant="contained"
                                        onClick={(e) => navigate("/ViewStatusReports")}>
                                        Cancel
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>

                        <ToastContainer />
                    </Grid>
                </Grid>


            </div>
        </>

    );
};
