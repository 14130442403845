// @ts-nocheck
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AboutWidget } from "./Components/home/AboutWidget";
import { CreateContact } from "./Components/contacts/CreateContact";
import { UpdateContact } from "./Components/contacts/UpdateContact";
import { CreateSite } from "./Components/sites/CreateSite";
import { CreateStaff } from "./Components/staffs/CreateStaff";
import { UpdateStaff } from "./Components/staffs/UpdateStaff";
import { ContactPage } from "./Components/contacts/ContactPage";
import { HomeWidget } from "./Components/home/HomeWidget";
import { SitePage } from "./Components/sites/SitePage";
import { StaffPage } from "./Components/staffs/StaffPage";
import { LoginWidget } from "./Components/LoginWidget";
import { ResetWidget } from "./Components/ResetWidget";
import { ECSReportPage } from "./Components/reports/ECSReportPage";
import { ForgotPassword } from "./Components/ForgotPassword";
import { POPage } from "./Components/PO/POPage";
import { AddPO } from "./Components/PO/AddPO";
import { OperationSummary } from "./Components/PO/OperationSummary";
import { AssignWorker } from "./Components/PO/AssignWorker";
import { CertificateType } from "./Components/staffs/CertificateType";
import { CertificateBody } from "./Components/staffs/CertificateBody";
import { UpdateSite } from "./Components/sites/UpdateSite";
import { WorkOrderPage } from "./Components/workorder/WorkOrderPage";
import { CreateWorkOrder } from "./Components/workorder/CreateWorkOrder";
import { UpdateWorkOrder } from "./Components/workorder/UpdateWorkOrder";
import { UpdatePO } from "./Components/PO/UpdatePO";
import { ReportsByPOPage } from "./Components/reports/ReportsByPOPage";
import { MosquitoControlReport } from "./Components/reports/MosquitoControlReport";
import { TermiteControlReport } from "./Components/reports/TermiteControlReport";
import { MosquitoFoggingReport } from "./Components/reports/MosquitoFoggingReport";
import { CommonPestReport } from "./Components/reports/CommonPestReport";
import { AnimalReport } from "./Components/reports/AnimalReport";
import {useSelector} from 'react-redux'
import { SiteZone } from "./Components/sites/SiteZone";
import { SiteType } from "./Components/sites/SiteType";
import { CreateBulkWorkOrder } from "./Components/workorder/CreateBulkWorkOrder";
import { AddADCReport } from "./Components/reports/AddADCReport";
import { AddDSSDReport } from "./Components/reports/AddDSSDReport";
import { AddSDPCReport } from "./Components/reports/AddSDPCReport";
import { ViewStatusReports } from "./Components/reports/ViewStatusReports";
import { UpdateADCReport } from "./Components/reports/UpdateADCReport";
import { UpdateDSSDReport } from "./Components/reports/UpdateDSSDReport";
import { UpdateSDPCReport } from "./Components/reports/UpdateSDPCReport";
import { CompanyPage } from "./Components/contacts/CompanyPage";
import { CreateCompany } from "./Components/contacts/CreateCompany";
import { UpdateCompany } from "./Components/contacts/UpdateCompany";
import { isDeveloper, isLoggedUserStaff, isProgClaimEligible, isStatusReportEligible, isSuperUser } from "./utils";
import { UpdateECSReport } from "./Components/reports/UpdateECSReport";
import { ManualReportsPage } from "./Components/reports/ManualReportsPage";
import { AddManualReport } from "./Components/reports/AddManualReport";
import { AddProgressiveClaim } from "./Components/reports/AddProgressiveClaim";
import { ProgressiveClaimPage } from "./Components/reports/ProgressiveClaimPage";
import { ECSSummaryReport } from "./Components/reports/ECSSummaryReport";
import { UpdateManualReport } from "./Components/reports/UpdateManualReport";
import { UpdateProgressiveClaim } from "./Components/reports/UpdateProgressiveClaim";
import { ReportNotification } from "./Components/reports/ReportNotification";
import { InvoicePage } from "./Components/sales/InvoicePage";
import { CreateInvoice } from "./Components/sales/CreateInvoice";
import { UpdateInvoice } from "./Components/sales/UpdateInvoice";
import CalenderMgmt from "./Components/workorder/CalenderMgmt";
import { QuotationPage } from "./Components/sales/QuotationPage";
import { CreateQuotation } from "./Components/sales/CreateQuotation";
import { UpdateQuotation } from "./Components/sales/UpdateQuotation";
import { DocPage } from "./Components/others/DocPage";
import { UpdateDoc } from "./Components/others/UpdateDoc";
import { AddDoc } from "./Components/others/CreateDoc";

const RouterWidget = () => {

  const isStaff = useSelector(state => state.userRedux.isStaff)
  const accessControlID = useSelector(state => state.userRedux.accessControlID)
  const superUser = isSuperUser();
  const developer = isDeveloper();

  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            <Route index element={<LoginWidget />} />
            <Route path='/Home' element={<HomeWidget />} />
            <Route path="/Forgot-Password" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetWidget />} />
            <Route path="/About" element={<AboutWidget />} />
            <Route path="/Site" element={<SitePage />} />
            <Route path="/ECSReports" element={<ECSReportPage />} />
            <Route path="/ReportsByPO" element={<ReportsByPOPage />} />
            <Route path="/MosquitoControlReport" element={<MosquitoControlReport />} />
            <Route path="/TermiteControlReport" element={<TermiteControlReport />} />
            <Route path="/CommonPestReport" element={<CommonPestReport />} />
            <Route path="/MosquitoFoggingReport" element={<MosquitoFoggingReport />} />
            <Route path="/AnimalReport" element={<AnimalReport />} />
            <Route path="/ManualReports" element={<ManualReportsPage /> } />
            <Route path="/UpdateManualReport" element={<UpdateManualReport />} />


            <Route path="/Contact" element={isStaff ? <ContactPage /> : <Navigate to={"/Home"} />} />
            <Route path="/Company" element={isStaff ? <CompanyPage /> : <Navigate to={"/Home"} />} />
            <Route path="/Staff" element={isStaff ? <StaffPage /> : <Navigate to={"/Home"} />} />
            <Route path="/WorkOrder" element={isStaff ? <WorkOrderPage /> : <Navigate to={"/Home"} />} />
            <Route path="/POSummary" element={isStaff ? <POPage /> : <Navigate to={"/Home"} />} />
            <Route path="/Operation_Summary" element={isStaff ? <OperationSummary /> : <Navigate to={"/Home"} />} />


            <Route path="/ViewStatusReports" element={isStatusReportEligible() ? <ViewStatusReports /> : <Navigate to={"/Home"} />} />
            <Route path="/AddManualReport" element={superUser ? <AddManualReport /> : <Navigate to={"/Home"} />} />
            <Route path="/AddProgressiveClaim" element={<AddProgressiveClaim />} />
            <Route path="/ECSSummaryReport" element={isLoggedUserStaff() && (accessControlID === 1 || accessControlID === 9) ? <ECSSummaryReport /> : <Navigate to={"/Home"} />} />
            <Route path="/ProgressiveClaim" element={<ProgressiveClaimPage /> } />
            <Route path="/UpdateProgressiveClaim" element={<UpdateProgressiveClaim /> } />
            <Route path="/UpdateECSReport" element={developer ? <UpdateECSReport /> : <Navigate to={"/Home"} />} />
            <Route path="/Assign_Worker" element={superUser ? <AssignWorker /> : <Navigate to={"/Home"} />} />
            <Route path="/AddPO" element={superUser ? <AddPO /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdatePO" element={superUser ? <UpdatePO /> : <Navigate to={"/Home"} />} />
            <Route path="/SiteZone" element={superUser ? <SiteZone /> : <Navigate to={"/Home"} />} />
            <Route path="/SiteType" element={superUser ? <SiteType /> : <Navigate to={"/Home"} />} />
            <Route path="/Certificate_Type" element={superUser ? <CertificateType /> : <Navigate to={"/Home"} />} />
            <Route path="/Certificate_Body" element={superUser ? <CertificateBody /> : <Navigate to={"/Home"} />} />
            <Route path="/CreateContact" element={superUser ? <CreateContact /> : <Navigate to={"/Home"} />} />
            <Route path="/AddCompany" element={superUser ? <CreateCompany /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateContact" element={superUser ? <UpdateContact /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateCompany" element={superUser ? <UpdateCompany /> : <Navigate to={"/Home"} />} />
            <Route path="/CreateWorkOrder" element={superUser ? <CreateWorkOrder /> : <Navigate to={"/Home"} />} />
            <Route path="/CreateBulkWorkOrder" element={superUser ? <CreateBulkWorkOrder /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateWorkOrder" element={superUser ? <UpdateWorkOrder /> : <Navigate to={"/Home"} />} />
            <Route path="/CreateStaff" element={superUser ? <CreateStaff /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateStaff" element={superUser ? <UpdateStaff /> : <Navigate to={"/Home"} />} />
            <Route path="/AddADCReport" element={superUser ? <AddADCReport /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateADCReport" element={superUser ? <UpdateADCReport /> : <Navigate to={"/Home"} />} />
            <Route path="/AddDSSDReport" element={superUser ? <AddDSSDReport /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateDSSDReport" element={superUser ? <UpdateDSSDReport /> : <Navigate to={"/Home"} />} />
            <Route path="/AddSDPCReport" element={superUser ? <AddSDPCReport /> : <Navigate to={"/Home"} />} />
            <Route path="/ReportNotification" element={superUser ? <ReportNotification /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateSDPCReport" element={superUser ? <UpdateSDPCReport /> : <Navigate to={"/Home"} />} />
            <Route path="/CreateSite" element={superUser ? <CreateSite /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateSite" element={superUser ? <UpdateSite /> : <Navigate to={"/Home"} />} />
            <Route path="/Invoice" element={superUser ? <InvoicePage /> : <Navigate to={"/Home"} />} />
            <Route path="/GenerateInvoice" element={superUser ? <CreateInvoice /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateInvoice" element={superUser ? <UpdateInvoice /> : <Navigate to={"/Home"} />} />
            <Route path="/Quotation" element={superUser ? <QuotationPage /> : <Navigate to={"/Home"} />} />
            <Route path="/GenerateQuotation" element={superUser ? <CreateQuotation /> : <Navigate to={"/Home"} />} />
            <Route path="/UpdateQuotation" element={superUser ? <UpdateQuotation /> : <Navigate to={"/Home"} />} />
            <Route path="/CalenderMgmt" element={superUser ? <CalenderMgmt /> : <Navigate to={"/Home"} />} />

            <Route path='/Documents' element={<DocPage />} />
            <Route path='/AddDocuments' element={<AddDoc />} />
            <Route path='/UpdateDocuments' element={<UpdateDoc />} />

          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
};

export default RouterWidget;
