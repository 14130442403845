// @ts-nocheck
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TextField,
  Typography,
  TableRow,
  TableHead,
  TableBody,
  Button,
  makeStyles,
  Theme
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { Sidebar } from "../Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { inputFieldVariant, base64Converter, getCurrentUserID, getDateTime, isValidUrl, acceptedFiles, checkIfFileIsAccepted, checkIfFileIsPDF, dateFormat } from "../../utils";
import moment from "moment";
import { useForm, Controller } from 'react-hook-form';
import {
  FormHelperText,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import pdfIcon from '../../assert/pdf_icon.png'
import ImageViewer from "../../assert/ImageViewer";
import TablePlaceholder from "../TablePlaceholder";
import logo from '../../assert/logo_index.jpeg'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const useStyles = makeStyles((theme: Theme) => ({
  dropDown: {
    width: "100%",
    height: 33,
    borderRadius: 4,
    backGround: theme.palette.background.paper,
    boxSizing: "border-box",
    fontSize: 14,
  },
  dateInput: {
    fontSize: 14,
    width: "100%",
    padding: '0',
    '& .MuiInputBase-formControl': {
      height: 33
    }
  },
  table: {
    borderRadius: 8,
    width: "100%",
    fontWeight: "normal",
    "& .MuiTableCell-root": {
      border: "1px solid #ddd",
      textAlign: "center",
      padding: "0px",
    },
    "& .MuiTableCell-head": {
      // background: theme.palette.info.dark,
      padding: "5px 0",
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      color: "gray",
      fontWeight: "normal",
      padding: "5px 0",
    },
  },
}));


let certificateDummyData = {
  CertTypeID: "",
  CertBodyID: "",
  ValidityStartDate: "",
  ValidityEndDate: "",
  CertificateImageURL: "",
  base64Data: "",
  CertFileName: ""
}


export const UpdateStaff = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false)
  const [payslipDataFetched, setPayslipDataFetched] = useState(false)
  const [payslipDataAvailable, setPayslipDataAvailable] = useState(false)

  const [genders, setGenders] = useState<any>([]);
  const [staffTypes, setStaffTypes] = useState<any>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<any>([]);
  const [salutations, setSalutations] = useState<any>([]);
  const [staffTitles, setStaffTitles] = useState<any>([]);
  const [accessControls, setAccessControls] = useState<any>([]);
  const [idTypes, setIdTypes] = useState<any>([]);
  const [relationships, setRelationships] = useState<any>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<any>([]);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [showImagePreview, setShowImagePreview] = useState<Boolean>(false);
  const [certificateBodys, setCertificateBodys] = useState<Array<any>>([]);
  const [certificateTypes, setCertificateTypes] = useState<Array<any>>([]);

  const [staff, setStaff] = useState(state?.data || {});

  const { handleSubmit, control, watch, setValue } = useForm({ defaultValues: staff });

  const [certificateDetails, setCertificateDetails] = useState([
    { ...certificateDummyData, id: 0 },
  ]);
  const [tableKey, setTableKey] = useState(0);

  const handleTableInput = async (e, index) => {
    let { name, value } = e.target;
    let certificate = certificateDetails;

    if (name === "CertificateImageURL" && !!e.target?.files?.length) {
      if (checkIfFileIsPDF(e.target.files[0])) {
        value = e.target.files[0]
        let d = await base64Converter(!!e.target?.files?.length ? e.target.files[0] : e.target.files)
        certificate[index] = {
          ...certificate[index],
          CertFileName: e.target.files[0].name,
          base64Data: d,
        };
      }
      else {
        toast.error("Upload a PDF File")
        return
      }
    }

    certificate[index] = {
      ...certificate[index],
      [name]: value,
    };
    setCertificateDetails([...certificate]);
  };

  const checkTableInputRow = (id) => {
    let certificate = certificateDetails.filter((certificate) => certificate.id === id)[0];
    return (
      !!certificate.CertTypeID &&
      !!certificate.CertBodyID &&
      !!certificate.ValidityStartDate &&
      !!certificate.ValidityEndDate &&
      !!certificate.CertificateImageURL
    );
  };


  const fetchStaffCertificates = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}staffcertificate?StaffID=${staff.StaffID}`)
      .then((res) => {
        let certs = res.data.map((data, index) => {
          return {
            ...certificateDummyData,
            id: index,
            ...data
          }
        })
        setCertificateDetails([...certs])
      }).catch((res) => {
        console.log(res)
      }).finally(() => setLoading(false))
  };

  const fetchStaffPayslip = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}PaySlip/getByStaff?StaffID=${staff.StaffID}`)
      .then((res) => {
        if (!!res.data.length) {
          setStaff({ ...staff, ...res.data[0] })
          setPayslipDataAvailable(true)
        }

      }).catch((res) => {
        console.log(res)
      }).finally(() => {
        setLoading(false)
        setPayslipDataFetched(true)
      })
  };

  const updateStaff = async (data) => {

    let CertificateDetails = certificateDetails.filter(c => checkTableInputRow(c.id))

    CertificateDetails = CertificateDetails.map(certificate => {
      certificate["AddedByUserID"] = getCurrentUserID();
      certificate["AddedDateTime"] = getDateTime();
      certificate["CertificateImageURL"] = certificate["base64Data"] || certificate["CertificateImageURL"]
      delete certificate["id"]
      delete certificate["base64Data"]
      return certificate
    })


    let temp = {
      ...staff,
      ...data,
      StaffImageURL: !!uploadedFile ? await base64Converter(uploadedFile) : staff.StaffImageURL,
      AddedByUserID: getCurrentUserID(),
      AddedDateTime: getDateTime(),
      PasswordHash: "password",
      CertificateDetails: CertificateDetails
    }
    delete temp["AccessControl"]
    delete temp["EmplopymentStatus"]
    delete temp["StaffTitle"]
    delete temp["EmploymentStatus"]

    !!temp.StaffImageURL && isValidUrl(temp.StaffImageURL) && delete temp["StaffImageURL"]
    console.log(temp)
    setLoading(true)

    let payslipFields = [
      "FromDate",
      "ToDate",
      "BasicPay",
      "TotalAllowance",
      "TotalAllowanceDesc",
      "TotalDeduction",
      "TotalDeductionDesc",
      "DateOfPayment",
      "ModeOfPayment",
      "OverTimePeriod",
      "OverTimeHours",
      "OverTimePay",
      "OtherAdditionalPayments",
      "OtherAdditionalPaymentsDesc",
      "NetPay",
      "EmployerCPFContr",
    ]

    let payslipData = {}

    payslipFields.map(d => payslipData[d] = temp[d])
    payslipFields.map(d => delete temp[d])
    console.log(payslipData)
    console.log(temp)

    axios
      .put(`${SERVER_IP}PaySlip/save`, {
        ...payslipData,
        StaffID: temp["StaffID"],
        EmployerName: "Pest Pro",
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
      })
      .then((res) => {
        payslipFields.map(d => delete temp[d])
        delete temp['EmployerName']
        delete temp['PaySlipID']

        axios
          .put(`${SERVER_IP}staff?StaffID=${temp.StaffID}`, temp)
          .then((res) => {
            toast.success("Staff Successfully Updated");
            console.log(res, "res");
            setTimeout(() => {
              navigate("/Staff", {
                state: state
              });
            },2000)

          })
          .catch((res) => {
            console.log(res);
            toast.error("Couldn't Update Staff");
          }).finally(() => setLoading(false))
      })
      .catch((res) => {
        console.log(res);
        toast.error("Couldn't Update Staff");
      }).finally(() => setLoading(false))

  };


  const getCertificateTypes = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}certificatetype`)
      .then((res) => {

        setCertificateTypes(res.data);
      }).catch((res) => {
        console.log(res)
      }).finally(() => setLoading(false))
  };

  const getCertificateBodys = () => {
    setLoading(true)
    axios
      .get(`${SERVER_IP}certificatebody`)
      .then((res) => {

        setCertificateBodys(res.data);
      }).catch((res) => {
        console.log(res)
      }).finally(() => setLoading(false))
  };

  const getGenders = () => {
    axios
      .get(`${SERVER_IP}Gender`)
      .then((res) => {

        setGenders(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getIdTypes = () => {
    axios
      .get(`${SERVER_IP}idtype`)
      .then((res) => {

        setIdTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const getEmploymentStatuses = () => {
    axios
      .get(`${SERVER_IP}staffemploymentstatus`)
      .then((res) => {

        setEmploymentStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const getRelationships = () => {
    axios
      .get(`${SERVER_IP}relationship`)
      .then((res) => {

        setRelationships(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getMaritalStatuses = () => {
    axios
      .get(`${SERVER_IP}Maritalstatus`)
      .then((res) => {

        setMaritalStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const getStaffTypes = () => {
    axios
      .get(`${SERVER_IP}staffEmploymentType`)
      .then((res) => {

        setStaffTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getEmploymentTitle = () => {
    axios
      .get(`${SERVER_IP}staffTitle`)
      .then((res) => {

        setStaffTitles(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSalutations = () => {
    axios
      .get(`${SERVER_IP}saluation`)
      .then((res) => {

        setSalutations(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getAccessControls = () => {
    axios
      .get(`${SERVER_IP}AccessControl`)
      .then((res) => {

        setAccessControls(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  useEffect(() => {
    fetchStaffPayslip();
    fetchStaffCertificates();
    getGenders();
    getStaffTypes();
    getEmploymentStatuses()
    getSalutations();
    getEmploymentTitle();
    getAccessControls();
    getIdTypes();
    getRelationships();
    getMaritalStatuses();
    getCertificateBodys();
    getCertificateTypes();
    // document.getElementById("ppp")?.click()
  }, []);


  const NetValue = (parseInt(watch("BasicPay", 0)) || 0) +
    (parseInt(watch("TotalAllowance", 0)) || 0) -
    (parseInt(watch("TotalDeduction", 0)) || 0) +
    (parseInt(watch("TotalOvertimePay", 0)) || 0) +
    (parseInt(watch("OtherAdditionalPayments", 0)) || 0)
  setValue('NetPay', NetValue)

  const exportData = () => {

    setLoading(true)

    const docRefNo = `${staff.StaffName}'s Payslip`

    const doc = new jsPDF("portrait")

    function addFooters() {
      const pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.addImage(logo, 'jpeg', 10, 5, 60, 10)
        doc.setFontSize(15).setFont(undefined, 'bold').text(docRefNo.toUpperCase(), width - 10, 10, { align: 'right' });
        // doc.setFontSize(8).setFont(undefined, 'normal').text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
        doc.setFontSize(8).setFont(undefined, 'normal').text(`Page ${i} of ${pageCount}`, width - 20, 15, { align: 'center' });
      }
    }

    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()

    let tableData = [
      {
        description: "Name Of Employer",
        value: "Pest-Pro Management Pte Ltd"
      },
      {
        description: "Name Of Employee",
        value: staff.StaffName
      },
      {
        description: "Basic Pay (A)",
        value: staff.BasicPay
      },
      {
        description: "Total Allowances (B)",
        value: staff.TotalAllowance
      },
      {
        description: "Total Allowance Breakdown",
        value: staff.TotalAllowanceDesc
      },
      {
        description: "Total Deduction (B)",
        value: staff.TotalDeduction
      },
      {
        description: "Total Deduction Breakdown",
        value: staff.TotalDeductionDesc
      },
      {
        description: "Date Of Payment",
        value: staff.DateOfPayment
      },
      {
        description: "Mode Of Payment",
        value: staff.ModeOfPayment
      },
      {
        description: "Overtime Payment Periods",
        value: staff.OverTimePeriod
      },
      {
        description: "Overtime Hours Worked",
        value: staff.OverTimeHours
      },
      {
        description: "Total Overtime Pay (D)",
        value: staff.OverTimePay
      },
      {
        description: "Other Additional Payments (E)",
        value: staff.OtherAdditionalPayments
      },
      {
        description: "Other Additional Breakdown",
        value: staff.OtherAdditionalPaymentsDesc
      },
      {
        description: "Net Pay (A+B–C+D+E)",
        value: staff.NetPay
      },
      {
        description: "Employer’s CPF Contributions",
        value: staff.EmployerCPFContr
      },

    ]


    // let finalY = 25;

    doc.setFontSize(12)
      .setFont(undefined, 'bold')
      .text(`Payslip for ${dateFormat(staff.FromDate)} to ${dateFormat(staff.ToDate)}`, (width / 2) - 40, 25);


    const columns = [
      { title: "Description", field: "description", },
      { title: "Value", field: "value", },
    ]


    doc.autoTable({
      theme: "grid",
      showHead: 'never',
      startY: 35,
      startX: 35,
      breakAtY: width - 10,
      bodyStyles: { lineColor: [0, 0, 0] },
      styles: {
        halign: 'left',
        border: '2px solid',
        valign: 'middle'
      },
      headStyles: {
        halign: 'center',
        fillColor: [241, 90, 41],
        lineColor: [0, 0, 0],
        border: '2px solid'
      },
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 110, halign: 'center' },
      },
      body: tableData,
      didParseCell: (hookData) => {

        if (hookData.column.dataKey === 'description') {
          hookData.cell.styles.halign = 'left';
          hookData.cell.styles.fontStyle = 'bold';
        }
        if (hookData.column.dataKey === 'value') {
          hookData.cell.styles.halign = 'left';
        }

      }

    })

    let finalY = doc.lastAutoTable.finalY + 15;


    doc.text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
    addFooters()
    doc.save(`${docRefNo}.pdf`)

    setLoading(false)
  }

  return (
    <>
      <div style={{ marginTop: 80, display: "flex" }}>
        <Sidebar />
        <div style={{ width: '100%' }}>
          {
            payslipDataFetched ?
              <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>

                <form onSubmit={handleSubmit(updateStaff)}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      margin: "10px auto",
                      borderRadius: "10px",
                      border: `2px solid ${PrimaryColor}`
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          style={{ backgroundColor: PrimaryColor, color: "white" }}
                          variant="h6"
                          gutterBottom
                        >
                          <AccountCircleIcon style={{ margin: "auto 5px" }} />
                          {"Update Staff"}
                        </Typography>
                      </Grid>
                      <Grid container spacing={3} style={{ margin: "auto" }}>

                        <Grid item xs={12} sm={4}>

                          <Controller
                            name="SalutationID"
                            control={control}
                            defaultValue={staff.SalutationID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Salutation</InputLabel>
                                <Select
                                  id="Salutation"
                                  value={value}
                                  label="Salutation"
                                  onChange={onChange}
                                >

                                  {salutations.map((e) => (
                                    <MenuItem key={e.SalutationID} value={e.SalutationID}>{e.Salutation}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />

                        </Grid>
                        <Grid item xs={12} sm={4}>

                          <Controller
                            name="StaffName"
                            control={control}
                            defaultValue={staff.StaffName}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Staff Name"
                                fullWidth
                                variant={inputFieldVariant}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>

                          <Controller
                            name="GenderID"
                            control={control}
                            defaultValue={staff.GenderID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>Gender</InputLabel>
                                <Select
                                  id="GenderID"
                                  value={value}
                                  label="Gender"
                                  onChange={onChange}
                                >

                                  {genders.map((e) => (
                                    <MenuItem key={e.GenderID} value={e.GenderID}>{e.Gender}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Mobile"
                            control={control}
                            defaultValue={staff.Mobile}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Mobile Number"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Address1"
                            control={control}
                            defaultValue={staff.Address1}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Address 1"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Telephone"
                            control={control}
                            defaultValue={staff.Telephone}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Telephone"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Address2"
                            control={control}
                            defaultValue={staff.Address2}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Address 2"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue={staff.Email}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Email"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="PostCode"
                            control={control}
                            defaultValue={staff.PostCode}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Post Code"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Nationality"
                            control={control}
                            defaultValue={staff.Nationality}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Nationality"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="Passport"
                            control={control}
                            defaultValue={staff.Passport}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Passport Number"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>

                          <Controller
                            name="IDTypeID"
                            control={control}
                            defaultValue={staff.IDTypeID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                <InputLabel>ID Type</InputLabel>
                                <Select
                                  value={value}
                                  label="ID Type"
                                  onChange={onChange}
                                >

                                  {idTypes.map((e) => (
                                    <MenuItem key={e.IDTypeID} value={e.IDTypeID}>{e.IDType}</MenuItem>
                                  ))}

                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                            rules={{ required: 'This field is required' }}
                          />

                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="ID"
                            control={control}
                            defaultValue={staff.ID}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="ID"
                                variant={inputFieldVariant}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            rules={{ required: 'This field is required' }}
                          />
                        </Grid>

                      </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ margin: "auto" }}>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="NextOfKin"
                          control={control}
                          defaultValue={staff.NextOfKin}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Next of Kin Name"
                              variant={inputFieldVariant}
                              value={value}
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                          rules={{ required: 'This field is required' }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>

                        <Controller
                          name="RelationshipID"
                          control={control}
                          defaultValue={staff.RelationshipID}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                              <InputLabel>Next of Kin Relationship</InputLabel>
                              <Select
                                value={value}
                                label="Next of Kin Relationship"
                                onChange={onChange}
                              >

                                {relationships.map((e) => (
                                  <MenuItem key={e.RelationshipID} value={e.RelationshipID}>{e.Relationship}</MenuItem>
                                ))}

                              </Select>
                              {error && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                          )}
                          rules={{ required: 'This field is required' }}
                        />

                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="NextOfKinMobile"
                          control={control}
                          defaultValue={staff.NextOfKinMobile}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Next of Kin Mobile"
                              variant={inputFieldVariant}
                              value={value}
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                          rules={{ required: 'This field is required' }}
                        />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{
                      margin: "10px auto",
                      borderRadius: "10px",
                      border: `2px solid ${PrimaryColor}`
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          style={{ backgroundColor: PrimaryColor, color: "white" }}
                          variant="h6"
                        >
                          <AccountCircleIcon style={{ margin: "auto 5px" }} />
                          Personal Particulars
                        </Typography>
                      </Grid>
                      <Grid container spacing={3} style={{ margin: "auto" }}>
                        <Grid container spacing={3} style={{ margin: "auto" }}>

                          <Grid item xs={12} sm={6}>
                            <Controller
                              name="DOB"
                              control={control}
                              defaultValue={staff.DOB}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="Date Of Birth"
                                    inputFormat="dd/MM/yyyy"
                                    // disableFuture
                                    value={value || null}
                                    onChange={onChange}
                                    renderInput={(params) => <TextField
                                      variant={inputFieldVariant}
                                      fullWidth
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      {...params} />
                                    }
                                  />
                                </LocalizationProvider>

                              )}
                            // rules={{ required: 'This field is required' }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>

                            <Controller
                              name="MartialStatusID"
                              control={control}
                              defaultValue={staff.MartialStatusID}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                  <InputLabel>Marital Status</InputLabel>
                                  <Select
                                    value={value}
                                    label="Marital Status"
                                    onChange={onChange}
                                  >

                                    {maritalStatuses.map((e) => (
                                      <MenuItem key={e.MaritalStatusID} value={e.MaritalStatusID}>{e.MaritalStatus}</MenuItem>
                                    ))}

                                  </Select>
                                  {error && <FormHelperText>{error.message}</FormHelperText>}
                                </FormControl>
                              )}
                              rules={{ required: 'This field is required' }}
                            />

                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Controller
                              name="Religion"
                              control={control}
                              defaultValue={staff.Religion}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Religion"
                                  variant={inputFieldVariant}
                                  value={value}
                                  fullWidth
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                              rules={{ required: 'This field is required' }}
                            />
                          </Grid>



                          <Grid item xs={12}>
                            <Typography>Staff Photo (Images Only)</Typography>
                            <input
                              type="file"
                              accept={acceptedFiles}
                              name="StaffImageURL"
                              onChange={async (e) => {
                                if (checkIfFileIsAccepted(e.target.files[0])) {
                                  setStaff({
                                    ...staff,
                                    StaffImageFileName: e.target.files[0].name
                                  })
                                  setUploadedFile(e.target.files[0])
                                }
                                else {
                                  toast.error("Invalid File Type")
                                  setUploadedFile(null)
                                  e.target.value = null
                                }
                              }}
                            />
                          </Grid>

                          {
                            !!uploadedFile && uploadedFile?.type?.includes("image") &&
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={URL.createObjectURL(uploadedFile)}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(URL.createObjectURL(uploadedFile))
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {staff.StaffImageFileName || "Not Available"}</strong>
                              </div>
                            </Grid>
                          }

                          {
                            !!uploadedFile && uploadedFile?.type?.includes("pdf") &&
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(uploadedFile))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {staff.StaffImageFileName || "Not Available"}</strong>
                              </div>
                            </Grid>
                          }

                          {
                            !!!uploadedFile && staff?.StaffImageURL?.includes("jpg") &&
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={staff.StaffImageURL}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(staff.StaffImageURL)
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {staff.StaffImageFileName || "Not Available"}</strong>
                              </div>
                            </Grid>
                          }

                          {
                            !!!uploadedFile && staff?.StaffImageURL?.includes("pdf") &&
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(staff.StaffImageURL)
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {staff.StaffImageFileName || "Not Available"}</strong>
                              </div>
                            </Grid>
                          }

                        </Grid>
                      </Grid>
                    </Grid>


                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{
                      margin: "10px auto",
                      borderRadius: "10px",
                      border: `2px solid ${PrimaryColor}`
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          style={{ backgroundColor: PrimaryColor, color: "white" }}
                          variant="h6"
                        >
                          <AccountCircleIcon style={{ margin: "auto 5px" }} />
                          Employment
                        </Typography>
                      </Grid>
                      <Grid container spacing={3} style={{ margin: "auto" }}>
                        <Grid item container spacing={1} direction="row">
                          <Grid container spacing={3} style={{ margin: "auto" }}>

                            <Grid item xs={12} sm={4}>

                              <Controller
                                name="StaffTitleID"
                                control={control}
                                defaultValue={staff.StaffTitleID}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                    <InputLabel>Staff Title</InputLabel>
                                    <Select
                                      value={value}
                                      label="Staff Title"
                                      onChange={onChange}
                                    >
                                      {staffTitles.map((e) => (
                                        <MenuItem key={e.StaffTitleID} value={e.StaffTitleID}>{e.StaffTitle}</MenuItem>
                                      ))}

                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                                )}
                                rules={{ required: 'This field is required' }}
                              />

                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="AccessControlID"
                                control={control}
                                defaultValue={staff.AccessControlID}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                    <InputLabel>Access Control</InputLabel>
                                    <Select
                                      value={value}
                                      label="Access Control"
                                      onChange={onChange}
                                    >
                                      {accessControls.map((e) => (
                                        <MenuItem key={e.AccessControlID} value={e.AccessControlID}>{e.AccessControl}</MenuItem>
                                      ))}

                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="StaffEmploymentType"
                                control={control}
                                defaultValue={staff.StaffEmploymentType}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                    <InputLabel>Employment Type</InputLabel>
                                    <Select
                                      value={value}
                                      label="Employment Type"
                                      onChange={onChange}
                                    >
                                      {staffTypes.map((e) => (
                                        <MenuItem key={e.StaffEmploymentTypeID} value={e.StaffEmploymentTypeID}>{e.StaffEmploymentType}</MenuItem>
                                      ))}

                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="EntitledLeave"
                                control={control}
                                defaultValue={staff.EntitledLeave}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Entitled Leave"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="UsedLeave"
                                control={control}
                                defaultValue={staff.UsedLeave}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Used Leave"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="BalanceLeave"
                                control={control}
                                defaultValue={staff.BalanceLeave}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Balance Leave"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="StaffEmploymentStatusID"
                                control={control}
                                defaultValue={staff.StaffEmploymentStatusID}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                    <InputLabel>Employment Status</InputLabel>
                                    <Select
                                      value={value}
                                      label="Employment Status"
                                      onChange={onChange}
                                    >
                                      {employmentStatuses.map((e) => (
                                        <MenuItem key={e.StaffEmploymentStatusID} value={e.StaffEmploymentStatusID}>{e.StaffEmploymentStatus}</MenuItem>
                                      ))}

                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="JobStartDate"
                                control={control}
                                defaultValue={staff.JobStartDate}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      label="Joined Date"
                                      inputFormat="dd/MM/yyyy"
                                      value={value || null}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      renderInput={(params) => <TextField
                                        variant={inputFieldVariant}
                                        fullWidth
                                        {...params} />
                                      }
                                    />
                                  </LocalizationProvider>

                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="JobEndDate"
                                control={control}
                                defaultValue={staff.JobEndDate}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      label="Job End Date"
                                      inputFormat="dd/MM/yyyy"
                                      // disableFuture
                                      value={value || null}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      renderInput={(params) => <TextField
                                        variant={inputFieldVariant}
                                        fullWidth
                                        {...params} />
                                      }
                                    />
                                  </LocalizationProvider>

                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>


                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="Department"
                                control={control}
                                defaultValue={staff.Department}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Department"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>


                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{
                      margin: "10px auto",
                      borderRadius: "10px",
                      border: `2px solid ${PrimaryColor}`
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>

                        <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                          <Grid container direction="row">
                            <Grid item>
                              <AccountCircleIcon style={{ margin: "auto 5px" }} />
                              Generate Payslip
                            </Grid>
                            <Grid item style={{ marginLeft: 'auto' }}>
                              <Button color="secondary"
                                style={{ marginRight: '10px' }}
                                size="small"
                                id='ppp'
                                variant="contained"
                                disabled={!payslipDataAvailable}
                                onClick={() => exportData()}
                              >
                                Download
                              </Button>
                            </Grid>
                          </Grid>


                        </Typography>
                      </Grid>

                      <Grid container spacing={3} style={{ margin: "auto" }}>
                        <Grid item container spacing={1} direction="row">
                          <Grid container spacing={3} style={{ margin: "auto" }}>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="ModeOfPayment"
                                control={control}
                                defaultValue={staff.ModeOfPayment}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                                    <InputLabel>Mode Of Pay</InputLabel>
                                    <Select
                                      value={value}
                                      label="Mode Of Pay"
                                      onChange={onChange}
                                    >
                                      {["Cash", "Cheque", "Bank Deposit"].map((e) => (
                                        <MenuItem key={e} value={e}>{e}</MenuItem>
                                      ))}

                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="BasicPay"
                                control={control}
                                defaultValue={staff.BasicPay}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Basic Pay (A)"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    type={"number"}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="OverTimePay"
                                control={control}
                                defaultValue={staff.OverTimePay}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Total Overtime Pay (D)"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    type={"number"}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="TotalAllowance"
                                control={control}
                                defaultValue={staff.TotalAllowance}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Total Allowance (B)"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    type={"number"}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="TotalAllowanceDesc"
                                control={control}
                                defaultValue={staff.TotalAllowanceDesc}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    placeholder="Total Allowances Breakdown"
                                    minRows={3}
                                    style={{ width: '100%' }}
                                    variant={"outlined"}
                                    value={value}
                                    
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="TotalDeduction"
                                control={control}
                                defaultValue={staff.TotalDeduction}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Total Deductions (C)"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    type={"number"}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="TotalDeductionDesc"
                                control={control}
                                defaultValue={staff.TotalDeductionDesc}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    placeholder="Total Deductions Breakdown"
                                    minRows={3}
                                    style={{ width: '100%' }}
                                    variant={"outlined"}
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>



                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="OtherAdditionalPayments"
                                control={control}
                                defaultValue={staff.OtherAdditionalPayments}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Other Additional Payments (E)"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    type={"number"}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="OtherAdditionalPaymentsDesc"
                                control={control}
                                defaultValue={staff.OtherAdditionalPaymentsDesc}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    placeholder="Other Additional Payments Breakdown"
                                    minRows={3}
                                    style={{ width: '100%' }}
                                    variant={"outlined"}
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="OverTimePeriod"
                                control={control}
                                defaultValue={staff.OverTimePeriod}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Overtime Payment Period"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="OverTimeHours"
                                control={control}
                                defaultValue={staff.OverTimeHours}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Overtime Hours Worked"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>


                            <Grid item xs={12} sm={4}>
                              <Controller
                                name="EmployerCPFContr"
                                control={control}
                                defaultValue={staff.EmployerCPFContr}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Employer’s CPF Contributions"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Controller
                                name="FromDate"
                                control={control}
                                defaultValue={staff.FromDate}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      label="From"
                                      inputFormat="dd/MM/yyyy"
                                      // disableFuture
                                      value={value || null}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      renderInput={(params) => <TextField
                                        variant={inputFieldVariant}
                                        fullWidth
                                        {...params} />
                                      }
                                    />
                                  </LocalizationProvider>

                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Controller
                                name="ToDate"
                                control={control}
                                defaultValue={staff.ToDate}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      label="To"
                                      inputFormat="dd/MM/yyyy"
                                      // disableFuture
                                      value={value || null}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      renderInput={(params) => <TextField
                                        variant={inputFieldVariant}
                                        fullWidth
                                        {...params} />
                                      }
                                    />
                                  </LocalizationProvider>

                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Controller
                                name="DateOfPayment"
                                control={control}
                                defaultValue={staff.DateOfPayment}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      label="Date of Payment"
                                      inputFormat="dd/MM/yyyy"
                                      // disableFuture
                                      value={value || null}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      renderInput={(params) => <TextField
                                        variant={inputFieldVariant}
                                        fullWidth
                                        {...params} />
                                      }
                                    />
                                  </LocalizationProvider>

                                )}
                              // rules={{ required: 'This field is required' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Controller
                                name="NetPay"
                                control={control}
                                defaultValue={staff.NetPay}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Net Pay (A+B–C+D+E)"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    disabled
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>


                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{
                      margin: "10px auto",
                      borderRadius: "10px",
                      border: `2px solid ${PrimaryColor}`
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          style={{ backgroundColor: PrimaryColor, color: "white" }}
                          variant="h6"
                        >
                          <AccountCircleIcon style={{ margin: "auto 5px" }} />
                          Qualification
                        </Typography>
                      </Grid>
                      <Grid container spacing={3} style={{ margin: "auto" }}>
                        <Grid item container spacing={1} direction="row">
                          <Grid container spacing={3} style={{ margin: "auto" }}>


                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="HighestQualification"
                                control={control}
                                defaultValue={staff.HighestQualification}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Highest Qualification"
                                    variant={inputFieldVariant}
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                                rules={{ required: 'This field is required' }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{
                      margin: "10px auto",
                      borderRadius: "10px",
                      border: `2px solid ${PrimaryColor}`
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          style={{ backgroundColor: PrimaryColor, color: "white" }}
                          variant="h6"
                        >
                          <AccountCircleIcon style={{ margin: "auto 5px" }} />
                          Certificate Details
                        </Typography>
                      </Grid>
                      <Grid container spacing={3} style={{ margin: "auto" }}>
                        <Grid item container spacing={1} direction="row">
                          <TableContainer>
                            <Table
                              aria-label="customized table"
                              className={classes.table}
                              key={tableKey}
                            >
                              <TableHead>
                                <TableRow color="primary">
                                  <TableCell>Certificate Type</TableCell>
                                  <TableCell>Certification Body</TableCell>
                                  <TableCell>Validity Start Date</TableCell>
                                  <TableCell>Validity End Date</TableCell>
                                  <TableCell>Upload</TableCell>
                                  <TableCell>View</TableCell>
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  certificateDetails.map((cert, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <Select
                                          variant="outlined"
                                          className={classes.dropDown}
                                          value={certificateDetails[index].CertTypeID}
                                          name="CertTypeID"
                                          onChange={(e) => handleTableInput(e, index)}
                                        >
                                          {certificateTypes.map((e) => (
                                            <MenuItem key={e.CertTypeID} value={e.CertTypeID}>{e.CertTypeName}</MenuItem>
                                          ))}
                                        </Select>
                                      </TableCell>
                                      <TableCell>
                                        <Select
                                          variant="outlined"
                                          className={classes.dropDown}
                                          value={certificateDetails[index].CertBodyID}
                                          name="CertBodyID"
                                          onChange={(e) => handleTableInput(e, index)}
                                        >
                                          {certificateBodys.map((e) => (
                                            <MenuItem key={e.CertBodyID} value={e.CertBodyID}>{e.CertBodyName}</MenuItem>
                                          ))}
                                        </Select>
                                      </TableCell>
                                      <TableCell width={"20%"}>
                                        <TextField
                                          variant="outlined"
                                          name="ValidityStartDate"
                                          id="ValidityStartDate"
                                          value={!!certificateDetails[index].ValidityStartDate ? moment(certificateDetails[index].ValidityStartDate).format('YYYY-MM-DD') : certificateDetails[index].ValidityStartDate}
                                          onChange={(e) => handleTableInput(e, index)}
                                          className={classes.dateInput}
                                          type={"date"}
                                        />
                                      </TableCell>
                                      <TableCell width={"20%"}>
                                        <TextField
                                          variant="outlined"
                                          name="ValidityEndDate"
                                          id="ValidityEndDate"
                                          value={!!certificateDetails[index].ValidityEndDate ? moment(certificateDetails[index].ValidityEndDate).format('YYYY-MM-DD') : certificateDetails[index].ValidityEndDate}
                                          onChange={(e) => handleTableInput(e, index)}
                                          className={classes.dateInput}
                                          type={"date"}
                                        />
                                      </TableCell>

                                      <TableCell>
                                        <input
                                          style={{ display: 'none' }}
                                          accept={".pdf"}
                                          type="file"
                                          id="CertificateImageURL"
                                          name="CertificateImageURL"
                                          onChange={(e) => handleTableInput(e, index)}
                                        />
                                        <Button
                                          color="primary"
                                          onClick={(e) => document.getElementsByName('CertificateImageURL')[index].click()}
                                        >Upload</Button>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          disabled={!!!certificateDetails[index].CertificateImageURL}
                                          color="secondary"
                                          onClick={() => {
                                            if (!!certificateDetails[index].CertificateImageURL) {
                                              if (isValidUrl(certificateDetails[index].CertificateImageURL)) {
                                                if (certificateDetails[index].CertificateImageURL?.includes("jpg")) {
                                                  setShowImagePreview(true)
                                                  setImagePreview(certificateDetails[index].CertificateImageURL)
                                                }
                                                else if (certificateDetails[index].CertificateImageURL?.includes("pdf")) {
                                                  window.open(certificateDetails[index].CertificateImageURL)
                                                }
                                              }
                                              else {
                                                if (certificateDetails[index].CertificateImageURL?.type?.includes("image")) {
                                                  setShowImagePreview(true)
                                                  setImagePreview(URL.createObjectURL(certificateDetails[index].CertificateImageURL))
                                                }
                                                else if (certificateDetails[index].CertificateImageURL?.type?.includes("pdf")) {
                                                  window.open(URL.createObjectURL(certificateDetails[index].CertificateImageURL))
                                                }
                                              }
                                            }
                                            else if (!!certificateDetails[index].CertificateImageURL && certificateDetails[index].CertificateImageURL?.includes("pdf")) {
                                              window.open(certificateDetails[index].CertificateImageURL)
                                            }
                                          }}
                                        >{certificateDetails[index].CertFileName || "View"}</Button>
                                      </TableCell>
                                      <TableCell>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                          {certificateDetails.length !== 0 && (
                                            <RemoveCircle
                                              style={{
                                                color: "red",
                                                marginRight: "10px",
                                              }}
                                              onClick={() => {
                                                setTableKey(Math.random());
                                                if (certificateDetails.length === 1) {
                                                  let temp = {
                                                    ...certificateDummyData,
                                                    id: 0,
                                                  };
                                                  setCertificateDetails([temp]);
                                                } else {
                                                  let temp = certificateDetails;
                                                  temp.splice(index, 1);
                                                  setCertificateDetails([...temp]);
                                                }
                                              }}
                                            />
                                          )}

                                          {index === certificateDetails.length - 1 &&
                                            checkTableInputRow(cert.id) && (
                                              <AddCircle
                                                style={{ cursor: "pointer", color: PrimaryColor }}
                                                onClick={() => {
                                                  let temp = certificateDetails;
                                                  let newId =
                                                    certificateDetails[
                                                      certificateDetails.length - 1
                                                    ].id + 1;
                                                  temp.push({
                                                    ...certificateDummyData,
                                                    id: newId,
                                                  });
                                                  setCertificateDetails([...temp]);
                                                }}
                                              />
                                            )}
                                        </div>

                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: '10px' }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        disabled={loading}
                        color="primary"
                        size="small"
                        variant="contained"
                        type="submit"
                      >
                        {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                        {"Update Staff"}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button color="secondary" size="small" variant="contained" onClick={(e) => navigate("/Staff", {
                        state: state
                      })}>
                        Back
                      </Button>
                    </Grid>

                  </Grid>
                </form>

              </Grid>
              :
              <TablePlaceholder />
          }

        </div>

        <ImageViewer
          src={imagePreview}
          show={showImagePreview}
          handleClose={() => setShowImagePreview(false)}
          fileName="StaffImage.jpg"
        />


        <ToastContainer />
      </div>
    </>
  );
};
