//@ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios'
import { Sidebar } from "../Sidebar";
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper, FormControl, InputLabel, Select as MuiSelect } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { MenuItem, Grid, TextField } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { dateTimeFormat, inputFieldVariant } from '../../utils';


export default function CalenderMgmt() {
  const { state } = useLocation();
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const navigate = useNavigate();

  const Options = [
    "Weekly"
  ]


  const fetchWorkOrder = (date: any) => {
    console.log(date)
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}workOrderForWeek?Date=${date}`
      )
      .then(async (res) => {
        let temp = res?.data?.message || []
        setWorkOrders(temp)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch WorkOrders!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'WorkOrderID',
      headerName: 'Work Order Number',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'SiteName',
      headerName: 'Site',
      width: 100,
      flex: 1,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'WorkTypeName',
      headerName: 'WorkType ',
      width: 250,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'AssignedDateTime',
      headerName: 'Assigned DateTime',
      width: 250,
      renderCell: (row) => row.value ? dateTimeFormat(row.value) : "N/A"
    },
  ];


  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <div style={{ padding: '10px 20px', maxWidth: 'inherit', width: '95%' }}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  inputFormat="dd/MM/yyyy"
                  disableFuture
                  value={selectedDate}
                  onChange={(val) => {
                    setSelectedDate(val)
                    fetchWorkOrder(val)
                  }}
                  renderInput={(params) => <TextField
                    variant={inputFieldVariant}
                    fullWidth
                    {...params} />
                  }
                />
              </LocalizationProvider>
            </FormControl>

          </Grid>

          <Grid style={{ width: '90%' }} item>
            <Box sx={{ width: '100%' }}>
              {(loading) ?
                <TablePlaceholder />
                :
                <div style={{ padding: '10px 20px' }}>
                  <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
                    <Toolbar
                      sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        backgroundColor: '#d9dffd',
                      }}
                    >
                      <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Work Order
                      </Typography>
                    </Toolbar>
                    <Box
                      sx={{
                        '& .MuiDataGrid-columnHeaders': {
                          backgroundColor: PrimaryColor,
                          color: 'white'
                        },
                      }}
                    >
                      <DataGrid
                        initialState={{
                          filter: queryOptions,
                        }}
                        onFilterModelChange={onFilterChange}
                        autoHeight
                        getRowId={(row) => row.WorkOrderID}
                        rows={workOrders}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 15, workOrders.length > 100 ? 100 : workOrders.length]}
                        disableSelectionOnClick
                        onPageSizeChange={(size) => setPageSize(size)}
                        page={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </Box>

                  </Paper>

                </div>
              }
              <ToastContainer />
            </Box>
          </Grid>
        </div>
      </div>
    </>



  );
}
