import { Typography, Grid } from '@material-ui/core'
import React from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Sidebar } from '../Sidebar';

export const AboutWidget = () => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <Grid direction='column' container spacing={2} style={{ marginTop: 100 }}>
        <Grid item>
          <Typography variant="h2" style={{ textAlign: 'center' }}> About </Typography>
          <br />
          <br />
          <Typography style={{ textAlign: 'center' }}> Our story</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center' }}><MailOutlineIcon style={{ textAlign: 'center', fontSize: 400 }} /></Grid>
      </Grid>
    </div>
  )
}
