import {
  Grid,
} from "@material-ui/core";
import CompanyTable from "./CompanyTable";
import { Sidebar } from "../Sidebar";

export const CompanyPage = () => {

  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CompanyTable />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
