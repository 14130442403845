// @ts-nocheck
import {

  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Badge, FormLabel, Radio, RadioGroup, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { base64Converter, getCurrentUserID, getDateTime, inputFieldVariant, removeBadgeStyle, siteImages } from "../../utils";
import pdfIcon from '../../assert/pdf_icon.png'
import docIcon from '../../assert/document_icon.png'
import ImageViewer from "../../assert/ImageViewer";
import { useForm, Controller } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap'

// const QQQ = [
//   {
//     id: 1,
//     data: "http://www.africau.edu/images/default/sample.pdf",
//     name: "Sample.pdf"
//   },
//   {
//     id: 2,
//     data: "https://img.freepik.com/free-vector/shining-circle-purple-lighting-isolated-dark-background_1441-2396.jpg",
//     name: "Spark.jpg"
//   },
//   {
//     id: 3,
//     data: "https://calibre-ebook.com/downloads/demos/demo.docx",
//     name: "Demo.docx"
//   },
// ]

const imageExts = [".jpg", ".jpeg", ".png"]

export const UpdateSite = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data

  const [loading, setLoading] = useState(false)
  const [siteZones, setSiteZones] = useState<any>([]);
  const [siteTypes, setSiteTypes] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);
  const [removedFiles, setRemovedFiles] = useState<Array<any>>([]);
  const [existingFiles, setExistingFiles] = useState<Array<any>>(JSON.parse(filteredState.SiteMapImageURL));
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [showImagePreview, setShowImagePreview] = useState<Boolean>(false);
  const [site, setSite] = useState(filteredState);

  const { handleSubmit, control } = useForm();


  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {

        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSiteTypes = () => {
    axios
      .get(`${SERVER_IP}sitetype`)
      .then((res) => {

        setSiteTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const updateSite = async (data) => {

    let files = await uploadedFiles?.map(async d => {
      let t = await base64Converter(d)
      return {
        data: t,
        fileName: d.name
      }
    })

    Promise.all(files).then(function (results) {
      // console.log(results)
      console.log(removedFiles)
      let temp = {
        ...site,
        ...data,
        SiteMapImageURL: results,
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        SiteID: site.SiteID,
        toBeRemoved: removedFiles
      }

      delete temp["SiteZone"]
      delete temp["SiteType"]
      delete temp["LinkedContacts"]
      console.log(temp)
      setLoading(true)
      axios.put(`${SERVER_IP}site?SiteID=${temp.SiteID}`, temp)
        .then((res) => {
          toast.success("Site Successfully Updated");
        }).then(() => navigate("/Site", {
          state: state
        }))
        .catch((res) => {
          toast.error("Couldn't Update Site");
        }).finally(() => setLoading(false))
    })
  };


  useEffect(() => {
    let temp = existingFiles.map((d, index) => {
      return {
        ...d,
        id: index
      }
    })
    setExistingFiles(temp)
    getSiteZones();
    getSiteTypes();
  }, []);


  const handleFileRemoval = (data) => {
    let temp = removedFiles
    temp.push(data)
    setRemovedFiles(temp)

    temp = existingFiles.filter(d => d.id !== data.id)
    setExistingFiles(temp)
    if (temp.length === 0) {
      document.getElementById("SiteMapImageURL").value = ''
    }
  }

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(updateSite)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update Site"}
                  </Typography>
                </Grid>



                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="SiteName"
                      control={control}
                      defaultValue={site.SiteName}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Site Name"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Address1"
                      control={control}
                      defaultValue={site.Address1}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Address 1"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="SiteZoneID"
                      control={control}
                      defaultValue={site.SiteZoneID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Zone</InputLabel>
                          <Select
                            id="SiteZoneID"
                            value={value}
                            label="Site Zone"
                            onChange={onChange}
                          >

                            {siteZones.map((e) => (
                              <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Address2"
                      control={control}
                      defaultValue={site.Address2}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Address 2"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="SiteTypeID"
                      control={control}
                      defaultValue={site.SiteTypeID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Type</InputLabel>
                          <Select
                            id="SiteTypeID"
                            value={value}
                            label="Site Type"
                            onChange={onChange}
                          >

                            {siteTypes.map((e) => (
                              <MenuItem key={e.SiteTypeID} value={e.SiteTypeID}>{e.Description}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="PostCode"
                      control={control}
                      defaultValue={site.PostCode}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Post Code"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="IsNFCAvailable"
                      control={control}
                      defaultValue={site.IsNFCAvailable}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Is NFC Available?</FormLabel>
                          <RadioGroup
                            row
                            value={value}
                            onChange={onChange}
                          >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="SiteStatus"
                      control={control}
                      defaultValue={site.SiteStatus}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Status</InputLabel>
                          <Select
                            value={value}
                            label="Site Status"
                            onChange={onChange}
                          >
                            {["Active", "InActive"].map((e) => (
                              <MenuItem key={e} value={e}>{e}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography>Site Image (Image, PDF, Word, Excel, PPT)</Typography>
                    <input
                      type="file"
                      accept={siteImages}
                      id="SiteMapImageURL"
                      name="SiteMapImageURL"
                      multiple
                      onChange={async (e) => {
                        if (!!e.target.files?.length) {
                          let pendingFiles = []
                          let existingFiles = uploadedFiles.map(f => f.name)
                          var inp = document.getElementById('SiteMapImageURL');
                          for (var i = 0; i < inp.files.length; ++i) {
                            if (!existingFiles.includes(inp.files.item(i).name))
                              pendingFiles.push(inp.files.item(i))
                          }
                          if (pendingFiles.length === 0) {
                            toast.error("File(s) with same name already added")
                            e.stopPropagation();
                            return
                          }
                          setUploadedFiles([...uploadedFiles, ...pendingFiles])
                        }
                      }}
                    />
                  </Grid>


                  <Container>
                    <Row>
                      {
                        existingFiles.map((d, index) => {

                          if (imageExts.some(el => d?.data?.includes(el))) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={d.data}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(d.data)
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>

                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else if (d?.data?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Download and View"
                                    src={docIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>

                  <Container>
                    <Row>

                      {
                        uploadedFiles?.map((d, index) => {

                          if (d?.type?.includes("image")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Enlarge"
                                    src={URL.createObjectURL(d)}
                                    onClick={() => {
                                      setShowImagePreview(true)
                                      setImagePreview(URL.createObjectURL(d))
                                    }}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else if (d?.type?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                          else {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click to Download and View"
                                    src={docIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>


                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Update Site"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/Site", {
                      state: state
                    })}>
                    Back
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

        <ImageViewer
          src={imagePreview}
          show={showImagePreview}
          handleClose={() => setShowImagePreview(false)}
          fileName="SiteMap.jpg"
        />

      </div>
    </>

  );
};
