// @ts-nocheck
import {

  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Badge, FormLabel, Radio, RadioGroup, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { base64Converter, getCurrentUserID, getDateTime, inputFieldVariant, isLoggedUserStaff, removeBadgeStyle } from "../../utils";
import pdfIcon from '../../assert/pdf_icon.png'
import docIcon from '../../assert/document_icon.png'
import ImageViewer from "../../assert/ImageViewer";
import { useForm, Controller } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";


export const UpdateManualReport = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data

  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);
  const [removedFiles, setRemovedFiles] = useState<Array<any>>([]);
  const [siteZones, setSiteZones] = useState<any>([]);
  const [siteTypes, setSiteTypes] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [selectedSiteType, setSelectedSiteType] = useState<any>(filteredState.SiteTypeID);
  const [selectedSiteZone, setSelectedSiteZone] = useState<any>(filteredState.SiteZoneID);
  const [manualReportTypes, setManualReportTypes] = useState<any>([]);
  const [existingFiles, setExistingFiles] = useState<Array<any>>(filteredState.ManualReportURL);
  const [reportDate, setReportDate] = useState<any>(filteredState.ReportDate);

  const { handleSubmit, control, setValue } = useForm();

  const isStaff = isLoggedUserStaff()


  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {

        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSiteTypes = () => {
    axios
      .get(`${SERVER_IP}sitetype`)
      .then((res) => {

        setSiteTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSites = (type = selectedSiteType, zone = selectedSiteZone) => {

    setValue("SiteID", "")
    if (!!!type || !!!zone) {
      setSites([])
      return
    }

    axios
      .get(`${SERVER_IP}ecssitename?ContactID=${getCurrentUserID()}&Staff=${isStaff}&SiteTypeID=${type}&SiteZoneID=${zone}`)
      .then((res) => {

        setSites(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getManualReportTypes = () => {
    axios
      .get(`${SERVER_IP}manualReportType/getAll`)
      .then((res) => {

        setManualReportTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const updateManualReport = async (data) => {

    let files = await uploadedFiles?.map(async d => {
      let t = await base64Converter(d)
      return {
        data: t,
        fileName: d.name
      }
    })

    Promise.all(files).then(function (results) {
      // console.log(results)
      console.log(removedFiles)
      let temp = {
        ...filteredState,
        ...data,
        toBeAdded: results,
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        ManualReportID: filteredState.ManualReportID,
        toBeRemoved: removedFiles
      }
      console.log(temp)
      setLoading(true)
      axios.put(`${SERVER_IP}manualReport/update?ManualReportID=${temp.ManualReportID}`, temp)
        .then((res) => {
          toast.success("ManualReport Successfully Updated");
        }).then(() => navigate("/ManualReports", {
          state: state
        }))
        .catch((res) => {
          toast.error("Couldn't Update ManualReport");
        }).finally(() => setLoading(false))
    })
  };


  useEffect(() => {
    getSiteZones();
    getSiteTypes();
    getSites();
    setValue("SiteID", filteredState.SiteID)
    getManualReportTypes();
    let temp = existingFiles.map((d, index) => {
      return {
        ...d,
        id: index
      }
    })
    setExistingFiles(temp)
  }, []);


  const handleFileRemoval = (data) => {
    let temp = removedFiles
    temp.push(data)
    setRemovedFiles(temp)

    temp = existingFiles.filter(d => d.id !== data.id)
    setExistingFiles(temp)
    if (temp.length === 0) {
      document.getElementById("ManualReportURL").value = ''
    }
  }

  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(updateManualReport)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update ManualReport"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteZoneID"
                      control={control}
                      defaultValue={filteredState.SiteZoneID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Zone</InputLabel>
                          <Select
                            id="SiteZoneID"
                            value={value}
                            label="Site Zone"
                            disabled={!isStaff}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedSiteZone(e.target.value)
                              getSites(undefined, e.target.value);
                            }}
                          >

                            {siteZones.map((e) => (
                              <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteTypeID"
                      control={control}
                      defaultValue={filteredState.SiteTypeID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Type</InputLabel>
                          <Select
                            id="SiteTypeID"
                            value={value}
                            label="Site Type"
                            disabled={!isStaff}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedSiteType(e.target.value)
                              getSites(e.target.value, undefined);
                            }}
                          >

                            {siteTypes.map((e) => (
                              <MenuItem key={e.SiteTypeID} value={e.SiteTypeID}>{e.Description}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteID"
                      control={control}
                      defaultValue={filteredState.SiteID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            label="Site"
                            onChange={onChange}
                            disabled={!isStaff}
                          >

                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Report Date"
                        inputFormat="dd/MM/yyyy"
                        value={reportDate}
                        disabled={!isStaff}
                        onChange={(newValue) => {
                          setReportDate(newValue)
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="ReportDate"
                          name="ReportDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="ReportTypeID"
                      control={control}
                      defaultValue={filteredState.ReportTypeID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Report Type</InputLabel>
                          <Select
                            value={value}
                            label="Report Type"
                            onChange={onChange}
                            disabled={!isStaff}
                          >

                            {manualReportTypes.map((e) => (
                              <MenuItem key={e.ManualReportTypeID} value={e.ManualReportTypeID}>{e.ManualReportName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography>ManualReport Image (Image, PDF, Word, Excel, PPT)</Typography>
                    <input
                      type="file"
                      accept={".pdf"}
                      id="ManualReportURL"
                      name="ManualReportURL"
                      multiple
                      disabled={!isStaff}
                      onChange={async (e) => {
                        if (!!e.target.files?.length) {
                          let pendingFiles = []
                          let existingFiles1 = [...uploadedFiles.map(f => f.name), ...existingFiles.map(f => f.name)]
                          var inp = document.getElementById('ManualReportURL');
                          for (var i = 0; i < inp.files.length; ++i) {
                            if (!existingFiles1.includes(inp.files.item(i).name))
                              pendingFiles.push(inp.files.item(i))
                          }
                          if (pendingFiles.length === 0) {
                            toast.error("File(s) with same name already added")
                            e.stopPropagation();
                            return
                          }
                          setUploadedFiles([...uploadedFiles, ...pendingFiles])
                        }
                      }}
                    />
                  </Grid>


                  <Container>
                    <Row>
                      {
                        existingFiles.map((d, index) => {

                          if (d?.data?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(d.data)
                                    }}
                                    alt="preview"
                                  />
                                  {
                                    isStaff &&
                                    <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                    </Badge>
                                  }

                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>

                  <Container>
                    <Row>

                      {
                        uploadedFiles?.map((d, index) => {


                          if (d?.type?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => handleFileRemoval(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>


                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={!isStaff}
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Update ManualReport"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/ManualReports", {
                      state: state
                    })}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
