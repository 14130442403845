import {
    Grid,
  } from "@material-ui/core";
  import { Sidebar } from "../Sidebar";
  import POTable from "./POTable";
  
  export const POPage = () => {
    return (
      <>
        <div style={{ marginTop: 80, display: 'flex' }}>
          <Sidebar />
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <POTable />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  