//@ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Select, MenuItem } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { dateFormat, isSuperUser } from '../../utils';
import { makeStyles } from '@material-ui/core';
import { alpha as fade } from '@material-ui/core/styles'

const themeSpacing = 10;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginRight: themeSpacing * 2,
    marginLeft: themeSpacing,
    borderRadius: 2,
    background: fade(PrimaryColor, 0.15),
    display: 'inline-block',
    '&:hover': {
      background: fade(PrimaryColor, 0.25),
    },
    '& $input': {
      transition: theme.transitions.create('width'),
      width: 180,
      '&:focus': {
        width: 350,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
  },
  search: {
    width: themeSpacing * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  input: {
    padding: `${themeSpacing}px ${themeSpacing}px ${themeSpacing}px ${themeSpacing * 9}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: '#2a2a2a',
    width: '100%',
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#2a2a2a'
    }
  },
}))

export default function POTable() {

  const classes = useStyles();
  const { state } = useLocation();
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);

  const [POs, setPOs] = useState<any>([]);
  const [backupPOs, setBackupPOs] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const fetchPO = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}po`
      )
      .then(async (res) => {
        let temp = res.data.message
        if (temp.length) {
          let linkedSiteData = await (await axios.get(`${SERVER_IP}getsiteforpo`)).data
          temp.map(d => {
            d["Sites"] = []
            linkedSiteData.map(site => {
              if (site.POID === d.POID)
                d.Sites = site.SiteName
            })
          })
          setPOs(temp);
          setBackupPOs(temp);
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch POs!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      headerName: 'PO Number',
      field: 'POnumber',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Status',
      field: 'POStatus',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'PO Date',
      field: 'POdate',
      width: 150,
      renderCell: (row) => row.value ? dateFormat(row.value) : "NA"
    },
    {
      headerName: 'Contact',
      field: 'ContactName',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Site',
      field: 'Sites',
      width: 250,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Description',
      field: 'Description',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Amount',
      field: 'Amount',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Balance Amount',
      field: 'BalanceAmount',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Staff In Charge',
      field: 'StaffName',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      headerName: 'Action',
      field: 'Action Buttons',
      sortable: false,
      filterable: false,
      width: 250,
      align: 'center',
      headerAlign: 'center',
      disableExport: true,
      hide: !isSuperUser(),
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdatePO', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize : pageSize,
                  currentPage : currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>Edit</Button>
            <Button
              style={{ marginLeft: '10px' }}
              onClick={(e) => {
                navigate("/ReportsByPo", {
                  state: row.row
                })
                e.stopPropagation()
              }} color='default' variant='contained'>View Report</Button>
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    fetchPO();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {(loading) ?
        <TablePlaceholder />
        :
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                PO Request Summary
              </Typography>

              <div className={classes.wrapper}>
                <div className={classes.search}>
                  <SearchIcon />
                </div>
                <input
                  className={classes.input}
                  title="Search PO No, Contact, Company and Status"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      let filteredData = backupPOs.filter(
                        (data) =>
                          data.POnumber
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase()) ||
                          data.ContactName
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase()) ||
                          data.CompanyName
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase()) ||
                          data.POStatus
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase())
                      )
                      setPOs(filteredData);
                    } else {
                      setPOs(backupPOs);
                    }
                  }}
                  placeholder="Search" />
              </div>

            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                initialState={{
                  filter: queryOptions,
                }}
                onFilterModelChange={onFilterChange}
                autoHeight
                style={{ maxWidth: '100%', overflowX: 'auto' }}
                getRowId={(row) => row.POID}
                rows={POs}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15, POs.length > 100 ? 100 : POs.length]}
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
                        <GridToolbarExport
                          style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                          csvOptions={{
                            fileName: 'PO',
                            utf8WithBom: true,
                          }}
                          printOptions={{
                            hideFooter: true,
                            hideToolbar: true,
                            disableToolbarButton: true
                          }}
                        />
                      </GridToolbarContainer>
                    )
                  }
                }}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
                page={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>

          </Paper>

        </div>
      }
      <ToastContainer />
    </Box>
  );
}
