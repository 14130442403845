import RouterWidget from './RouterWidget'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { PrimaryColor } from './variables';
import './custom.css'

const theme = createTheme({
  palette: {
    primary: {
      main: PrimaryColor
    }
  }
});

const App = () => {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <RouterWidget />
      </MuiThemeProvider>

    </div>
  )
}

export default App
