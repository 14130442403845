//@ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import Button from '@mui/material/Button';
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper, Toolbar, Typography } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { isSuperUser } from '../../utils';
import GetAppIcon from '@mui/icons-material/GetApp';
import logo from '../../assert/logo_index.jpeg'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
// import { getExcelDataset } from '../../utils';
// import ReactExport from 'react-data-export'
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


export default function StaffTable() {
  const { state } = useLocation();
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [staffs, setStaffs] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();


  const fetchStaff = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}staff`
      )
      .then((res) => {
        setStaffs(res.data);
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch Staffs!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'StaffName',
      headerName: 'Name',
      width: 200,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Mobile',
      headerName: 'Mobile',
      width: 100,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Email',
      headerName: 'Email',
      width: 200,
      flex: 1,
      renderCell: (row) => <div title={row.value}>{row.value || "NA"}</div>
    },
    {
      field: 'StaffTitle',
      headerName: 'Staff Title',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'AccessControl',
      headerName: 'Access Control',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'EmploymentStatus',
      headerName: 'Employment Status',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },

    {
      field: 'Action',
      headerName: 'Action Buttons',
      sortable: false,
      filterable: false,
      width: 200,
      hide: !isSuperUser(),
      disableExport: true,
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateStaff', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize: pageSize,
                  currentPage: currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>Edit</Button>
            {/* <Button
              style={{ marginLeft: '10px' }}
              disabled
              onClick={(e) => {
                if (window.confirm('Are You Sure to delete ?')) {
                  toast.success("Deleted")
                }
                e.stopPropagation()
              }} color='secondary' variant='contained'>Delete</Button> */}
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    fetchStaff();
  }, []);

  const exportData = () => {

    const doc = new jsPDF("landscape")

    function addFooters() {
      const pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.addImage(logo, 'jpeg', 10, 0, 80, 12)
        doc.text(docRefNo, width - 10, 10, { align: 'right' });
        doc.text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
        doc.text(`Page ${i}`, width - 20, height - 5);
      }
    }


    const columns = [
      {
        field: 'StaffName',
        title: 'Name',
      },
      {
        field: 'Mobile',
        title: 'Mobile',
      },
    ]

    const docRefNo = `Staffs`

    // doc.addImage(logo, 'jpeg', 10, 0, 80, 15)
    // doc.line(20, 25, 60, 25);
    doc.text("Electronic Clocking System Report ", 110, 25)

    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: staffs
    })

    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()

    let finalY = doc.lastAutoTable.finalY + 10; // The y position on the page

    if (finalY > height - 30) {
      doc.addPage()
      finalY = 30;
    }


    doc.text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
    addFooters()
    doc.save(`${docRefNo}.pdf`)
  }

  return (
    <Box sx={{ width: '100%' }}>
      {(loading) ?
        <TablePlaceholder />
        :
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Staff
              </Typography>
              {/* <ExcelFile
                filename="Staffs"
                element={<button type="button" className="btn btn-success float-right m-3">Export Data</button>}>
                <ExcelSheet dataSet={getExcelDataset("staff",staffs)} name="Staffs" />
              </ExcelFile> */}
            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                initialState={{
                  filter: queryOptions,
                }}
                onFilterModelChange={onFilterChange}
                autoHeight
                getRowId={(row) => row.StaffID}
                rows={staffs}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15, staffs.length > 100 ? 100 : staffs.length]}
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
                        <Button
                          style={{ backgroundColor: 'green', color: 'white', fontSize: 13, margin: '0 5px 5px 0' }}
                          color='primary'
                          size='medium'
                          variant='contained'
                          startIcon={<GetAppIcon />}
                          onClick={() => { exportData() }}
                        >
                          PDF
                        </Button>
                        <GridToolbarExport
                          style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                          csvOptions={{
                            fileName: 'Staffs',
                            utf8WithBom: true,
                          }}
                          printOptions={{
                            fileName: 'Staffs',
                            hideFooter: true,
                            hideToolbar: true,
                            // disableToolbarButton: true
                          }}
                        />
                      </GridToolbarContainer>
                    )
                  }
                }}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
                page={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>

          </Paper>

        </div>
      }
      <ToastContainer />
    </Box>
  );
}
