// @ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ToastContainer, toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControl
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { dateFormat, getCurrentUserID, inputFieldVariant, isLoggedUserStaff } from '../../utils';


export const ReportsByPOPage = () => {

  const navigate = useNavigate();

  const { state } = useLocation();
  const filteredState = state?.data
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);

  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState<any>({});
  const [reports, setReports] = useState<any>([]);
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [POs, setPOs] = useState<any>([]);
  const [POStatuses, setPOStatuses] = useState<any>([]);


  const columns: GridColDef[] = [
    {
      field: 'WorkOrderID',
      headerName: 'WO Number',
      width: 100
    },
    {
      field: 'POnumber',
      headerName: 'PO Number',
      width: 100
    },
    {
      field: 'POdate',
      headerName: 'PO Date',
      width: 150,
      renderCell: (row) => row.value ? dateFormat(row.value) : "NA"
    },
    {
      field: 'Description',
      headerName: 'Site Zone',
      width: 100
    },
    {
      field: 'SiteName',
      headerName: 'Site Name',
      width: 200,
      flex: 1
    },
    {
      field: 'WorkTypeName',
      headerName: 'Job Type',
      width: 150,
    },
    {
      field: 'AssignedDateTime',
      headerName: 'Assigned DateTime',
      width: 230,
      renderCell: (row) => row.value ? dateFormat(row.value, "DD/MM/YYYY hh:mm A") : "NA"
    },
    {
      field: 'WorkStatus',
      headerName: 'WO Status',
    },
    {
      field: 'Workers',
      headerName: 'Workers',
      width: 150,
      renderCell: (row) => (
        <>
          {
            row?.value?.length > 0 ?
              <Select value={100}>
                <MenuItem value={100} style={{ display: 'none' }}>Click To View</MenuItem>
                {
                  row.value.map((data, index) => <MenuItem disabled key={index} style={{ color: '#2a2a2a', opacity: 1 }} value={data?.StaffName}>{data?.StaffName}</MenuItem>)
                }
              </Select>
              :
              "NA"
          }

        </>
      )
    },
    {
      field: 'Report',
      headerName: 'Report',
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              setLoading(true)
              axios
                // .get(`${SERVER_IP}getReportByPO?WorkOrderID=1`)
                .get(`${SERVER_IP}getReportByPO?WorkOrderID=${row.id}`)
                .then((res) => {

                  if (res.data.message) {
                    toast.error("No Reports Available")
                    setLoading(false)
                  }
                  else {
                    let reportData = res.data
                    if (row.row.WorkTypeName === "Mosquito Fogging") {
                      navigate('/MosquitoFoggingReport', {
                        state: {
                          reportData: reportData,
                          queryOptions: queryOptions,
                          pageSize : pageSize,
                          currentPage : currentPage
                        }
                      })
                    }
                    else if (row.row.WorkTypeName === "Mosquito Control") {
                      navigate('/MosquitoControlReport', {
                        state: {
                          reportData: reportData,
                          queryOptions: queryOptions,
                          pageSize : pageSize,
                          currentPage : currentPage
                        }
                      })
                    }
                    else if (row.row.WorkTypeName === "Termite Control") {
                      navigate('/TermiteControlReport', {
                        state: {
                          reportData: reportData,
                          queryOptions: queryOptions,
                          pageSize : pageSize,
                          currentPage : currentPage
                        }
                      })
                    }
                    else if (row.row.WorkTypeName === "Common Pests") {
                      navigate('/CommonPestReport', {
                        state: {
                          reportData: reportData,
                          queryOptions: queryOptions,
                          pageSize : pageSize,
                          currentPage : currentPage
                        }
                      })
                    }
                    else if (row.row.WorkTypeName === "Animals") {
                      navigate('/AnimalReport', {
                        state: {
                          reportData: reportData,
                          queryOptions: queryOptions,
                          pageSize : pageSize,
                          currentPage : currentPage
                        }
                      })
                    }
                    else if (row.row.WorkTypeName === "Electronic Clocking") {
                      navigate('/ECS-Reports', {
                        state: reportData,
                      })
                    }
                  }

                }).catch((err) => {
                  console.log(err)
                  toast.error("No Reports Available")
                  setLoading(false)
                })

              e.stopPropagation()
            }} color='primary' variant='contained'>View</Button>
          </div>
        )
      }
    },
  ];

  const isStaff = isLoggedUserStaff()

  // useEffect(() => {
  //   // getCompanies()
  //   // getPOStatuses();
  //   // getPOs();
  //   getReports();
  // }, []);


  const getReports = async () => {
    setLoading(true)
    let url = `pageSize=${pageSize}&currentPage=${currentPage+1}&filter=${JSON.stringify(queryOptions?.filterModel?.items || [])}`;
    if (!!filteredState && !!filteredState.POnumber) {
      url = `${SERVER_IP}getReportbyPONumber?POnumber=${filteredState.POnumber}&` + url
    }
    else {
      if(state && !!state.POnumber)
        url = isStaff ? `${SERVER_IP}getReportbyPONumber?POnumber=${state.POnumber}&` + url : `${SERVER_IP}getReportsForContact?ContactID=${getCurrentUserID()}&` + url
      else
        url = isStaff ? `${SERVER_IP}reportsbyPO?` + url : `${SERVER_IP}getReportsForContact?ContactID=${getCurrentUserID()}&` + url
    }
    axios
      .get(url)
      // .get(`${SERVER_IP}reportsbyPO?${Object.keys(filter).map(function (k) {
      //   if (k === "PODate")
      //     return `${k}="${moment(filter[k]).format('YYYY-MM-DD')}"`
      //   else
      //     return `${k}="${filter[k]}"`
      // }).join("&")}`)
      // .get(`https://nodejsapiengine.uc.r.appspot.com/reportsbyPO?PONumber="10feb"&PODate="2022-02-09"&CompanyID="1"&OverallStatus="1"`)
      .then(async (res) => {

        let temp = res.data?.dataToDisplay
        if (temp.length) {
          // let workersData = await (await axios.get(`${SERVER_IP}workersbyPO`)).data

          // temp.map(data => {
          //   data["Workers"] = []
          //   workersData.map(worker => {
          //     if (worker.WorkOrderID === data.WorkOrderID)
          //       data.Workers.push(worker.StaffName)
          //   })
          // })
          temp = {
            dataToDisplay : temp,
            totalCount : res?.data?.totalCount
          }
          // console.log(temp)
          setReports(temp)
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    getReports();
  }, [currentPage, pageSize, queryOptions])

  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

            {
              false && <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    Reports By PO/JR
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel>PO Number</InputLabel>
                      <Select
                        onChange={function (event) {
                          setFilter({
                            ...filter,
                            PONumber: event.target.value
                          })
                        }}
                      >
                        {POs.map((e, index) => (
                          <MenuItem key={index} value={e.POnumber}>{e.POnumber}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="PO Date"
                        inputFormat="dd/MM/yyyy"
                        // disableFuture
                        value={filter.PODate || null}
                        onChange={(newValue) => {
                          setFilter({
                            ...filter,
                            PODate: newValue
                          })
                          // setSelectedMonth(newValue)
                          // fetchECSReport(undefined,newValue)
                        }}
                        renderInput={(params) => <MuiTextField
                          variant={inputFieldVariant}
                          name="PODate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel>Company</InputLabel>
                      <Select
                        onChange={function (event) {
                          setFilter({
                            ...filter,
                            CompanyID: event.target.value
                          })
                        }}
                      >
                        {companies.map((e) => (
                          <MenuItem key={e.CompanyID} value={e.CompanyID}>{e.CompanyName}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                      <InputLabel>Overall Status</InputLabel>
                      <Select
                        onChange={function (event) {
                          setFilter({
                            ...filter,
                            OverallStatus: event.target.value
                          })
                        }}
                      >
                        {POStatuses.map((e) => (
                          <MenuItem key={e.POStatusID} value={e.POStatusID}>{e.POStatus}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      disabled={loading}
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{ paddingTop: '10px' }}
                      onClick={() => getReports()}
                    >
                      {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                      View Details
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            }
            {
              // !!reports.length &&
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    Reports
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 'auto', width: '95%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Box
                      sx={{
                        width: 1,
                        '& .MuiDataGrid-columnHeaders': {
                          backgroundColor: PrimaryColor,
                        },
                      }}
                    >
                      <DataGrid
                        initialState={{
                          filter: queryOptions,
                        }}
                        onFilterModelChange={onFilterChange}
                        autoHeight
                        getRowId={(row) => row.WorkOrderID}
                        rows={reports?.dataToDisplay || []}
                        columns={columns}
                        pageSize={pageSize}
                        loading={loading}
                        rowCount={reports?.totalCount}
                        paginationMode='server'
                        rowsPerPageOptions={[5, 10, 15, (reports?.totalCount || 0) > 100 ? 100 : (reports?.totalCount || 0)]}
                        // rowsPerPageOptions={[5, 10, 15]}
                        disableSelectionOnClick
                        onPageSizeChange={(size) => setPageSize(size)}
                        page={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            }

            <ToastContainer />
          </Grid>

        </Grid>


      </div>
    </>
  );
};
