// @ts-nocheck
import { useState, useEffect } from 'react';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import Typography from '@mui/material/Typography';
import {
    Grid,
    Button,
} from "@material-ui/core";
import { Sidebar } from "../Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../../assert/logo_index.jpeg'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { dateFormat, getBase64DataFromURL, getMax, isLoggedUserStaff, isValidUrl } from '../../utils';


const docRefNo = "Fogging Report"

export const MosquitoFoggingReport = () => {

    const { state } = useLocation();
    const filteredState = state.reportData
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [reportData, setReportData] = useState<any>(filteredState || {});


    const getReportData = () => {
        axios
            .get(`${SERVER_IP}getReportByPO?WorkOrderID=${filteredState.WorkOrderID}`)
            .then((res) => {

                setReportData(res.data);
            }).catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        async function urlToBase64Conversion() {

            let duplicate = reportData

            await setLoading(true);

            let sign = await getBase64DataFromURL(duplicate?.ContactAckSignImageURL)
            duplicate.ContactAckSignImageBase64 = sign

            reportData?.images?.beforeImages?.map(async (image, index) => {
                let temp = await getBase64DataFromURL(image?.ImageURL)
                duplicate.images.beforeImages[index] = {
                    ...image,
                    base64: temp
                }
            })

            reportData?.images?.afterImages?.map(async (image, index) => {
                let temp = await getBase64DataFromURL(image?.ImageURL)
                duplicate.images.afterImages[index] = {
                    ...image,
                    base64: temp
                }
            })

            setReportData({ ...duplicate })

            await setLoading(false);
        }

        if (reportData) {

            urlToBase64Conversion()
        }
    }, [])

    useEffect(() => {
        // document.getElementById("ppp")?.click()
    }, []);

    const exportData = () => {

        setLoading(true)

        const doc = new jsPDF("landscape")

        function addFooters() {
            const pageCount = doc.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.addImage(logo, 'jpeg', 10, 0, 80, 12)
                doc.text(docRefNo, width - 10, 10, { align: 'right' });
                doc.text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
                doc.text(`Page ${i}`, width - 20, height - 5);
            }
        }


        doc.text(docRefNo, 120, 20)

        const imageSpace = "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"
        const imageHeight = 38
        const imageWidth = 58

        const columns = [
            { title: " ", field: "before", },
            { title: " ", field: "after", },
        ]

        doc.autoTable({
            theme: "grid",
            startY: 25,
            bodyStyles: { lineColor: [0, 0, 0] },
            styles: {
                halign: 'left',
                border: '3px solid',
                valign: 'middle'
            },
            headStyles: {
                halign: 'center',
                fillColor: [241, 90, 41],
                lineColor: [0, 0, 0],
                border: '3px solid'
            },
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            columnStyles: {
                0: { cellWidth: 135 },
                1: { cellWidth: 135 },
            },
            body: [
                [
                    {
                        content: `DATE AND TIME OF VISIT                          : ${dateFormat(reportData?.WOstartDateTime, "DD/MM/YYYY hh:mm A")}\n
NO OF FOGGING MACHINE DEPLOYED  : ${reportData?.FogMachineNum || ""}\n`,
                        styles: { halign: 'left', minCellHeight: 40 }
                    },
                    {
                        content: `DATE AND TIME OF COMPLETION : ${dateFormat(reportData?.WOendDateTime, "DD/MM/YYYY hh:mm A")}\n
ACTION BY                                        : ${reportData?.actionBy?.join(",\n                                                             ") || "None"}\n
PURCHASE ORDER NO                   : ${reportData?.POnumber || ""}\n
WORKORDER NO                             : ${reportData?.WorkOrderID || ""}`,
                        styles: { halign: 'left', minCellHeight: 40 }
                    },
                ],
                [{
                    content: `REMARKS : \n                      ${(reportData?.Findings?.length > 150 ? reportData?.Findings?.slice(0, 150) + "..." : reportData?.Findings) || ""}\n
LOCATION                          : ${reportData?.Location || ""}\n
SITE                                    : ${reportData?.SiteName || "NA"}\n`,
                    colSpan: 2,
                    styles: { halign: 'left', minCellHeight: 50 }
                }],
                ...getMax(reportData?.images?.beforeImages, reportData?.images?.afterImages).slice(0, 5).map(e => {
                    return [{
                        content: `PHOTOGRAPHS${imageSpace}${(e > 1) ? '\n\n' : ''}`,
                        colSpan: 2,
                        styles: { halign: 'left' }
                    }]
                }),
                {
                    before: `TECHNICIAN                                               : ${reportData?.Requestor || ""}\n
REQUESTED ON                                         : ${dateFormat(reportData?.PODate, "DD/MM/YYYY hh:mm A")}\n`,
                    after: `${isValidUrl(reportData?.ContactAckSignImageURL) ? `SIGNATURE OF ACKNOWLEDGEMENT${imageSpace}
ACKNOWLEDGED ON                                 : ${dateFormat(reportData?.ContactAckDateTime, "DD/MM/YYYY hh:mm A")}\n
ACKNOWLEDGED BY                                 : ${reportData?.AckContact}` : "Signature of Acknowledgement Not Available"}`,
                },
            ],
            didDrawCell: async function (data) {
                if (data?.row?.index === 2 && data.cell.section === 'body') {
                    reportData?.images?.beforeImages?.slice(0, 4).map((image, index) => {
                        let xValue = data.cell.x + 10 + ((index * (imageWidth + 5)))
                        let yValue = data.cell.y + 25 //+ (index > 1 ? (imageHeight + 5) : 0)
                        // console.log(xValue, yValue)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }

                if (data?.row?.index === 3 && data.cell.section === 'body') {
                    reportData?.images?.beforeImages?.slice(4, 8).map((image, index) => {
                        let xValue = data.cell.x + 10 + ((index * (imageWidth + 5)))
                        let yValue = data.cell.y + 25 //+ (index > 1 ? (imageHeight + 5) : 0)
                        // console.log(xValue, yValue)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }

                if (data?.row?.index === 4 && data.cell.section === 'body') {
                    reportData?.images?.beforeImages?.slice(8, 12).map((image, index) => {
                        // console.log(index)
                        let xValue = data.cell.x + 10 + ((index * (imageWidth + 5)))
                        let yValue = data.cell.y + 25 //+ (index > 1 ? (imageHeight + 5) : 0)
                        // console.log(xValue, yValue)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                if (data?.row?.index === 5 && data.cell.section === 'body') {
                    reportData?.images?.beforeImages?.slice(12, 16).map((image, index) => {
                        // console.log(index)
                        let xValue = data.cell.x + 10 + ((index * (imageWidth + 5)))
                        let yValue = data.cell.y + 25 //+ (index > 1 ? (imageHeight + 5) : 0)
                        // console.log(xValue, yValue)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }
                if (data?.row?.index === 6 && data.cell.section === 'body') {
                    reportData?.images?.beforeImages?.slice(16, 20).map((image, index) => {
                        // console.log(index)
                        let xValue = data.cell.x + 10 + ((index * (imageWidth + 5)))
                        let yValue = data.cell.y + 25 //+ (index > 1 ? (imageHeight + 5) : 0)
                        // console.log(xValue, yValue)
                        isValidUrl(image?.ImageURL) && doc.addImage(image?.base64, "jpeg", xValue, yValue, imageWidth, imageHeight);
                    })
                }

                if (data?.column?.index == 1 && data?.row?.index === data?.table?.body?.length - 1 && data.cell.section === 'body') {
                    isValidUrl(reportData?.ContactAckSignImageURL) && doc.addImage(reportData?.ContactAckSignImageBase64, "jpeg", data.cell.x + 10, data.cell.y + 7, imageWidth, imageHeight);
                }
            }
        })


        let finalY = doc.lastAutoTable.finalY + 10; // The y position on the page

        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()

        doc.setFontSize(10).text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
        finalY = finalY + 5
        doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
        finalY = finalY + 5
        doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
        addFooters()

        doc.save(`${docRefNo}.pdf`)

        setLoading(false)
    }

    return (
        <>
            <div style={{ marginTop: 80, display: 'flex' }}>
                <Sidebar />
                <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>

                    <Grid container spacing={3} style={{ margin: "auto", padding: '20px' }}>
                        {
                            isLoggedUserStaff() && <div style={{ margin: 10, padding: 5, border: `2px solid ${PrimaryColor}`, backgroundColor: PrimaryColor, color: 'white' }}>
                                <Grid container direction="row" style={{ fontSize: 24 }}>
                                    <Grid item>
                                        <b>Notes To Office</b>
                                    </Grid>
                                    <Grid item>
                                        <b>: &nbsp;</b>{reportData?.NotesToOffice || "NA"}
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <Grid item xs={12}>
                            <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                                <Grid container direction="row">
                                    <Grid item>
                                        {docRefNo}
                                    </Grid>
                                    <Grid item style={{ marginLeft: 'auto' }}>
                                        <Button color="secondary"
                                            style={{ marginRight: '10px' }}
                                            size="small"
                                            variant="contained"
                                            onClick={() => navigate('/ReportsByPO', {
                                                state: state
                                            })}
                                        >
                                            Back
                                        </Button>
                                        <Button color="primary"
                                            disabled={loading}
                                            size="small"
                                            variant="contained"
                                            id="ppp" onClick={() => exportData()}
                                        >
                                            Export
                                        </Button>
                                    </Grid>
                                </Grid>


                            </Typography>

                        </Grid>
                        <Grid style={{ marginLeft: 10, fontSize: 20, lineHeight: 2.5, border: '2px solid' }} container spacing={1} direction="row">
                            <Grid container>
                                <Grid style={{ padding: 10 }} item xs={6}>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Date and Time of Visit</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {dateFormat(reportData?.WOstartDateTime, "DD/MM/YYYY hh:mm A")}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>No Of Fogging Machine Deployed</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {reportData?.FogMachineNum}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Technician</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {reportData?.Requestor}
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Requested On</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {dateFormat(reportData?.PODate, "DD/MM/YYYY hh:mm A")}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Acknowledged By</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b>  {reportData?.AckContact}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Acknowledged On</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b>  {dateFormat(reportData?.ContactAckDateTime, "DD/MM/YYYY hh:mm A")}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Grid>
                                <Grid style={{ padding: 10 }} item xs={6}>

                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Date and Time of Completion</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {dateFormat(reportData?.WOendDateTime, "DD/MM/YYYY hh:mm A")}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Action By &nbsp;</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {
                                                    reportData?.actionBy?.map((w, index) => <div key={index}> {index === 0 && <b>: &nbsp;</b>} {index !== 0 && <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}{w}</div>)
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Purchase Order No</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {reportData?.POnumber || "NA"}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Work Order No</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {reportData?.WorkOrderID || "NA"}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Grid>
                            </Grid>

                            <Grid style={{ padding: 10 }} container>
                                <Grid item xs={12}>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item >
                                                <b>REMARKS : </b>
                                                <div style={{ lineBreak: 'anywhere' }}> {reportData?.Findings}</div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={3}>
                                                <b>Location</b>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <b>: &nbsp;</b> {reportData?.Location}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={3}>
                                                <b>Site</b>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <b>: &nbsp;</b> {reportData?.SiteName || "NA"}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid style={{ margin: 10 }} item xs={12}>
                                <span><b>Photographs</b></span>
                                <Grid container>
                                    {
                                        reportData?.images?.beforeImages?.map((data, index) => (
                                            <Grid item xs={3} key={index} style={{ maxHeight: '90%', marginBottom: 5 }}>
                                                <img
                                                    src={data?.ImageURL}
                                                    // src={logo}
                                                    alt='findings'
                                                    style={{ height: '100%', width: '100%', padding: 2 }}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>

                            </Grid>

                            <Grid container>
                                <Grid style={{ padding: 10 }} item xs={6}>

                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Technician</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {reportData?.Requestor}
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Requested On</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b> {dateFormat(reportData?.PODate, "DD/MM/YYYY hh:mm A")}
                                            </Grid>
                                        </Grid>
                                    </div>


                                </Grid>
                                <Grid style={{ padding: 10 }} item xs={6}>
                                    <div>
                                        <b>Signature of Acknowledgement</b>
                                    </div>
                                    <div style={{ maxHeight: '90%', marginBottom: 10 }}>
                                        <img
                                            src={reportData?.ContactAckSignImageURL}
                                            alt='signature'
                                            style={{ height: '200px', width: '50%' }}
                                        />
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Acknowledged By</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b>  {reportData?.AckContact}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Grid container direction="row">
                                            <Grid item xs={6}>
                                                <b>Acknowledged On</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>: &nbsp;</b>  {dateFormat(reportData?.ContactAckDateTime, "DD/MM/YYYY hh:mm A")}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </div>
        </>
    );
};