import { useState } from 'react'
import { Backdrop, Fade, Modal, Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { getCurrentUserID, getDateTime } from '../../utils';
import moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxWidth: '90%',
  maxHeight: '90vh'
};

function SignaturePad(props) {

  let sigPadRef = {}
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={props.show}
      onClose={() => props.handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.show}>
        <Box sx={modalStyle}>
          <Grid
            container
            spacing={3}
            style={{
              borderRadius: "10px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                style={{ backgroundColor: PrimaryColor, color: "white" }}
                variant="h4"
                gutterBottom
              >
                {"Sign Below"}
              </Typography>
            </Grid>
            <Grid style={{ padding: 10, margin: 10, border: `2px solid ${PrimaryColor}` }} item xs={12}>
              <SignatureCanvas backgroundColor="white" penColor='blue' canvasProps={{ width: 700, height: 200, className: 'sig-canvas' }} ref={(ref) => { sigPadRef = ref }} />
            </Grid>
            <Grid container style={{ marginBottom: '10px' }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ textAlign: "center" }}
              >
                <Button
                  disabled={loading}
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    let temp = {}
                    let url;
                    if (props.reportType === "ECS") {
                      temp = {
                        SiteZoneID: props.SiteZoneID,
                        SiteTypeID: props.SiteTypeID,
                        SiteID: props.SiteID,
                        //@ts-ignore
                        SignatureURL: sigPadRef.getTrimmedCanvas().toDataURL('image/png').split("base64,").pop(),
                        AcknowledgedBy: getCurrentUserID(),
                        ReportedDateTime: getDateTime(),
                        StatusMonthAndYear: moment(props.selectedMonth).format("MM-YYYY")
                      }
                      url = `${SERVER_IP}auth/authorizeEcsReport`
                    }
                    else {
                      temp = {
                        StatusReportType: props.reportType,
                        //@ts-ignore
                        SignatureURL: sigPadRef.getTrimmedCanvas().toDataURL('image/png').split("base64,").pop(),
                        AcknowledgedBy: getCurrentUserID(),
                        ReportedDateTime: getDateTime(),
                        StatusMonthAndYear: moment(props.selectedMonth).format("MM-YYYY")
                      }
                      url = `${SERVER_IP}authorizeStatusReports/UpdateADCReportSign`
                    }
                    console.log(temp)
                    setLoading(true)
                    axios
                      .post(url, temp)
                      .then(() => {
                        props.handleClose()
                        props.fetchReports()
                        toast.success("Report Signed")
                      })
                      .catch((err) => {
                        console.log(err)
                        toast.error(err?.response?.data?.message || "Couldn't Update Sign");
                      }).finally(() => setLoading(false))

                  }}
                >
                  {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                  Confirm
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ textAlign: "center" }}
              >
                <Button
                  size="small"
                  variant="contained"
                  //@ts-ignore
                  onClick={(e) => sigPadRef?.clear()}>
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ textAlign: "center" }}
              >
                <Button
                  color="error"
                  size="small"
                  variant="contained"
                  onClick={(e) => props.handleClose()}>
                  Cancel
                </Button>
              </Grid>

            </Grid>
          </Grid>
          <ToastContainer />
        </Box>
      </Fade>
    </Modal>
  );
}

export default SignaturePad;