import {
    Grid,
  } from "@material-ui/core";
  import { Sidebar } from "../Sidebar";
  import OperationTable from "./OperationTable";
  
  export const OperationSummary = () => {
    return (
      <>
        <div style={{ marginTop: 80, display: 'flex' }}>
          <Sidebar />
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <OperationTable />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  