// @ts-nocheck
import {
  Grid,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import axios from "axios";
import React, { useState, FormEvent, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, Badge } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { base64Converter, dateFormat, getCurrentUserID, getDateTime, inputFieldVariant, isLoggedUserStaff, removeBadgeStyle, siteImages } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import pdfIcon from '../../assert/pdf_icon.png'
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

export const AddManualReport = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [siteZones, setSiteZones] = useState<any>([]);
  const [siteTypes, setSiteTypes] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [manualReportTypes, setManualReportTypes] = useState<any>([]);
  const [selectedSiteType, setSelectedSiteType] = useState<any>("");
  const [selectedSiteZone, setSelectedSiteZone] = useState<any>("");
  const [reportDate, setReportDate] = useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);

  const isStaff = isLoggedUserStaff()

  const { handleSubmit, control, setValue } = useForm();


  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {

        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSiteTypes = () => {
    axios
      .get(`${SERVER_IP}sitetype`)
      .then((res) => {

        setSiteTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSites = (type = selectedSiteType, zone = selectedSiteZone) => {

    setValue("SiteID", "")
    if (!!!type || !!!zone) {
      setSites([])
      return
    }

    axios
    .get(`${SERVER_IP}ecssitename?ContactID=${getCurrentUserID()}&Staff=${isStaff}&SiteTypeID=${type}&SiteZoneID=${zone}`)
      .then((res) => {

        setSites(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getManualReportTypes = () => {
    axios
      .get(`${SERVER_IP}manualReportType/getAll`)
      .then((res) => {

        setManualReportTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const createManualReport = async (data) => {
    if (!!!uploadedFiles.length) {
      toast.error("Report Not Uploaded")
      return
    }
    if (!!!reportDate) {
      toast.error("Select a Date")
      return
    }

    let files = await uploadedFiles?.map(async d => {
      let t = await base64Converter(d)
      return {
        data: t,
        fileName: d.name
      }
    })

    Promise.all(files).then(function (results) {
      let temp = {
        ...data,
        ReportDate: dateFormat(reportDate, "YYYY-MM-DD"),
        ManualReportURL: results,
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
      }
      console.log(temp)
      setLoading(true)
      axios
        .post(
          `${SERVER_IP}manualReport/save`,
          temp
        )
        .then((res) => {
          toast.success("Report Successfully Added");
        }).then(() => navigate("/ManualReports"))
        .catch((res) => {
          toast.error("Couldn't Add Report");
        }).finally(() => setLoading(false))
    })
  };

  useEffect(() => {
    getSiteZones();
    getSiteTypes();
    getManualReportTypes();
  }, []);

  const removeUploadedFile = (file) => {
    let temp = uploadedFiles.filter(f => f.name !== file.name)
    setUploadedFiles(temp)
    if(temp.length === 0){
      document.getElementById("ManualReportURL").value = ''
    }
  }


  return (

    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(createManualReport)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Add Manual Report"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteZoneID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Zone</InputLabel>
                          <Select
                            id="SiteZoneID"
                            value={value}
                            label="Site Zone"
                            onChange={(e) => {
                              onChange(e);
                              setSelectedSiteZone(e.target.value)
                              getSites(undefined, e.target.value);
                            }}
                          >

                            {siteZones.map((e) => (
                              <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteTypeID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Type</InputLabel>
                          <Select
                            id="SiteTypeID"
                            value={value}
                            label="Site Type"
                            onChange={(e) => {
                              onChange(e);
                              setSelectedSiteType(e.target.value)
                              getSites(e.target.value, undefined);
                            }}
                          >

                            {siteTypes.map((e) => (
                              <MenuItem key={e.SiteTypeID} value={e.SiteTypeID}>{e.Description}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            label="Site"
                            onChange={onChange}
                          >

                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Report Date"
                        inputFormat="dd/MM/yyyy"
                        value={reportDate}
                        onChange={(newValue) => {
                          setReportDate(newValue)
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="ReportDate"
                          name="ReportDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <Controller
                      name="ReportTypeID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Report Type</InputLabel>
                          <Select
                            value={value}
                            label="Report Type"
                            onChange={onChange}
                          >

                            {manualReportTypes.map((e) => (
                              <MenuItem key={e.ManualReportTypeID} value={e.ManualReportTypeID}>{e.ManualReportName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <Typography>Report (PDF Only)</Typography>
                    <input
                      type="file"
                      multiple
                      accept={'.pdf'}
                      id="ManualReportURL"
                      name="ManualReportURL"
                      onChange={async (e) => {
                        if (!!e.target.files?.length) {
                          let pendingFiles = []
                          let existingFiles = uploadedFiles.map(f => f.name)
                          var inp = document.getElementById('ManualReportURL');
                          for (var i = 0; i < inp.files.length; ++i) {
                            if (!existingFiles.includes(inp.files.item(i).name))
                              pendingFiles.push(inp.files.item(i))
                          }
                          if (pendingFiles.length === 0) {
                            toast.error("File with same name already added")
                            e.stopPropagation();
                            return
                          }
                          setUploadedFiles([...uploadedFiles, ...pendingFiles])
                        }
                      }}
                    />
                  </Grid>

                  <Container>
                    <Row>

                      {
                        uploadedFiles?.map((d, index) => {

                          if (d?.type?.includes("pdf")) {
                            return <Col key={index} sm>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12}>
                                  <img
                                    title="Click To View"
                                    src={pdfIcon}
                                    style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(URL.createObjectURL(d))
                                    }}
                                    alt="preview"
                                  />
                                  <Badge style={removeBadgeStyle} title="Remove" onClick={() => removeUploadedFile(d)} color="error" badgeContent="X">
                                  </Badge>
                                </Grid>
                                <strong>File Name : {d.name}</strong>
                              </div>
                            </Col>
                          }
                        })
                      }

                    </Row>
                  </Container>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Create Report"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/ManualReports")}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
