// @ts-nocheck
import { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import { Table } from 'react-bootstrap'
import {
  Select,
  MenuItem,
  InputLabel,
  Grid,
  TextField,
  Button,
  FormControl,
} from "@material-ui/core";
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import logo from "../../assert/logo_index.jpeg"
import { getCurrentUserID, getDateTime, getLoggedUserRole, isLoggedUserStaff } from '../../utils'
import SignaturePad from './SignaturePad';
const SampleData = {
  header: [
    "Week",
    "Point 1",
    "Point 2",
    "Point 3",
    "Point 4",
    "Point 5",
    "Point 6",
    "Point 7",
    "Point 8",
    "Point 9",
    "Point 10",
    "Point 11",
    "Point 12",
    "Point 13",
    "Point 14",
    "Point 15",
    "Point 16",
  ],
  data: [
  ]
}


export default function ECSReportTable() {
  const { state } = useLocation();
  const [eCSReports, setECSReports] = useState<any>(SampleData);
  const [loading, setLoading] = useState(false)
  const [selectedSiteZone, setSelectedSiteZone] = useState<any>(state?.SiteZoneID)
  const [selectedSiteType, setSelectedSiteType] = useState<any>(state?.SiteTypeID)
  const [selectedSiteName, setSelectedSiteName] = useState<any>(state?.SiteID)
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
  // const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date().setMonth(0));
  const [reportData, setReportData] = useState<any>({
    NumberOfMC: "",
    NumberOfTC: "",
    NumberOfCPC: "",
    Worker1: "",
    Worker2: "",
    Worker3: "",
  });
  const [siteZones, setSiteZones] = useState<any>([]);
  const [siteTypes, setSiteTypes] = useState<any>([]);
  const [siteNames, setSiteNames] = useState<any>([]);
  const [addButtonClicked, setAddButtonClicked] = useState(false);


  const populateData = (xxx) => {
    let headers = Array.from(new Set(xxx.pointsData.map(d => d.PointNumber)))
    // console.log(headers)

    let finalData = []


    const getWeekParam = (date) => {
      const d = new Date(date)
      const weekOfMondayDate = new Date(d.setDate(d.getDate() - d.getDay() + 1)).getDate();
      const getMonthFromDate = d.getMonth() + 1
      return ('0' + weekOfMondayDate).slice(-2) + "/" + ('0' + getMonthFromDate).slice(-2) + "/" + d.getFullYear()
    }

    let pointsJson = {}


    headers.map(header => pointsJson[header] = [])

    let pointsJsonTwo = {}
    xxx.ecsReports.map(data => pointsJsonTwo[getWeekParam(data.ScanDateTime)] = {})
    Object.keys(pointsJsonTwo).map(key => pointsJsonTwo[key] = pointsJson)

    let Weeks = xxx.ecsReports.map(data => getWeekParam(data.ScanDateTime))
    Weeks = [...new Set(Weeks)]

    let firstWeekData = {}
    let secondWeekData = {}
    let thirdWeekData = {}
    let fourthWeekData = {}
    let fifthWeekData = {}

    headers.map(header => {
      firstWeekData[header] = []
      secondWeekData[header] = []
      thirdWeekData[header] = []
      fourthWeekData[header] = []
      fifthWeekData[header] = []
    })

    xxx.ecsReports.map(data => {
      let week = getWeekParam(data.ScanDateTime)
      if (week === Weeks[0]) {
        firstWeekData[data.PointNumber].push(data.ScanDateTime)
      }
      else if (week === Weeks[1]) {
        secondWeekData[data.PointNumber].push(data.ScanDateTime)
      }
      else if (week === Weeks[2]) {
        thirdWeekData[data.PointNumber].push(data.ScanDateTime)
      }
      else if (week === Weeks[3]) {
        fourthWeekData[data.PointNumber].push(data.ScanDateTime)
      }
      else if (week === Weeks[4]) {
        fifthWeekData[data.PointNumber].push(data.ScanDateTime)
      }
    })

    Object.keys(pointsJsonTwo).map(week => {
      if (week === Weeks[0]) {
        pointsJsonTwo[week] = firstWeekData
      }
      else if (week === Weeks[1]) {
        pointsJsonTwo[week] = secondWeekData
      }
      else if (week === Weeks[2]) {
        pointsJsonTwo[week] = thirdWeekData
      }
      else if (week === Weeks[3]) {
        pointsJsonTwo[week] = fourthWeekData
      }
      else if (week === Weeks[4]) {
        pointsJsonTwo[week] = fifthWeekData
      }
    })

    // console.log(pointsJsonTwo)

    Object.keys(pointsJsonTwo).map(key => {
      Object.keys(pointsJsonTwo[key]).map(point => pointsJsonTwo[key][point].sort(function (a, b) {
        return new Date(b) - new Date(a);
      }))
    })
    // console.log("-----------------------------------------------------------")
    // console.log(pointsJsonTwo)

    for (let i = 0; i < 6; i++) {
      Object.keys(pointsJsonTwo).map(key => {

        Object.keys(pointsJsonTwo[key]).map(point => {
          let availableDates = []
          pointsJsonTwo[key][point].map((date, index) => {
            // console.log(date)
            // console.log(key)
            // console.log(point)
            if (availableDates.includes(moment(date).format("DD/MM/YYYY"))) {
              // console.log("Already There")
              pointsJsonTwo[key][point].splice(index, 1);
            }
            else {
              // console.log("Not Present..Added")
              availableDates.push(moment(date).format("DD/MM/YYYY"))
            }
            // console.log(availableDates)
          })
        })
      })


    }


    // console.log("-----------------------------------------------------------")
    // console.log(pointsJsonTwo)


    Object.keys(pointsJsonTwo).map(key => {
      Object.keys(pointsJsonTwo[key]).map(point => pointsJsonTwo[key][point].sort(function (a, b) {
        return new Date(a) - new Date(b);
      }))
    })
    // console.log("-----------------------------------------------------------")
    // console.log(pointsJsonTwo)

    const checkIfBExists = (data) => {
      let res = false
      Object.keys(data).map(key => {
        if (data[key].length > 1) {
          res = true
        }
      })
      return res
    }

    const checkIfCExists = (data) => {
      let res = false
      Object.keys(data).map(key => {
        if (data[key].length > 2) {
          res = true
        }
      })
      return res
    }

    const getWeekData = (d) => {
      switch (d) {
        case 1:
          return '1st'
        case 2:
          return '2nd'
        case 3:
          return '3rd'
        case 4:
          return '4th'
        default:
          return '5th'
      }
    }


    Object.keys(pointsJsonTwo).map((key, index) => {
      if (checkIfBExists(pointsJsonTwo[key])) {
        let tempOne = {}
        let tempTwo = {}
        let tempThree = {}
        tempOne["Week"] = getWeekData(index + 1);
        tempOne["Date Of Visit"] = key;
        headers.map(header => tempOne[`Point ${header}`] = "")

        Object.keys(pointsJsonTwo[key]).map(point => {
          tempOne[`Point ${point}`] = pointsJsonTwo[key][point][0];
        })
        finalData.push(tempOne)

        tempTwo["Week"] = getWeekData(index + 1);
        tempTwo["Date Of Visit"] = key;
        headers.map(header => tempTwo[`Point ${header}`] = "")

        Object.keys(pointsJsonTwo[key]).map(point => {
          tempTwo[`Point ${point}`] = pointsJsonTwo[key][point][1];
        })
        finalData.push(tempTwo)
        if (checkIfCExists(pointsJsonTwo[key])) {
          tempThree["Week"] = getWeekData(index + 1);
          tempThree["Date Of Visit"] = key;
          headers.map(header => tempThree[`Point ${header}`] = "")

          Object.keys(pointsJsonTwo[key]).map(point => {
            tempThree[`Point ${point}`] = pointsJsonTwo[key][point][2];
          })
          finalData.push(tempThree)
        }

      }
      else {
        let temp = {}
        temp["Week"] = getWeekData(index + 1);
        temp["Date Of Visit"] = key;
        headers.map(header => temp[`Point ${header}`] = "")

        Object.keys(pointsJsonTwo[key]).map(point => {
          temp[`Point ${point}`] = pointsJsonTwo[key][point][0];
        })
        finalData.push(temp)
      }

    })

    // console.log(finalData)

    let modifiedHeader = headers.map(header => `Point ${header}`)
    modifiedHeader.unshift("Date Of Visit")
    modifiedHeader.unshift("Week")

    return {
      header: modifiedHeader,
      data: finalData
    }

  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    // console.log(name, value)
    setReportData({
      ...reportData,
      [name]: value
    })
  };

  const fetchECSReport = (siteId = selectedSiteName, month = selectedMonth) => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}ecsreports?SiteZoneID=${selectedSiteZone}&SiteTypeID=${selectedSiteType}&SiteID=${siteId}&ScanDateTime=${moment(month).format("YYYY-MM-DD")}&Staff=${getLoggedUserRole()}`,
        // `${SERVER_IP}ecsreports?SiteZoneID=1&SiteTypeID=1&SiteID=1&ScanDateTime=2022-01-04`,
      )
      .then((res) => {
        setReportData(res.data)
        let d = populateData(res.data)
        // console.log(d)
        setECSReports(d);
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch ECSReports!");
      }).finally(() => {
        setLoading(false)
        document.getElementById('qqq')?.click()
      })
  };

  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {

        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSiteTypes = () => {
    axios
      .get(`${SERVER_IP}sitetype`)
      .then((res) => {

        setSiteTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const isStaff = isLoggedUserStaff()

  const getSiteNames = (type = selectedSiteType, zone = selectedSiteZone) => {
    axios
      .get(`${SERVER_IP}ecssitename?ContactID=${getCurrentUserID()}&Staff=${isStaff}&SiteTypeID=${type}&SiteZoneID=${zone}`)
      .then((res) => {

        setSiteNames(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const updateReportData = () => {
    let temp = {
      ...reportData,
      ReportDate: moment(selectedMonth).format("YYYY-MM-DD"),
      UpdatedByUserID: getCurrentUserID(),
      UpdatedDateTime: getDateTime(),
    }

    delete temp["ecsReports"]
    delete temp["pointsData"]
    axios
      .put(`${SERVER_IP}ecsReportData/update?SiteZoneID=${selectedSiteZone}&SiteTypeID=${selectedSiteType}&SiteID=${selectedSiteName}&ScanDateTime=${moment(selectedMonth).format("YYYY-MM-DD")}`, temp)
      .then((res) => {
        console.log(res.data)
      }).catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    getSiteZones();
    getSiteTypes();

    if (!!state) {
      getSiteNames(state?.SiteTypeID, state?.SiteZoneID)
      fetchECSReport()
    }

    // fetchECSReport()

    // document.getElementById("ppp")?.click()

  }, []);

  const exportData = () => {

    const doc = new jsPDF("landscape")

    function addFooters() {
      const pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.addImage(logo, 'jpeg', 10, 0, 80, 12)
        doc.text(docRefNo, width - 10, 10, { align: 'right' });
        doc.text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
        doc.text(`Page ${i}`, width - 20, height - 5);
      }
    }

    const studentData = [
      {
        id: 1,
        work: "Mosquito Control",
        noOfServices: reportData.NumberOfMC || 0,
        vectorControl: reportData.Worker1,
      },
      {
        id: 2,
        work: "Termites Control",
        noOfServices: reportData.NumberOfTC || 0,
        vectorControl: reportData.Worker2,
      },
      {
        id: 3,
        work: "Common Pest ",
        noOfServices: reportData.NumberOfCPC || 0,
        vectorControl: reportData.Worker3,
      }
    ]
    const columns = [
      { title: "Work Performed", field: "work", },
      { title: "Number of Services", field: "noOfServices", },
      { title: "Technician/Workers", field: 'vectorControl' }
    ]



    let site = siteNames.filter(s => s.SiteID === selectedSiteName)[0]
    let siteName = site.SiteName
    let month = moment(selectedMonth).format("MMMM YYYY")

    const docRefNo = `ECSR-${siteName}-${moment(selectedMonth).format("YYYYMM")}`

    // doc.addImage(logo, 'jpeg', 10, 0, 80, 15)
    // doc.line(20, 25, 60, 25);
    doc.text("Electronic Clocking System Report ", 110, 25)

    doc.autoTable({
      theme: "grid",
      startY: 35,
      styles: {
        halign: 'right'
      },
      body: [
        {
          siteName: "Site",
          siteNameValue: siteName,
          monthName: "Month",
          monthNameValue: month,
        },
        {
          monthName: "Report Gen",
          monthNameValue: moment(new Date()).format("DD/MM/YYYY hh:mm A"),
        },
        {
          monthName: "Document Ref",
          monthNameValue: docRefNo,
        },
      ],
      didParseCell: function (data) {
        if (data?.column?.index === 0 || data?.column?.index === 2) {
          data.cell.styles.fontStyle = 'bold';
        }
      }
    })
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: studentData
    })
    doc.autoTable({ html: '#ddd' })

    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()

    let finalY = doc.lastAutoTable.finalY + 10; // The y position on the page

    if (finalY > height - 30) {
      doc.addPage()
      finalY = 30;
    }

    doc.setFontSize(10).text(20, finalY, "Acknowledged By")
    finalY = finalY + 5
    !!reportData.authImageUrl && doc.addImage(reportData.authImageUrl, 'jpeg', 20, finalY, 80, 15)
    // doc.line(20, finalY, 80, finalY);
    finalY = finalY + 20
    doc.setFontSize(15).text(20, finalY, reportData?.SignedBy || "")
    finalY = finalY + 5
    doc.setFontSize(10).text(20, finalY, `Date : ${moment(new Date()).format("DD/MM/YYYY")}`)

    finalY = finalY + 5


    doc.text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
    addFooters()
    doc.save(`${docRefNo}.pdf`)
  }

  const handleModalClose = () => {
    setAddButtonClicked(false)
  }

  const getFirstNonEmptyValue = (obj = {}) => {

    let nonEmptyKey = Object.keys(obj).find(d => d.includes("Point") && !!obj[d]);
    return obj[nonEmptyKey]

  }

  return (

    <Box style={{ maxWidth: '95%' }}>

      <div style={{ padding: '10px 20px', maxWidth: 'inherit' }}>
        <Paper style={{ borderRadius: '10px', maxWidth: 'inherit' }}>
          <Grid container spacing={3} style={{ padding: '10px' }}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Site Zone</InputLabel>
                <Select
                  id="SiteZone"
                  label="Site Zone"
                  value={selectedSiteZone}
                  onChange={function (event) {
                    setECSReports(SampleData);
                    setSelectedSiteZone(event.target.value);
                    !!selectedSiteType && getSiteNames(undefined, event.target.value)
                  }}
                >
                  {siteZones.map((e) => (
                    <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Site Type</InputLabel>
                <Select
                  id="SiteTypeID"
                  value={selectedSiteType}
                  label="Site Type"
                  onChange={function (event) {
                    setECSReports(SampleData);
                    setSelectedSiteType(event.target.value);
                    !!selectedSiteZone && getSiteNames(event.target.value, undefined)
                  }}
                >

                  {siteTypes.map((e) => (
                    <MenuItem key={e.SiteTypeID} value={e.SiteTypeID}>{e.Description}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Site Name</InputLabel>
                <Select
                  id="SiteTypeID"
                  value={selectedSiteName}
                  label="Site Name"
                  onChange={function (event) {
                    setSelectedSiteName(event.target.value);
                    setECSReports(SampleData);
                    fetchECSReport(event.target.value)
                  }}
                >

                  {siteNames.map((e) => (
                    <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Month"
                  views={['month', 'year']}
                  // disableFuture
                  // disabled
                  value={selectedMonth}
                  onChange={(newValue) => {
                    setSelectedMonth(newValue)
                    fetchECSReport(undefined, newValue)
                  }}
                  renderInput={(params) => <MuiTextField
                    variant="outlined"
                    id="selectedMonth"
                    name="selectedMonth"
                    fullWidth
                    {...params} />
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="NumberOfMC"
                label="No of Mosquito Control"
                fullWidth
                variant="outlined"
                onChange={onChange}
                disabled={reportData.isAuthorized}
                value={reportData.NumberOfMC || ""}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="NumberOfTC"
                label="No of Termite Control"
                fullWidth
                variant="outlined"
                onChange={onChange}
                disabled={reportData.isAuthorized}
                value={reportData.NumberOfTC || ""}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="NumberOfCPC"
                label="No of Common Pest Control"
                fullWidth
                variant="outlined"
                onChange={onChange}
                disabled={reportData.isAuthorized}
                value={reportData.NumberOfCPC || ""}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="Worker1"
                label="Worker 1"
                fullWidth
                variant="outlined"
                onChange={onChange}
                disabled={reportData.isAuthorized}
                value={reportData.Worker1 || ""}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="Worker2"
                label="Worker 2"
                fullWidth
                variant="outlined"
                onChange={onChange}
                disabled={reportData.isAuthorized}
                value={reportData.Worker2 || ""}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="Worker3"
                label="Worker 3"
                fullWidth
                variant="outlined"
                onChange={onChange}
                disabled={reportData.isAuthorized}
                value={reportData.Worker3 || ""}
              />
            </Grid>

          </Grid>
        </Paper>

        <Paper style={{ borderRadius: '10px', marginTop: '30px', maxWidth: '1150px', overflowX: 'auto' }}>
          {(loading) ?
            <TablePlaceholder />
            :

            eCSReports?.data?.length > 0 ?
              <Paper style={{ borderRadius: '10px' }}>
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    backgroundColor: '#d9dffd',
                  }}
                >
                  <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    ECS Reports

                  </Typography>
                  <Typography
                    sx={{ marginLeft: 'auto' }}
                  >
                    <Button id="ppp" color="primary" variant='contained' onClick={() => {
                      updateReportData();
                      exportData();
                    }}>Export</Button>

                  </Typography>
                </Toolbar>

                <TableContainer component={Paper}>
                  <Table id="ddd">
                    <TableHead>
                      <TableRow style={{ backgroundColor: PrimaryColor, color: 'white' }}>
                        {
                          eCSReports.header.map((data, index) => <TableCell key={index} style={{ minWidth: "150px", color: 'inherit', fontWeight: 'bold', fontSize: '18px' }} >{data}</TableCell>)
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        eCSReports?.data?.map((data, index) => (
                          <TableRow key={index}>
                            {
                              !!data && Object.keys(data).map((key, index) => (<TableCell key={index} style={{ minWidth: "150px", fontWeight: 'bold', fontSize: 18 }}>
                                {
                                  key === "Week" && data[key]
                                }
                                {
                                  key === "Date Of Visit" && !!data["Point 1"] && moment(data["Point 1"]).format("DD/MM/YYYY")
                                }
                                {
                                  key.includes("Point") && !!data[key] && moment(data[key]).format("hh:mm A")
                                }
                              </TableCell>
                              ))
                            }
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container>
                  <Grid item sm={12} md={6}>

                  </Grid>
                  <Grid style={{ textAlign: 'center', margin: 'auto' }} item sm={12} md={6}>
                    {
                      !!reportData.authImageUrl &&
                      <div>
                        <img
                          src={reportData.authImageUrl}
                          alt="Sign"
                          width="80%"
                        />
                        <div style={{ textAlign: 'center', margin: 'auto', fontSize: 26, fontWeight: 'bold', paddingBottom: 10 }}>Signed By : {reportData.SignedBy}</div>
                      </div>

                    }
                    {
                      !isStaff && !!!reportData.isAuthorized && <Button
                        color="primary"
                        variant='contained'
                        onClick={() => {
                          setAddButtonClicked(true)
                        }}
                      >
                        {"Authorize"}
                      </Button>
                    }
                  </Grid>
                </Grid>

              </Paper>
              :
              <h2 style={{ textAlign: 'center' }}>No Data Matches</h2>
          }

        </Paper>
        <SignaturePad
          show={addButtonClicked}
          handleClose={() => handleModalClose()}
          selectedMonth={selectedMonth}
          fetchReports={() => fetchECSReport()}
          reportType="ECS"
          SiteID={selectedSiteName}
          SiteZoneID={selectedSiteZone}
          SiteTypeID={selectedSiteType}
        />
      </div>

      <ToastContainer />
    </Box>

  );
}
