import { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { Button } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function OperationTable() {
    const [staffs, setOperations] = useState<any>([]);
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(5)
    const navigate = useNavigate();

    const fetchOperation = () => {
        setLoading(true)
        axios
            .get(
                `${SERVER_IP}staff`
            )
            .then((res) => {
                setOperations(res.data);
            })
            .catch((err) => {
                console.log(err)
                toast.error("Couldn't fetch Operations!");
            }).finally(() => setLoading(false))
    };

    const columns: GridColDef[] = [
        {
            headerName: 'JO Number',
            field: 'Name',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Site Zone',
            field: 'Name',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Site Name',
            field: 'Name',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'PO Type',
            field: 'Mobile',
            width: 100,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'PO Frequency',
            field: 'Mobile',
            width: 100,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Start Date',
            field: 'Email',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'End Date',
            field: 'Operation Title',
            width: 150,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Assigned Date Time',
            field: 'Access Control',
            width: 150,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'JO Status',
            field: 'Employment Status',
            width: 150,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Worker',
            field: 'Employment Status',
            width: 150,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Assign Worker',
            field: 'Employment Status',
            width: 150,
            renderCell: (row) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <Button onClick={(e) => {
                            //   navigate('/createOperation', {
                            //     state: row.row,
                            //   });
                            e.stopPropagation()
                        }} color='primary' variant='contained'>Edit</Button>
                    </div>
                )
            }
        },
        {
            headerName: 'Report',
            field: 'Employment Status',
            width: 150,
            renderCell: (row) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <Button onClick={(e) => {
                            //   navigate('/createOperation', {
                            //     state: row.row,
                            //   });
                            e.stopPropagation()
                        }} color='primary' variant='contained'>Edit</Button>
                    </div>
                )
            }
        },
        {
            headerName: 'Reschedule',
            field: 'Employment Status',
            width: 150,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Reschedule Details',
            field: 'Employment Status',
            width: 150,
            renderCell: (row) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <Button onClick={(e) => {
                            //   navigate('/createOperation', {
                            //     state: row.row,
                            //   });
                            e.stopPropagation()
                        }} color='primary' variant='contained'>Edit</Button>
                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        // fetchOperation();
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            {(loading) ?
                <TablePlaceholder />
                :
                <div style={{ padding: '10px 20px' }}>
                    <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                backgroundColor: '#d9dffd',
                            }}
                        >
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Operation Summary
                            </Typography>
                        </Toolbar>
                        <Box
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: PrimaryColor,
                                    color: 'white'
                                },
                            }}
                        >
                            <DataGrid
                                autoHeight
                                getRowId={(row) => row.OperationID}
                                rows={staffs}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[5, 10, 15, staffs.length > 100 ? 100 : staffs.length]}
                                disableSelectionOnClick
                                onPageSizeChange={(size) => setPageSize(size)}
                            />
                        </Box>

                    </Paper>

                </div>
            }
            <ToastContainer />
        </Box>
    );
}
