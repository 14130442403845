//@ts-nocheck
import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { Button, Select, MenuItem } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { isSuperUser } from '../../utils';


export default function CompanyTable() {
  const [companies, setCompanies] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(5)
  const navigate = useNavigate();

  const fetchCompany = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}company/getAll`
      )
      .then(async (res) => {
        let companyData = res.data
        setCompanies(companyData)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch Companies!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'CompanyName',
      headerName: 'Name',
      width: 200,
      flex : 1,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'BillingAddress1',
      headerName: 'Address 1',
      width: 250,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'BillingAddress2',
      headerName: 'Address 2',
      width: 250,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'BillingPostCode',
      headerName: 'Post Code',
      width: 250,
      renderCell: (row) => row.value || "NA"
    },

    {
      field: 'Action',
      headerName: 'Action Buttons',
      sortable: false,
      filterable: false,
      width: 200,
      disableExport: true,
      hide: !isSuperUser(),
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateCompany', {
                state: row.row,
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>Edit</Button>
            {/* <Button
              style={{ marginLeft: '10px' }}
              disabled
              onClick={(e) => {
                if (window.confirm('Are You Sure to delete ?')) {
                  toast.success("Deleted")
                }
                e.stopPropagation()
              }} color='secondary' variant='contained'>Delete</Button> */}
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    fetchCompany();
  }, []);

  return (


    <Box sx={{ width: '100%' }}>
      {(loading) ?
        <TablePlaceholder />
        :
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Company
              </Typography>
            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                autoHeight
                disableExtendRowFullWidth={true}
                getRowId={(row) => row.CompanyID}
                rows={companies}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15, companies.length > 100 ? 100 : companies.length]}
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
                        <GridToolbarExport
                          style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                          csvOptions={{
                            fileName: 'Companies',
                            utf8WithBom: true,
                          }}
                          printOptions={{
                            hideFooter : true,
                            hideToolbar : true,
                            disableToolbarButton : true
                          }}
                        />
                      </GridToolbarContainer>
                    )
                  }
                }}
                componentsProps={{}}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
              />
            </Box>

          </Paper>

        </div>
      }
      <ToastContainer />
    </Box>

  );
}
