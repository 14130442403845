//@ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import { Button as MuiButton } from '@mui/material';
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { Button } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { dateFormat, isSuperUser } from "../../utils";
import logo from '../../assert/logo_index.jpeg'
import brands from '../../assert/brands.png'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default function QuotationTable() {
  const { state } = useLocation();
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [quotations, setQuotations] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const fetchQuotation = () => {
    setLoading(true)
    const url = `${SERVER_IP}SalesQuotation/getAll?pageSize=${pageSize}&currentPage=${currentPage+1}&filter=${JSON.stringify(queryOptions?.filterModel?.items || [])}`
    axios
      .get(
        url
      )
      .then(async (res) => {
        let temp = res.data?.dataToDisplay || []
        temp = {
          dataToDisplay : temp,
          totalCount : res?.data?.totalCount
        }
        // console.log(temp)
        setQuotations(temp)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch Quotations!");
      }).finally(() => setLoading(false))
  };

  const exportData = (data) => {

    // console.log(data)
    const GST = 8;

    let amount = parseInt(data.Amount) || 0;
    let GSTAmount = GST * 0.01 * amount
    let totalAmount = amount + GSTAmount

    setLoading(true)

    const docRefNo = "Quotation"

    const doc = new jsPDF("portrait")

    function addFooters() {
      const pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.addImage(logo, 'jpeg', 10, 5, 60, 10)
        doc.setFontSize(15).setFont(undefined, 'bold').text(docRefNo.toUpperCase(), width - 10, 10, { align: 'right' });
        // doc.setFontSize(7).setFont(undefined, 'normal').text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
        doc.setFontSize(7).setFont(undefined, 'normal').text(`Page ${i} of ${pageCount}`, width - 20, 15, { align: 'center' });
      }
    }

    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()

    const columns = [
      { title: "DESCRIPTION", field: "description", },
      { title: "AMOUNT (SGD)", field: "amount", },
    ]

    doc.setFontSize(7).setFont(undefined, 'bold').text(`PEST-PRO MANAGEMENT PTE LTD`, 15, 25);
    doc.setFontSize(7).setFont(undefined, 'normal').text(`3A INTERNATIONAL BUSINESS PARK #11-05, ICON@IBP SINGAPORE 609935`, 15, 29);
    doc.text(`RCB No: 201005550C GST Reg No: 201005550C`, 15, 33);
    doc.text(`Tel: +65 6300 8385 Fax: +65 6569 6468`, 15, 37);
    doc.text(`Email: customercare@pestpro.com.sg Website: www.pestpro.com.sg`, 15, 41);

    doc.setFontSize(10).setFont(undefined, 'bold').text(data.SiteName || '', 20, 50);
    doc.setFontSize(10).setFont(undefined, 'normal').text(data.Address1, 20, 54);
    !!data.Address2 && doc.text(data.Address2, 20, 58);
    doc.text(`SINGAPORE ${data.PostCode}`, 20, 62);
    doc.text(`ATTN: ${data.ContactName}`, 20, 66);
    const textWidth = doc.getTextWidth(`ATTN: ${data.ContactName}`);
    doc.line(20, 67, 20 + textWidth, 67)

    doc.rect((width / 2) + 5, 45, 75, 25);

    doc.setFontSize(10).setFont(undefined, 'bold').text(`Quotation No`, (width / 2) + 10, 50)
      .setFont(undefined, 'normal').text(`:  ${data.QuotationNumber}`, (width / 2) + 40, 50);
    doc.setFontSize(10).setFont(undefined, 'bold').text(`Quotation Date`, (width / 2) + 10, 55)
      .setFont(undefined, 'normal').text(`:  ${dateFormat(data.QuotationDate)}`, (width / 2) + 40, 55);
    doc.setFontSize(10).setFont(undefined, 'bold').text(`Validity`, (width / 2) + 10, 60)
      .setFont(undefined, 'normal').text(`:  ${data.Validity}`, (width / 2) + 40, 60);
    doc.setFontSize(10).setFont(undefined, 'bold').text(`Account No`, (width / 2) + 10, 65)
      .setFont(undefined, 'normal').text(`:  ${data.AccountNumber}`, (width / 2) + 40, 65);


    doc.autoTable({
      theme: "grid",
      startY: 80,
      breakAtY: width - 10,
      bodyStyles: { lineColor: [0, 0, 0] },
      styles: {
        halign: 'left',
        border: '2px solid',
        valign: 'middle'
      },
      headStyles: {
        halign: 'center',
        fillColor: [241, 90, 41],
        lineColor: [0, 0, 0],
        border: '2px solid'
      },
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      columnStyles: {
        0: { cellWidth: 140 },
        1: { cellWidth: 36, halign: 'right' },
      },
      body: [{
        description: data.Description,
        amount: amount.toFixed(2)
      }],
      didParseCell: (hookData) => {
        if (hookData.section === 'head') {
          if (hookData.column.dataKey === 'description') {
            hookData.cell.styles.halign = 'left';
          }
          if (hookData.column.dataKey === 'amount') {
            hookData.cell.styles.halign = 'right';
          }
        }
      }

    })


    let finalY = doc.lastAutoTable.finalY + 15;

    doc.setFontSize(8).setFont(undefined, 'normal').text(`Sub Total`, (width / 2) + 40, finalY).text(`${amount.toFixed(2)}`, (width / 2) + 80, finalY, { align: 'right' })
    finalY += 5
    doc.setFontSize(8).setFont(undefined, 'normal').text(`GST ${GST}.00%`, (width / 2) + 40, finalY).text(`${GSTAmount.toFixed(2)}`, (width / 2) + 80, finalY, { align: 'right' })
    doc.setFontSize(7).setFont(undefined, 'bold').text(`This is a system generated quotation, no signature is required.`, 15, finalY)
    finalY += 2
    doc.setDrawColor('black').setLineWidth(0.5).line(10, finalY, width - 10, finalY);

    finalY += 5

    doc.setFontSize(7).setFont(undefined, 'bold').text(`Total Amount`, (width / 2) + 40, finalY).text(`${totalAmount.toFixed(2)}`, (width / 2) + 80, finalY, { align: 'right' })
    doc.setFontSize(7).setFont(undefined, 'bold').text(`PEST-PRO MANAGEMENT PTE LTD`, 15, finalY);
    finalY += 4
    doc.setFontSize(7).setFont(undefined, 'normal').text(`3A INTERNATIONAL BUSINESS PARK #11-05, ICON@IBP SINGAPORE 609935`, 15, finalY);
    finalY += 4
    doc.text(`Tel: +65 6300 8385 Fax: +65 6569 6468`, 15, finalY);
    finalY += 4
    doc.text(`Email: customercare@pestpro.com.sg Website: www.pestpro.com.sg`, 15, finalY);
    finalY += 4

    doc.rect(15, finalY, (width / 2) + 20, 22);
    doc.addImage(brands, 'png', (width / 2) + 40, finalY, (width / 2) - 50, 22)
    finalY += 5

    doc.setFontSize(7).setFont(undefined, 'bold')
      .text(`If you have any Sales or Service queries;`, 17, finalY);
    finalY += 6

    doc.setFont(undefined, 'normal').text(`Your Salesperson is`, 17, finalY);
    doc.setFont(undefined, 'normal')
      .text(`For Quotation/ service report matters kindly refer to`, (width / 2) - 30, finalY);
    finalY += 4

    doc.setFont(undefined, 'bold').text(`PESTPRO`, 17, finalY);
    doc.setFont(undefined, 'bold').text(``, (width / 2) - 30, finalY);
    finalY += 4
    doc.setFont(undefined, 'normal').text(`Email`, 17, finalY);

    doc.setFont(undefined, 'normal').text(`Email: `, (width / 2) - 30, finalY);
    finalY += 7

    doc.setFont(undefined, 'normal').text(`Prepared By :\t\t\t\t Printed By :\t\t\t\t Printed On : ${dateFormat(Date.now(), 'DD/MM/YYYY HH:mm:ss A')}`, 15, finalY);
    finalY += 5

    // doc.addImage(remittance, 'png', 10, finalY, width - 20, 60)

    addFooters()

    doc.save(`${docRefNo}.pdf`)

    setLoading(false)
  }

  const columns: GridColDef[] = [
    {
      field: 'QuotationNumber',
      headerName: 'Quotation Number',
      width: 200,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'ContactName',
      headerName: 'Contact Name',
      width: 200,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'SiteName',
      headerName: 'Site',
      width: 200,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Amount',
      headerName: 'Amount',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'QuotationDate',
      headerName: 'Quotation Date',
      width: 200,
      renderCell: (row) => dateFormat(row.value) || "NA"
    },
    {
      field: 'Action',
      headerName: 'Action Buttons',
      sortable: false,
      filterable: false,
      disableExport: true,
      hide: !isSuperUser(),
      width: 250,
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateQuotation', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize: pageSize,
                  currentPage: currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>Edit</Button>
            <MuiButton
              style={{ marginLeft: '10px' }}
              id={`pp${row.row.QuotationId}`}
              onClick={(e) => {
                e.preventDefault()
                exportData(row.row)
              }}
              color='secondary'
              variant='contained'
            >
              Download
            </MuiButton>
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    fetchQuotation();
  }, [currentPage, pageSize, queryOptions])

  // useEffect(()=>{
  //   console.log(quotations)
  //   if(!!quotations.length){
  //     document.getElementById('pp5')?.click()
  //   }
  // },[quotations])

  return (


    <Box sx={{ width: '100%' }}>
      {(loading) ?
        <TablePlaceholder />
        :
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Quotation
              </Typography>
            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                initialState={{
                  filter: queryOptions,
                }}
                onFilterModelChange={onFilterChange}
                autoHeight
                getRowId={(row) => row.SalesQuotationID}
                rows={quotations?.dataToDisplay || []}
                columns={columns}
                pageSize={pageSize}
                loading={loading}
                rowCount={quotations?.totalCount}
                paginationMode='server'
                rowsPerPageOptions={[5, 10, 15, (quotations?.totalCount || 0) > 100 ? 100 : (quotations?.totalCount || 0)]}
                // components={{
                //   Toolbar: () => {
                //     return (
                //       <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
                //         <GridToolbarExport
                //           style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                //           csvOptions={{
                //             fileName: 'Quotations',
                //             utf8WithBom: true,
                //           }}
                //           printOptions={{
                //             hideFooter: true,
                //             hideToolbar: true,
                //             disableToolbarButton: true
                //           }}
                //         />
                //       </GridToolbarContainer>
                //     )
                //   }
                // }}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
                page={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>

          </Paper>

        </div>
      }
      <ToastContainer />
    </Box>

  );
}
