//@ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { Button, Select, MenuItem } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { isSuperUser } from '../../utils';
import GetAppIcon from '@mui/icons-material/GetApp';
import logo from '../../assert/logo_index.jpeg'
import jsPDF from 'jspdf'
import 'jspdf-autotable'


export default function ContactTable() {
  const { state } = useLocation();
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [contacts, setContacts] = useState<any>([]);
  const [loading, setLoading] = useState(false)


  const navigate = useNavigate();

  const fetchContact = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}contact`
      )
      .then(async (res) => {
        let contactData = res.data
        let linkedSiteData = await (await axios.get(`${SERVER_IP}contactsitelist`)).data
        contactData.map(contact => {
          contact["LinkedSites"] = []
          linkedSiteData.map(site => {
            if (site.ContactID === contact.ContactID)
              contact.LinkedSites.push(site.SiteName)
          })
        })
        setContacts(contactData)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch Contacts!");
      }).finally(() => setLoading(false))
  };

  const columns: GridColDef[] = [
    {
      field: 'ContactName',
      headerName: 'Name',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'CompanyName',
      headerName: 'Company',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Mobile',
      headerName: 'Mobile',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Telephone',
      headerName: 'Telephone',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'Email1',
      headerName: 'Email',
      flex: 1,
      renderCell: (row) => row.value || "NA"
    },
    {
      field: 'LinkedSites',
      headerName: 'Linked Sites',
      width: 150,
      sortable: false,
      valueGetter: (params) => `${params?.value?.join(", ")}`,
      renderCell: (row) => (
        <>
          {
            row.row.LinkedSites?.length > 0 ?
              <Select value={100}>
                <MenuItem value={100} style={{ display: 'none' }}>Click To View</MenuItem>
                {
                  row.row.LinkedSites?.map((data, index) => <MenuItem disabled key={index} style={{ color: '#2a2a2a', opacity: 1 }} value={data}>{data}</MenuItem>)
                }
              </Select>
              :
              "NA"
          }

        </>
      )
    },
    {
      field: 'Action',
      headerName: 'Action Buttons',
      sortable: false,
      filterable: false,
      width: 200,
      disableExport: true,
      hide: !isSuperUser(),
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateContact', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize: pageSize,
                  currentPage: currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>Edit</Button>
            {/* <Button
              style={{ marginLeft: '10px' }}
              disabled
              onClick={(e) => {
                if (window.confirm('Are You Sure to delete ?')) {
                  toast.success("Deleted")
                }
                e.stopPropagation()
              }} color='secondary' variant='contained'>Delete</Button> */}
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    fetchContact();
  }, []);

  const exportData = () => {

    const doc = new jsPDF("landscape")

    function addFooters() {
      const pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.addImage(logo, 'jpeg', 10, 0, 80, 12)
        doc.text(docRefNo, width - 10, 10, { align: 'right' });
        doc.text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
        doc.text(`Page ${i}`, width - 20, height - 5);
      }
    }


    const columns = [
      {
        field: 'ContactName',
        title: 'Name',
      },
      {
        field: 'CompanyName',
        title: 'Company',
      },
      {
        field: 'Mobile',
        title: 'Mobile',
      },
      {
        field: 'Telephone',
        title: 'Telephone',
      },
      {
        field: 'Status',
        title: 'Status',
      },
      {
        field: 'Email1',
        title: 'Email',
      },
    ]

    const docRefNo = `Contacts`

    doc.text("Electronic Clocking System Report ", 110, 25)

    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: contacts
    })

    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()

    let finalY = doc.lastAutoTable.finalY + 10; // The y position on the page

    if (finalY > height - 30) {
      doc.addPage()
      finalY = 30;
    }


    doc.text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
    finalY = finalY + 5
    doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
    addFooters()
    doc.save(`${docRefNo}.pdf`)
  }

  return (


    <Box sx={{ width: '100%' }}>
      {(loading) ?
        <TablePlaceholder />
        :
        <div style={{ padding: '10px 20px' }}>
          <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                backgroundColor: '#d9dffd',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Contact
              </Typography>
            </Toolbar>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: PrimaryColor,
                  color: 'white'
                },
              }}
            >
              <DataGrid
                initialState={{
                  filter: queryOptions
                }}
                onFilterModelChange={onFilterChange}
                autoHeight
                disableExtendRowFullWidth={true}
                getRowId={(row) => row.ContactID}
                rows={contacts}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15, contacts.length > 100 ? 100 : contacts.length]}
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
                        <Button
                          style={{ backgroundColor: 'green', color: 'white', fontSize: 13, margin: '0 5px 5px 0' }}
                          color='primary'
                          size='medium'
                          variant='contained'
                          startIcon={<GetAppIcon />}
                          onClick={() => { exportData() }}
                        >
                          PDF
                        </Button>
                        <GridToolbarExport
                          style={{ backgroundColor: 'green', color: 'white', fontSize: 16, marginBottom: 5 }}
                          csvOptions={{
                            fileName: 'Contacts',
                            utf8WithBom: true,
                          }}
                          printOptions={{
                            hideFooter: true,
                            hideToolbar: true,
                            // disableToolbarButton: true
                          }}
                        />
                      </GridToolbarContainer>
                    )
                  }
                }}
                disableSelectionOnClick
                onPageSizeChange={(size) => setPageSize(size)}
                page={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>

          </Paper>

        </div>
      }
      <ToastContainer />
    </Box>

  );
}
