// @ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ToastContainer, toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import {
  Grid,
  Button,
} from "@material-ui/core";
import { Sidebar } from "../Sidebar";
import { dateFormat, getCurrentUserID, isLoggedUserStaff } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';


export const ManualReportsPage = () => {
  const { state } = useLocation();
  const [queryOptions, setQueryOptions] = useState(state?.queryOptions || {});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [pageSize, setPageSize] = useState(state?.pageSize || 5);
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 0);
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState<any>([]);
  const navigate = useNavigate();

  const isStaff = isLoggedUserStaff()


  const columns: GridColDef[] = [
    {
      field: 'ManualReportID',
      headerName: 'Report ID',
      renderCell: (row) => row.value || "NA",
      width: 70
    },
    {
      field: 'SiteZoneName',
      headerName: 'Site Zone',
      renderCell: (row) => row.value || "NA",
      width: 100
    },
    {
      field: 'SiteTypeName',
      headerName: 'Site Type',
      renderCell: (row) => row.value || "NA",
      width: 200
    },
    {
      field: 'SiteName',
      headerName: 'Site Name',
      renderCell: (row) => row.value || "NA",
      width: 200,
      flex: 1
    },
    {
      field: 'ReportDate',
      headerName: 'Report Date',
      width: 100,
      renderCell: (row) => row.value ? dateFormat(row.value) : "NA"
    },

    {
      field: 'ReportTypeName',
      headerName: 'Report Type',
      renderCell: (row) => row.value || "NA",
      width: 150,
    },
    {
      field: 'ManualReportURL',
      headerName: 'Report',
      width: 200,
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button onClick={(e) => {
              navigate('/UpdateManualReport', {
                state: {
                  data: row.row,
                  queryOptions: queryOptions,
                  pageSize : pageSize,
                  currentPage : currentPage
                }
              });
              e.stopPropagation()
            }} color='primary' variant='contained'>View</Button>
            <Button
              color="error"
              size="small"
              variant="contained"
              style={{ marginLeft: 20 }}
              onClick={() => {
                if (window.confirm("Are You Sure?")) {
                  deleteReport(row.id)
                }
              }}
            >
              Delete
            </Button>
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    getReports();
  }, []);


  const getReports = async () => {
    setLoading(true)
    let url = `${SERVER_IP}manualReport/getAll?ContactID=${getCurrentUserID()}&Staff=${isStaff}`
    axios
      .get(url).then(async (res) => {
        let temp = res.data;
        setReports(temp)
      }).catch((err) => {
        console.log(err)
      }).finally(() => setLoading(false))
  }

  const deleteReport = async (id) => {
    setLoading(true)
    axios.delete(`${SERVER_IP}manualReport/delete?ManualReportID=${id}`)
      .then((res) => {
        getReports();
        toast.success("Deleted Successfully...Table Will Refresh in Few Seconds");
      })
      .catch((res) => {
        toast.error("Couldn't Delete!");
      }).finally(() => setLoading(false))
  }

  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

            {
              // !!reports.length &&
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    Manual Reports
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 'auto', width: '95%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Box
                      sx={{
                        width: 1,
                        '& .MuiDataGrid-columnHeaders': {
                          backgroundColor: PrimaryColor,
                        },
                      }}
                    >
                      <DataGrid
                        initialState={{
                          filter: queryOptions,
                        }}
                        onFilterModelChange={onFilterChange}
                        autoHeight
                        getRowId={(row) => row.ManualReportID}
                        rows={reports}
                        columns={columns}
                        pageSize={pageSize}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 15, reports.length > 100 ? 100 : reports.length]}
                        disableSelectionOnClick
                        onPageSizeChange={(size) => setPageSize(size)}
                        page={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            }

            <ToastContainer />
          </Grid>

        </Grid>


      </div>
    </>
  );
};
