import {
  Grid,
} from "@material-ui/core";
import { Sidebar } from "../Sidebar";
import StaffTable from "./StaffTable";

export const StaffPage = () => {
  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StaffTable />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
