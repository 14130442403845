import React from 'react'
import { Backdrop, Fade, Modal, Box, Button } from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { isValidUrl } from '../utils';
import { saveAs } from "file-saver";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxWidth: '90%',
  maxHeight: '90vh'
};

function ImageViewer(props) {

  const downloadFile = () => {

    
    if (isValidUrl(props.src)) {
      saveAs(
        props.src,
        props.fileName
      );
    }
    else{
      const link = document.createElement('a');
      link.href = props.src;
      link.setAttribute('download', props.fileName);
      document.body.appendChild(link);
      link.click();
    }

  }
  return (
    <Modal
      open={props.show}
      onClose={() => props.handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.show}>
        <Box sx={modalStyle}>
          <TransformWrapper
            initialScale={1}
          // initialPositionX={200}
          // initialPositionY={100}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <div style={{ marginBottom: '10px', justifyContent: 'space-around' }}>
                  <Button style={{ marginRight: '20px' }} variant="contained" color="primary" onClick={() => zoomIn()}>Zoom In +</Button>
                  <Button style={{ marginRight: '20px' }} variant="contained" color="primary" onClick={() => zoomOut()}>Zoom Out -</Button>
                  <Button style={{ marginRight: '20px' }} variant="contained" color="primary" onClick={() => resetTransform()}>Reset</Button>
                  <Button style={{ marginRight: '20px' }} variant="contained" color="success" onClick={() => downloadFile()}>Download</Button>
                  <Button variant="contained" color="error" onClick={() => props.handleClose()}>Close</Button>
                </div>
                <TransformComponent>
                  <img style={{ height: '60vh' }} src={props.src} alt="preview" />
                  {/* <div>Example text</div> */}
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ImageViewer;