import React, { useState, useEffect } from "react";
import { Header } from "./LoginWidget";
import {
  Grid,
  makeStyles,
  Theme,
  Container,
  Box,
  TextField,
  Button,
  Modal,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import LogoIndex from "../assert/logo_index.jpeg";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios'
import { SERVER_IP } from "../variables";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(5, 5),
    textAlign: "center",
    maxWidth: "500px",
  },
  textField: {
    width: 250,
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      width: '80%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: theme.shape.borderRadius * 2,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: theme.shape.borderRadius * 2,
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '20%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: theme.shape.borderRadius * 2,
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      width: '20%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
  closebtn: {
    position: 'absolute',
    right: '25px',
    color: '#5C5C5C',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
}));


export const ResetWidget = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false)


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    navigate("/")
    setOpen(false)
  }

  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token")

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === "password1") {
      setPassword1(value)
    }
    else if (name === "password2") {
      setPassword2(value)
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!!!password1 || !!!password2) {
      toast.error("Passwords should not be empty");
      return
    }

    if (password1 !== password2) {
      toast.error("Passwords don't match..check again");
      return
    }

    setLoading(true)

    axios
      .put(`${SERVER_IP}updatePassword`, {
        token: token,
        password: password1
      })
      .then(async (res) => {
        setLoading(false)
        handleOpen()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        toast.error("Something Went Wrong");
      });

  };

  useEffect(() => {
    axios
      .get(`${SERVER_IP}verifyToken?token=${token}`)
      .then(async (res) => {
        if (!res.data.status) {
          window.alert("Link Expired...!")
          navigate("/")
        }
      })
      .catch((err) => {
        console.log(err)
        navigate("/")
      });
  }, [])

  return (
    <div>
      <Header />
      <Container maxWidth="xs" style={{ marginTop: 100, minWidth: 400, backgroundColor: '#f1f1fa', padding: 50, borderRadius: 20 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item container className={classes.container}>
            <Grid item xs={12} style={{ minHeight: "20px", padding: 5, borderRadius: 20 }}>
              <img style={{ width: '100%' }} alt="logo" src={LogoIndex} />
            </Grid>
            <Grid item xs={12} style={{ minHeight: "20px", padding: 5, backgroundColor: 'gray', borderRadius: 20 }}>
              <Typography variant="h5" style={{ color: '#fff' }}>Reset Password</Typography>
            </Grid>
          </Grid>
          <form onSubmit={submitForm}>
            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              style={{ marginTop: 10, textAlign: "right" }}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  onChange={onChange}
                  value={password1}
                  name="password1"
                  autoFocus
                  label="Enter Password"
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  type={showPassword1 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword1}
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  onChange={onChange}
                  value={password2}
                  name="password2"
                  label="Confiirm Password"
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  type={showPassword2 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword2}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 100, borderRadius: 20 }}
                  onClick={submitForm}
                  disabled={loading}
                >
                  {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                  Reset
                </Button>

                <Modal
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <div style={{
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%)`,
                    width: 400,
                    padding: 30,
                  }} className={classes.paper}>
                    <CloseIcon onClick={handleClose} className={classes.closebtn} />
                    <Box>
                      <Typography style={{ fontWeight: 'bold' }} variant="h6" component="h2">
                        Password Changed
                      </Typography>
                      <Typography sx={{ mt: 2 }}>
                        Your Password Has Been Changed Successfully
                      </Typography>
                    </Box>
                  </div>
                </Modal>
              </Grid>
            </Grid>
          </form>

        </Grid>
      </Container>
      <ToastContainer />
    </div>
  );
};
