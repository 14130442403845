// @ts-nocheck
import {
  FormControlLabel,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import axios from "axios";
import { Button as MuiButton } from '@mui/material';
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { NumericFormat } from 'react-number-format';
import { ToastContainer, toast } from "react-toastify";
import { FormLabel, Radio, RadioGroup, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserID, getDateTime, inputFieldVariant, useStyles} from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from "moment";
import jobDetailDummyData from './JobDetailsDummyData.json';

export const UpdateInvoice = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const filteredState = state.data

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null)
  const [invoice, setInvoice] = useState(filteredState);
  const [contacts, setContacts] = useState<Array<any>>([])
  const [sites, setSites] = useState<Array<any>>([])

  const { handleSubmit, control, setValue } = useForm();
  const classes = useStyles();
  const [tableKey1, setTableKey1] = useState(0);

  const [jobDetails, setJobDetails] = useState([
    { ...jobDetailDummyData, id: 0 },
  ]);


  const getSites = () => {
    axios
      .get(`${SERVER_IP}site`)
      .then((res) => {
        setSites(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getContactsForSite = (id) => {
    axios
      .get(`${SERVER_IP}contactbysite?SiteID="${id}"`)
      .then((res) => {
        setContacts(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const handleTableInput = async (e, index) => {
    let { name, value } = e.target;
    let jobDetail = jobDetails;
    // console.log(name,value)
    jobDetail[index] = {
      ...jobDetail[index],
      [name]: value,
    }
    setJobDetails([...jobDetail]);
  }

  const checkTableInputRow = (id) => {
    let jobDetail = jobDetails.filter((jobDetail) => jobDetail.id === id)[0];
    return (
      !!jobDetail?.JobNumber &&
      !!jobDetail?.JobDate
    );
  };

  const updateInvoice = async (data) => {

    let JobDetails = jobDetails.filter(c => checkTableInputRow(c.id))

    JobDetails = JobDetails.map(d => {
      d["UpdatedByUserID"] = getCurrentUserID();
      d["UpdatedDateTime"] = 2022;
      delete d["id"]
      return d
    })

    let temp = {
      ...invoice,
      ...data,
      AddedByUserID: getCurrentUserID(),
      AddedDateTime: getDateTime(),
      Amount:amount,
      jobDetails: JobDetails
    }
    console.log(temp)
    delete temp["ContactName"]
    delete temp["SiteName"]
    setLoading(true)
    axios.put(`${SERVER_IP}Invoice/update?InvoiceId=${temp.InvoiceId}`, temp)
      .then((res) => {
        toast.success("Invoice Successfully Updated");
      }).then(() => navigate("/Invoice", {
        state: state
      }))
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(error?.response?.data?.message || "Couldn't Update Invoice");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          toast.error(error?.message || "Couldn't Update Invoice");
        }
      }).finally(() => setLoading(false))
  };


  useEffect(() => {
    getSites()
    getContactsForSite(invoice.SiteID)
    let temp = filteredState?.jobDetails?.map((data, index) => {
      return {
        ...jobDetailDummyData,
        id: index,
        ...data
      }
    }) || [];
    if (temp.length)
      setJobDetails([...temp])
  }, []);


  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(updateInvoice)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Update Invoice"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="InvoiceNumber"
                      control={control}
                      defaultValue={invoice.InvoiceNumber}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Invoice Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteID"
                      control={control}
                      defaultValue={invoice.SiteID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            label="Site"
                            onChange={(e) => {
                              getContactsForSite(e.target.value);
                              onChange(e);
                              setValue("ContactID", null)
                            }}
                          >
                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="ContactID"
                      control={control}
                      defaultValue={invoice.ContactID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Contact</InputLabel>
                          <Select
                            value={value}
                            label="Contact"
                            onChange={onChange}
                          >
                            {contacts.map((e) => (
                              <MenuItem key={e.ContactID} value={e.ContactID}>{e.ContactName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Terms"
                      control={control}
                      defaultValue={invoice.Terms}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Terms"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          inputProps={{ maxLength: 15 }}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="AccountNumber"
                      control={control}
                      defaultValue={invoice.AccountNumber}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Account Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          inputProps={{ maxLength: 15 }}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required', maxLength : { value : 15, message : "Max 15 Chars"} }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Description"
                      control={control}
                      defaultValue={invoice.Description}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextareaAutosize
                          placeholder="Description"
                          minRows={5}
                          maxLength={1024}
                          style={{ width: '100%' }}
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ margin: 'auto' }}>
                  <label>Amount</label>
                    <NumericFormat
                      required
                      className="custom-number-input"
                      placeholder="Enter the Amount"
                      defaultValue={invoice.Amount}
                      onValueChange={(e)=>setAmount(e.formattedValue)}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="InvoiceDate"
                      control={control}
                      defaultValue={invoice.InvoiceDate}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Invoice Date"
                            inputFormat="dd/MM/yyyy"
                            // disableFuture
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              variant={inputFieldVariant}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              {...params} />
                            }
                          />
                        </LocalizationProvider>

                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="DateDue"
                      control={control}
                      defaultValue={invoice.DateDue}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Due Date"
                            inputFormat="dd/MM/yyyy"
                            // disableFuture
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              variant={inputFieldVariant}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              {...params} />
                            }
                          />
                        </LocalizationProvider>

                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="PaidUnpaid"
                      control={control}
                      defaultValue={invoice.PaidUnpaid}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl>
                          <FormLabel>Paid?</FormLabel>
                          <RadioGroup
                            row
                            value={value}
                            onChange={onChange}
                          >
                            <FormControlLabel value={"Completed"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"Unpaid"} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  margin: "10px auto",
                  borderRadius: "10px",
                  padding: 10,
                  border: `2px solid ${PrimaryColor}`,
                  width: '98%'
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      style={{ backgroundColor: PrimaryColor, color: "white" }}
                      variant="h6"
                    >
                      Jobs
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "auto" }}>
                    <Grid item container spacing={1} direction="row">
                      <TableContainer style={{ maxWidth: '98%', overflowX: 'auto' }}>
                        <Table
                          aria-label="customized table"
                          className={classes.table}
                          key={tableKey1}
                        >
                          <TableHead>
                            <TableRow color="primary">
                              <TableCell>Job Number</TableCell>
                              <TableCell>Job Date</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              jobDetails.map((jobDetail, index) => (
                                <TableRow key={index}>

                                  <TableCell style={{ width: 350 }}>
                                    <TextField
                                      variant="outlined"
                                      value={jobDetails[index].JobNumber}
                                      name="JobNumber"
                                      className={classes.dateInput}
                                      onChange={(e) => handleTableInput(e, index)}
                                    />
                                  </TableCell>

                                  <TableCell style={{ width: 350 }}>
                                    <TextField
                                      variant="outlined"
                                      value={!!jobDetails[index].JobDate ? moment(jobDetails[index].JobDate).format('YYYY-MM-DD') : jobDetails[index].JobDate}
                                      name="JobDate"
                                      type="date"
                                      className={classes.dateInput}
                                      onChange={(e) => handleTableInput(e, index)}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                      {jobDetails.length !== 0 && (
                                        <RemoveCircle
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => {
                                            setTableKey1(Math.random());
                                            if (jobDetails.length === 1) {
                                              let temp = {
                                                ...jobDetailDummyData,
                                                id: 0,
                                              };
                                              setJobDetails([temp]);
                                            } else {
                                              let temp = jobDetails;
                                              temp.splice(index, 1);
                                              setJobDetails([...temp]);
                                            }
                                          }}
                                        />
                                      )}

                                      {index === jobDetails.length - 1 &&
                                        checkTableInputRow(jobDetail.id) && (
                                          <AddCircle
                                            style={{ cursor: "pointer", color: PrimaryColor }}
                                            onClick={() => {
                                              let temp = jobDetails;
                                              let newId =
                                                jobDetails[
                                                  jobDetails.length - 1
                                                ].id + 1;
                                              temp.push({
                                                ...jobDetailDummyData,
                                                id: newId,
                                              });
                                              setJobDetails([...temp]);
                                            }}
                                          />
                                        )}
                                    </div>

                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Update Invoice"}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <MuiButton
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault()
                      setLoading(true)
                      axios
                        .post(`${SERVER_IP}Invoice/email`, {
                          InvoiceId: invoice.InvoiceId
                        })
                        .then((res) => {
                          toast.success("Email Sent Successfully to Client")
                        }).catch((err) => {
                          console.log(err)
                          toast.error("Couldn't Send Email...Check if Contact's Email is Correct or Try After Sometime")
                        }).finally(() => setLoading(false))
                    }}
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Send Email"}
                  </MuiButton>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/Invoice", {
                      state: state
                    })}>
                    Back
                  </Button>
                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
