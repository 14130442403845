import React, { FormEvent, useState } from "react";
import {
  AppBar,
  Grid,
  makeStyles,
  Theme,
  Container,
  Toolbar,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import Logo from "../assert/logo_top.png";
import LogoIndex from "../assert/logo_index.jpeg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { SERVER_IP, PrimaryColor } from "../variables";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/reducers/users/usersActions";

const useStyles = makeStyles((theme: Theme) => ({
  appBarStyle: {
    backgroundColor: PrimaryColor,
    boxShadow: "1px",
  },
  textField: {
    width: 280,
  },
  container: {
    padding: theme.spacing(5, 5),
    textAlign: "center",
    maxWidth: "400px",
    marginTop: 30
  },
}));

export const Header = () => {
  const classes = useStyles();
  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBarStyle}
        style={{ marginBottom: 100 }}
      >
        <Toolbar disableGutters>
          <Box ml="10px">
            {/* <img alt="logo" src={Logo} /> */}
            <h3>{"Electronic Pest Control Management System "}</h3>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export const LoginWidget = () => {

  const dispatch = useDispatch();

  const classes = useStyles();
  const [loginRequest, setloginRequest] = useState({
    Login: "",
    Password: "",
  });
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === "Password") {
      loginRequest["Password"] = value;
    } else if (name === "Login") {
      loginRequest["Login"] = value;
    }
    setloginRequest({ ...loginRequest });
  };
  const onSubmit = async (event: FormEvent<Element>): Promise<void> => {
    event.preventDefault();
    setLoading(true)
    axios
      .post(`${SERVER_IP}authentication`, {
        email: `${loginRequest.Login}`,
        password: `${loginRequest.Password}`,
      })
      .then(async (res) => {
        setLoading(false)
        toast.success("Login Success!");
        dispatch(setUserData({
          userId : res.data.userId,
          userName : res.data.username,
          isStaff : res.data.isStaff,
          TitleID : res.data.TitleID,
          accessControlID : res.data.AccessControlID
        }))
        navigate("/Home");
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        toast.error("Login Failed!");
      });
  };
  return (
    <>
      <Header />
      <Container
        maxWidth="xs"
        style={{
          marginTop: 100,
          minWidth: 400,
          backgroundColor: "#fff",
          border: '2px solid #000',
          padding: 50,
          borderRadius: 20,
        }}
      >
        <form onSubmit={onSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item style={{ textAlign: "center" }}>
              <img style={{width : '100%'}} alt="logo" src={LogoIndex} />
            </Grid>
            <Grid item container spacing={1} className={classes.container}>
              {/* <Grid item xs={12} style={{ minHeight: "20px", paddingLeft: 5 }}>
              <div
                style={{
                  paddingLeft: 5,
                  textAlign: "center",
                  backgroundColor: "#337ab7",
                }}
              >
                <IconButton style={{ paddingLeft: 5, textAlign: "center" }}>
                  <AccountCircleIcon style={{ color: "white" }} /> &nbsp;&nbsp;
                  <Typography variant="h6" style={{ color: "white" }}>
                    {" "}
                    Login
                  </Typography>
                </IconButton>
              </div>
            </Grid> */}
              <Grid item spacing={1} container xs={12}>
                <Grid item xs={12}>
                  <TextField
                    onChange={onChange}
                    value={loginRequest.Login}
                    name="Login"
                    label="UserName"
                    autoFocus
                    required
                    variant="outlined"
                    className={classes.textField}
                    // helperText={<span>{errors.email}</span>}
                    // error={errors.email}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={onChange}
                    required
                    value={loginRequest.Password}
                    name="Password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    className={classes.textField}
                    // helperText={<span>{errors.email}</span>}
                    // error={errors.email}
                    size="small"
                  />
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{ width: 170, borderRadius: 20, marginTop: 20 }}
                    onClick={onSubmit}
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              style={{ marginLeft: "auto", marginTop: 20, color: "#037ab7", cursor: 'pointer' }}
              onClick={() => navigate("/Forgot-Password")}
            >
              Forgot Password?
            </Typography>
          </Grid>
        </form>



        <ToastContainer />
      </Container>
      <Container
        maxWidth="xs"
        style={{
          minWidth: 400,
          backgroundColor: "transparent",
        }}
      >
        <Grid>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction='row'>
                  <Grid style={{
                    marginTop: 'auto',
                    marginBottom: 'auto'
                  }} item>
                    <div style={{ color: '#000' }}>
                      Powered by
                    </div>
                  </Grid>
                  <Grid style={{ paddingTop: '5px' }}>
                    <img src={Logo} alt="Konnect" width={"100px"} height={"40px"} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </>
  );
};
