// @ts-nocheck
import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { useState, FormEvent, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { inputFieldVariant, getCurrentUserID, getDateTime, dateTimeFormat, dateFormat } from "../../utils";
import WorkOrderData from "./WorkOrderData.json"
import { DatePicker, LocalizationProvider, DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useForm, Controller } from 'react-hook-form';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  FormHelperText,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Box,
  Chip,
  Stack,
  InputAdornment
} from "@mui/material";

export const CreateWorkOrder = () => {

  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();

  const [workOrder, setWorkOrder] = useState(WorkOrderData);
  const [loading, setLoading] = useState(false)
  const [workers, setWorkers] = useState<any>([]);
  const [workTypes, setWorkTypes] = useState<any>([]);
  const [workStatuses, setWorkStatuses] = useState<any>([]);
  const [POs, setPOs] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [siteZones, setSiteZones] = useState<any>([]);
  const [workNatures, setWorkNatures] = useState<any>([]);
  const [availableWorkers, setAvailableWorkers] = useState<Array<any>>([]);
  const [selectedWorkers, setSelectedWorkers] = useState<Array<any>>([]);
  const [linkedWorkers, setLinkedWorkers] = useState<Array<any>>([]);

  const columns: GridColDef[] = [
    // {
    //   field: 'StaffID',
    //   headerName: 'Worker ID',
    //   width: 100
    // },
    {
      field: 'StaffName',
      headerName: 'Worker Name',
      width: 200
    }
  ];

  const getSiteZones = () => {
    axios
      .get(`${SERVER_IP}sitezone`)
      .then((res) => {
        setSiteZones(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getWorkTypes = () => {
    axios
      .get(`${SERVER_IP}worktype`)
      .then((res) => {

        setWorkTypes(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getWorkStatuses = () => {
    axios
      .get(`${SERVER_IP}workstatus`)
      .then((res) => {

        setWorkStatuses(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const fetchWorkers = async () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}workers`
      )
      .then((res) => {
        setWorkers(res.data);
        setAvailableWorkers(res.data);
      })
      .catch((res) => {
      }).finally(() => setLoading(false))
  };


  const createWorkOrder = async (data) => {
    // console.log(linkedWorkers)
    let temp = {
      ...workOrder,
      ...data,
      UpdatedByUserID: getCurrentUserID(),
      UpdatedDateTime: getDateTime(),
      AssignedWorkers: linkedWorkers,
      AssignedDateTime: dateTimeFormat(workOrder.AssignedDateTime, "YYYY/MM/DD HH:mm:ss"),
      RequestedStartDate: dateFormat(workOrder.RequestedStartDate, "YYYY/MM/DD"),
      RequestedEndDate: dateFormat(workOrder.RequestedEndDate, "YYYY/MM/DD")
    }
    console.log(temp)
    setLoading(true)
    axios.post(`${SERVER_IP}workOrder`, temp)
      .then((res) => {
        console.log(res)
        toast.success("created successfully!");
        navigate("/WorkOrder")
      })
      .catch((res) => {
        console.log(res)
        toast.error("Creation Failed!");
      }).finally(() => setLoading(false))
  }

  const fetchPO = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}po`
      )
      .then(async (res) => {
        let temp = res.data.message
        if (temp.length) {
          setPOs(temp);
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch POs!");
      }).finally(() => setLoading(false))
  };

  const getWorkNatures = () => {
    axios
      .get(`${SERVER_IP}worknature`)
      .then((res) => {

        setWorkNatures(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    fetchPO();
    getWorkTypes();
    getWorkStatuses();
    fetchWorkers();
    getSiteZones();
    getWorkNatures();
  }, []);



  return (

    <>

      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <form onSubmit={handleSubmit(createWorkOrder)}>
            <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    {"Create WorkOrder"}
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto' }}>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="POID"
                      control={control}
                      defaultValue={workOrder.POID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>PO Number</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {[{ POID: "", POnumber: "None" }, ...POs].map((e) => (
                              <MenuItem key={e.POID} value={e.POID}>{e.POnumber}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    // rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="SiteZoneID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site Zone</InputLabel>
                          <Select
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              axios
                                .get(`${SERVER_IP}sitelistbyzone?SiteZoneID=${e.target.value}`)
                                .then((res) => {
                                  setSites(res.data);
                                }).catch((res) => {
                                  console.log(res)
                                });
                            }}
                          >
                            {siteZones.map((e) => (
                              <MenuItem key={e.SiteZoneID} value={e.SiteZoneID}>{e.Description}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="SiteID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="CreatedType"
                      control={control}
                      defaultValue={"Manual"}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Created Type"
                          fullWidth
                          disabled
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="WorkTypeID"
                      control={control}
                      defaultValue={workOrder.WorkTypeID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Work Type</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >

                            {workTypes.map((e) => (
                              <MenuItem key={e.WorkTypeID} value={e.WorkTypeID}>{e.WorkTypeName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="WorkStatusID"
                      control={control}
                      defaultValue={workOrder.WorkStatusID}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Work Status</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >

                            {workStatuses.map((e) => (
                              <MenuItem key={e.WorkStatusID} value={e.WorkStatusID}>{e.WorkStatus}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="WorkNatureID"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>WorkNature</InputLabel>
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            {workNatures.map((e) => (
                              <MenuItem key={e.WorkNatureID} value={e.WorkNatureID}>{e.WorkNature}</MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Requested Start Date"
                        inputFormat="dd/MM/yyyy"
                        value={workOrder.RequestedStartDate || null}
                        onChange={(newValue) => {
                          setWorkOrder({
                            ...workOrder,
                            RequestedStartDate: newValue
                          })
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="RequestedStartDate"
                          name="RequestedStartDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Requested End Date"
                        inputFormat="dd/MM/yyyy"
                        minDate={workOrder.RequestedStartDate || null}
                        value={workOrder.RequestedEndDate || null}
                        onChange={(newValue) => {
                          setWorkOrder({
                            ...workOrder,
                            RequestedEndDate: newValue
                          })
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="RequestedEndDate"
                          name="RequestedEndDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Assigned Date Time"
                        inputFormat="yyyy/MM/dd hh:mm a"
                        value={workOrder.AssignedDateTime || null}
                        onChange={(newValue) => {
                          setWorkOrder({
                            ...workOrder,
                            AssignedDateTime: newValue
                          })
                        }}
                        renderInput={(params) => <TextField
                          variant={inputFieldVariant}
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={3}>

                    <Controller
                      name="WOInvoice"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Invoices"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                      Selected Workers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                      <Box
                        sx={{
                          height: 300,
                          width: 1,
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: PrimaryColor,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.StaffID}
                          rows={selectedWorkers}
                          columns={columns}
                          pageSize={4}
                          onSelectionModelChange={(newSelection) => {
                            console.log(newSelection)
                            if (!!newSelection.length) {
                              console.log(newSelection)
                              let temp = selectedWorkers.filter(worker => newSelection.includes(worker.StaffID))
                              setSelectedWorkers(temp)

                              let linked = temp.map(worker => worker.StaffID)
                              setLinkedWorkers(linked)

                              temp = workers.filter(worker => !linked.includes(worker.StaffID))
                              setAvailableWorkers(temp)
                            }
                            else {
                              setSelectedWorkers([])
                              setLinkedWorkers([])
                              setAvailableWorkers(workers)
                            }
                          }}
                          selectionModel={linkedWorkers}
                          loading={loading}
                          rowsPerPageOptions={[4]}
                          checkboxSelection
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                      Available Workers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                      <Box
                        sx={{
                          height: 300,
                          width: 1,
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: PrimaryColor,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.StaffID}
                          rows={availableWorkers}
                          columns={columns}
                          pageSize={4}
                          onSelectionModelChange={(newSelection) => {
                            // console.log(newSelection)
                            if (!!newSelection.length) {
                              // console.log(newSelection)
                              let temp = workers.filter(worker => worker.StaffID === newSelection[0])
                              // console.log(temp)
                              let data = [...selectedWorkers]
                              // console.log(data)
                              data.push(temp[0])
                              setSelectedWorkers(data)

                              let linked = data.map(worker => worker.StaffID)
                              setLinkedWorkers(linked)
                              temp = workers.filter(worker => !linked.includes(worker.StaffID))
                              setAvailableWorkers(temp)
                            }
                          }}
                          // selectionModel={linkedWorkers}
                          loading={loading}
                          rowsPerPageOptions={[4]}
                          checkboxSelection
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>


              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Create WorkOrder"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/WorkOrder")}>
                    Cancel
                  </Button>
                </Grid>

              </Grid>

              <ToastContainer />
            </Grid>
          </form>
        </Grid>

      </div>
    </>

  );
};
