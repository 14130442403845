
import {
  Grid,
  Typography,
  Button,
  Switch,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import {
  FormHelperText,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  FormLabel, FormControl,
  Box
} from "@mui/material";
import { Button as MuiButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ToastContainer, toast } from "react-toastify";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserID, getDateTime, inputFieldVariant, weekDays } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap'

export const ReportNotification = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [WOReportSelection, setWOReportSelection] = useState(false)
  const [WOReportDay, setWOReportDay] = useState('')
  const [otherReportSelection, setOtherReportSelection] = useState(false)
  const [otherReportFreq, setOtherReportFreq] = useState('')
  const [otherReportDay, setOtherReportDay] = useState('')
  const [expandedPanel, setExpandedPanel] = useState('WO')


  const [contacts, setContacts] = useState<Array<any>>([]);
  const [availableContacts, setAvailableContacts] = useState<Array<any>>([]);
  const [selectedContacts, setSelectedContacts] = useState<Array<any>>([]);
  const [linkedContacts, setLinkedContacts] = useState<Array<any>>([]);


  const columns: GridColDef[] = [
    {
      field: 'ContactName',
      headerName: 'Contact Name',
      width: 200
    },
    {
      field: 'Email1',
      headerName: 'Email',
      width: 200
    },
  ];


  const fetchContact = () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}contact`
      )
      .then(async (res) => {
        setContacts(res.data)
        setAvailableContacts(res.data)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't fetch Contacts!");
      }).finally(() => setLoading(false))
  };

  const generateNotification = async (data) => {

    let temp = {}
    let url = ''

    if (data === "WO") {
      if (!WOReportDay) {
        toast.error("Select a Day")
        return
      }
      // console.log(WOReportDay, WOReportSelection)
      temp = {
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        OnOffSelection: WOReportSelection,
        SelectedDay: WOReportDay
      }
      url = `${SERVER_IP}AutoWorkOrderReports/save`
    }
    else {
      // console.log(otherReportFreq, otherReportDay, otherReportSelection)
      // console.log(selectedContacts)
      if (!otherReportFreq) {
        toast.error("Select Send Frequency")
        return
      }
      else if (!otherReportDay) {
        toast.error("Select a Day")
        return
      }
      else if (!!!selectedContacts.length) {
        toast.error("Select atleast one contact")
        return
      }
      temp = {
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        OnOffSelection: otherReportSelection,
        SelectedDay: otherReportDay,
        WeeklyOrMonthly: otherReportFreq,
        ContactEmailList: selectedContacts.map(d => d.Email1)
      }
      url = `${SERVER_IP}AutoEmailWorkAnyOtherOrderReport/save`
    }

    console.log(temp)
    setLoading(true)
    axios
      .post(
        url,
        temp
      )
      .then((res) => {
        toast.success("Preference Updated");
      })
      .catch((res) => {
        toast.error("Couldn't Create Record");
      }).finally(() => setLoading(false))

  };


  useEffect(() => {
    fetchContact()
  }, []);


  return (

    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />

        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>

          <Accordion expanded={expandedPanel === "WO"} onClick={() => setExpandedPanel("WO")} style={{ width: '100%', borderRadius: 10 }}>
            <AccordionSummary
              style={{ backgroundColor: PrimaryColor, color: 'white', borderRadius: 10 }}
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                <SummarizeIcon style={{ margin: 'auto 5px' }} />
                {"WorkOrder Reports"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Grid container style={{ width: '100%', border: `2px solid ${PrimaryColor}` }}>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={4}>
                    <FormControl>
                      <FormLabel>ON/OFF Selection</FormLabel>
                      <Switch
                        checked={WOReportSelection}
                        onChange={e => setWOReportSelection(e.target.checked)}
                      />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <MuiFormControl fullWidth variant="standard">
                      <MuiInputLabel>Select WeekDays</MuiInputLabel>
                      <MuiSelect
                        value={WOReportDay}
                        onChange={e => setWOReportDay(e.target.value)}
                      >
                        {weekDays.map((e) => (
                          <MuiMenuItem key={e} value={e}>{e}</MuiMenuItem>
                        ))}
                      </MuiSelect>
                    </MuiFormControl>

                  </Grid>

                </Grid>

                <Grid container style={{ marginBottom: '10px' }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ textAlign: "center" }}
                  >
                    <MuiButton
                      disabled={loading}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        generateNotification("WO")
                      }}
                    >
                      {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                      {"Save"}
                    </MuiButton>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={(e) => navigate("/Site")}>
                      Cancel
                    </Button>
                  </Grid> */}

                </Grid>
              </Grid>


            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expandedPanel === "Other"} onClick={() => setExpandedPanel("Other")} style={{ width: '100%', borderRadius: 10 }}>
            <AccordionSummary
              style={{ backgroundColor: PrimaryColor, color: 'white', borderRadius: 10 }}
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                <SummarizeIcon style={{ margin: 'auto 5px' }} />
                {"Any Other Reports"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container style={{ width: '100%', border: `2px solid ${PrimaryColor}` }}>


                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={3}>
                    <FormControl>
                      <FormLabel>ON/OFF Selection</FormLabel>
                      <Switch
                        checked={otherReportSelection}
                        onChange={e => setOtherReportSelection(e.target.checked)}
                      />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <MuiFormControl fullWidth variant="standard">
                      <MuiInputLabel>Frequency</MuiInputLabel>
                      <MuiSelect
                        value={otherReportFreq}
                        onChange={e => setOtherReportFreq(e.target.value)}
                      >
                        {['Weekly', 'Monthly'].map((e) => (
                          <MuiMenuItem key={e} value={e}>{e}</MuiMenuItem>
                        ))}
                      </MuiSelect>
                    </MuiFormControl>

                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <MuiFormControl fullWidth variant="standard">
                      <MuiInputLabel>Select WeekDays</MuiInputLabel>
                      <MuiSelect
                        value={otherReportDay}
                        onChange={e => setOtherReportDay(e.target.value)}
                      >
                        {weekDays.map((e) => (
                          <MuiMenuItem key={e} value={e}>{e}</MuiMenuItem>
                        ))}
                      </MuiSelect>
                    </MuiFormControl>

                  </Grid>

                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                      <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                        Selected Contacts
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Box
                          sx={{
                            height: 300,
                            width: 1,
                            '& .MuiDataGrid-columnHeaders': {
                              backgroundColor: PrimaryColor,
                            },
                          }}
                        >
                          <DataGrid
                            getRowId={(row) => row.ContactID}
                            rows={selectedContacts}
                            columns={columns}
                            pageSize={4}
                            onSelectionModelChange={(newSelection) => {
                              console.log(newSelection)
                              if (!!newSelection.length) {
                                console.log(newSelection)
                                let temp = selectedContacts.filter(contact => newSelection.includes(contact.ContactID))
                                setSelectedContacts(temp)

                                let linked = temp.map(contact => contact.ContactID)
                                setLinkedContacts(linked)

                                temp = contacts.filter(contact => !linked.includes(contact.ContactID))
                                setAvailableContacts(temp)
                              }
                              else {
                                setSelectedContacts([])
                                setLinkedContacts([])
                                setAvailableContacts(contacts)
                              }
                            }}
                            selectionModel={linkedContacts}
                            loading={loading}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                      <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                        Available Contacts
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Box
                          sx={{
                            height: 300,
                            width: 1,
                            '& .MuiDataGrid-columnHeaders': {
                              backgroundColor: PrimaryColor,
                            },
                          }}
                        >
                          <DataGrid
                            getRowId={(row) => row.ContactID}
                            rows={availableContacts}
                            columns={columns}
                            pageSize={4}
                            onSelectionModelChange={(newSelection) => {
                              // console.log(newSelection)
                              if (!!newSelection.length) {
                                // console.log(newSelection)
                                let temp = contacts.filter(contact => contact.ContactID === newSelection[0])
                                // console.log(temp)
                                let data = [...selectedContacts]
                                // console.log(data)
                                data.push(temp[0])
                                setSelectedContacts(data)

                                let linked = data.map(contact => contact.ContactID)
                                setLinkedContacts(linked)
                                temp = contacts.filter(contact => !linked.includes(contact.ContactID))
                                setAvailableContacts(temp)
                              }
                            }}
                            // selectionModel={linkedContacts}
                            loading={loading}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '10px' }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ textAlign: "center" }}
                  >
                    <MuiButton
                      disabled={loading}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        generateNotification("Other")
                      }}
                    >
                      {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                      {"Save"}
                    </MuiButton>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={(e) => navigate("/Site")}>
                      Cancel
                    </Button>
                  </Grid> */}

                </Grid>
              </Grid>


            </AccordionDetails>
          </Accordion>

        </Grid>
        <ToastContainer />
      </div>
    </>

  );
};
