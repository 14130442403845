// @ts-nocheck
import {
  Grid,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import CircularProgress from '@mui/material/CircularProgress';
import { base64Converter, dateFormat, getCurrentUserID, getDateTime, inputFieldVariant, isLoggedUserStaff } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import pdfIcon from '../../assert/pdf_icon.png'
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

export const ECSSummaryReport = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [reportDate, setReportDate] = useState<any>(null);

  const isStaff = isLoggedUserStaff()

  const { handleSubmit, control } = useForm();


  const generateReport = async (data) => {

    if (!!!reportDate) {
      toast.error("Select a Month")
      return
    }
    setLoading(true)
    axios({
      url: `${SERVER_IP}generateExcel/download?referenceMonth=${dateFormat(reportDate, "MM")}&referenceYear=${dateFormat(reportDate, "YYYY")}`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        toast.success("Excel File Generated will be Saved in Your PC's Download Directory", {
          autoClose: 6000,
          bodyStyle: {
            fontSize: 20,
            padding: 6
          }
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ECS_Summary_${dateFormat(reportDate, "MMMM_YYYY")}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((res) => {
        toast.error("Couldn't Generate Report");
      }).finally(() => setLoading(false))

  };

  return (

    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(generateReport)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Generate ECS Summary Report"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>



                  <Grid item xs={12} sm={3}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Select Month"
                        views={['month', 'year']}
                        value={reportDate}
                        onChange={(newValue) => {
                          setReportDate(newValue)
                        }}
                        renderInput={(params) => <TextField
                          variant="standard"
                          id="ReportDate"
                          name="ReportDate"
                          fullWidth
                          {...params} />
                        }
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={3}
                    style={{ textAlign: "center", marginTop: 10 }}
                  >
                    <Button
                      disabled={loading}
                      color="primary"
                      size="small"
                      variant="contained"
                      type="submit"
                    >
                      {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                      {loading ? "Generating Report...Please Wait" : "Generate"}
                    </Button>
                  </Grid>


                </Grid>
              </Grid>

            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
