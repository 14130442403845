//@ts-nocheck
import { useState, useEffect } from 'react';
import axios from 'axios'
import { PrimaryColor, SERVER_IP } from '../../variables';
import { Box, Paper } from '@mui/material';
import TablePlaceholder from '../TablePlaceholder';
import { ToastContainer, toast } from "react-toastify";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {
    Grid,
    Button,
    CircularProgress,
} from "@material-ui/core";
import moment from 'moment'
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import logo from "../../assert/logo_index.jpeg"
import Table from '@mui/material/Table';
import { isSuperUser, isLoggedUserStaff, getCurrentUserID, isProgClaimEligible, checkIfMonthOver } from '../../utils';
import { useNavigate } from 'react-router-dom';
import SignaturePad from './SignaturePad';

export default function ViewDSSDReports() {

    const navigate = useNavigate();

    const [reports, setReports] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [addButtonClicked, setAddButtonClicked] = useState(false);
    const [reportData, setReportData] = useState<any>({});

    const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
    const [another, setAnother] = useState<Date | null>(new Date());

    const isStaff = isLoggedUserStaff()

    const fetchReports = () => {
        setLoading(true)
        axios
            .get(`${SERVER_IP}reportDSSD/get?ContactID=${getCurrentUserID()}&Staff=${isStaff}&month=${moment(selectedMonth).format("MM")}&year=${moment(selectedMonth).format("YYYY")}`)
            .then((res) => {
                // console.log(res.data)
                let t = res.data.data?.map((d, index) => {
                    return {
                        ...d,
                        S_No: index + 1
                    }
                })
                // console.log(t)
                setReports(t);
                setReportData(res.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Couldn't fetch DSSD Reports!");
            }).finally(() => {
                setLoading(false)
            })
    };

    const columns: GridColDef[] = [
        // {
        //     field: 'ReportDSSDID',
        //     headerName: 'Report DSSD ID',
        //     width: 100
        // },
        {
            field: 'SiteName',
            headerName: 'Site',
            width: 200,
        },
        {
            field: 'Monthly_Rate',
            headerName: 'Monthly Rate',
            width: 100
        },
        {
            field: 'SS_A',
            headerName: 'Weekly Site Inspection & Service Report (A)',
            description: 'Weekly Site Inspection & Service Report (A)',
            width: 100,
        },
        {
            field: 'SS_B',
            headerName: 'Clocking Record (B)',
            description: 'Clocking Record (B)',
            width: 100,

        },
        {
            field: 'SS_C',
            headerName: 'Monthly Safety Audit Report (C)',
            description: 'Monthly Safety Audit Report (C)',
            width: 100,

        },
        {
            field: 'SS_D',
            headerName: 'Monthly Update of Technicians and Workers License (D)',
            description: 'Monthly Update of Technicians and Workers License (D)',
            width: 100,

        },
        {
            field: 'SS_E',
            headerName: 'Performance Report Scorecard (E)',
            description: 'Performance Report Scorecard (E)',
            width: 100,

        },
        {
            field: 'SS_F',
            headerName: 'Contract Sum & Detailed of Provisional Sum Utilization (F)',
            description: 'Contract Sum & Detailed of Provisional Sum Utilization (F)',
            width: 100,

        },
        {
            field: 'D_A',
            headerName: 'Deduction (A)',
            description: 'Deduction (A)',
            width: 100,
        },
        {
            field: 'D_B',
            headerName: 'Deduction (B)',
            description: 'Deduction (B)',
            width: 100,
        },
        {
            field: 'D_C',
            headerName: 'Deduction (C)',
            description: 'Deduction (C)',
            width: 100,
        },
        {
            field: 'D_D',
            headerName: 'Deduction (D)',
            description: 'Deduction (D)',
            width: 100,
        },
        {
            field: 'D_E',
            headerName: 'Deduction (E)',
            description: 'Deduction (E)',
            width: 100,
        },
        {
            field: 'D_F',
            headerName: 'Deduction (F)',
            description: 'Deduction (F)',
            width: 100,
        },
        {
            field: 'Total_D',
            headerName: 'Total Deduction',
            width: 100,
        },
        {
            field: 'Report_Month',
            headerName: 'Reported On',
            width: 100
        },
        {
            field: 'Action',
            headerName: 'Action Buttons',
            sortable: false,
            filterable: false,
            disableExport: true,
            width: 200,
            hide: !isSuperUser() || reportData.isAuthorized,
            renderCell: (row) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <Button onClick={(e) => {
                            navigate('/UpdateDSSDReport', {
                                state: row.row,
                            });
                            e.stopPropagation()
                        }} color='primary' variant='contained'>Edit</Button>
                    </div>
                )
            }
        },
    ];

    const exportData = () => {

        const doc = new jsPDF("landscape")

        function addFooters() {
            const pageCount = doc.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.addImage(logo, 'jpeg', 10, 0, 80, 12)
                doc.text(docRefNo, width - 10, 10, { align: 'right' });
                doc.text(`Pest-Pro Management Pte. Ltd.`, 5, height - 5);
                doc.text(`Page ${i}`, width - 20, height - 5);
            }
        }

        const col = [
            { title: "S/N", field: "S_No" },
            { title: "Site Name", field: "SiteName" },
            { title: "Monthly Rate", field: "Monthly_Rate" },
            { title: "SS_A", field: "SS_A" },
            { title: "SS_B", field: "SS_B" },
            { title: "SS_C", field: "SS_C" },
            { title: "SS_D", field: "SS_D" },
            { title: "SS_E", field: "SS_E" },
            { title: "SS_F", field: "SS_F" },
            { title: "Deduction A", field: "D_A" },
            { title: "Deduction B", field: "D_B" },
            { title: "Deduction C", field: "D_C" },
            { title: "Deduction D", field: "D_D" },
            { title: "Deduction E", field: "D_E" },
            { title: "Deduction F", field: "D_F" },
            { title: "Total Deduction", field: "Total_D" },
        ]

        doc.autoTable({
            theme: "grid",
            startY: 35,
            columns: col.map(col => ({ ...col, dataKey: col.field })),
            body: reports,
            styles: {
                halign: 'left',
                border: '2px solid',
                valign: 'middle'
            },
            columnStyles: {
                0: { cellWidth: 10 },
                9: { cellWidth: 10 },
                10: { cellWidth: 10 },
                11: { cellWidth: 10 },
                12: { cellWidth: 10 },
                13: { cellWidth: 10 },
                14: { cellWidth: 10 },
            },
            headStyles: {
                halign: 'center',
                fillColor: PrimaryColor,
                lineColor: [0, 0, 0],
                border: '2px solid',
            },
            head: [
                [
                    {
                        content: 'S/N',
                        styles: { halign: 'center', fillColor: PrimaryColor },
                    },
                    {
                        content: 'Site',
                        styles: { halign: 'center', fillColor: PrimaryColor },
                    },
                    {
                        content: 'Monthly Rate*',
                        styles: { halign: 'center', fillColor: PrimaryColor },
                    },
                    {
                        content: 'Weekly Site Inspection & Service Report (A)',
                        styles: { halign: 'center', cellWidth: 20, fillColor: PrimaryColor },
                    },
                    {
                        content: 'Clocking Record (B)',
                        styles: { halign: 'center', cellWidth: 20, fillColor: PrimaryColor },
                    },
                    {
                        content: 'Monthly Safety Audit Report (C)',
                        styles: { halign: 'center', cellWidth: 20, fillColor: PrimaryColor },
                    },
                    {
                        content: 'Monthly Update of Technicians and Workers License (D)',
                        styles: { halign: 'center', cellWidth: 20, fillColor: PrimaryColor },
                    },
                    {
                        content: 'Performance Report Scorecard (E)',
                        styles: { halign: 'center', cellWidth: 20, fillColor: PrimaryColor },
                    },
                    {
                        content: 'Contract Sum & Detailed of Provisional Sum Utilization (F)',
                        styles: { halign: 'center', cellWidth: 20, fillColor: PrimaryColor },
                    },
                    {
                        content: `\t\t  Deduction (10%)\n_____________________________\n(A)     (B)     (C)     (D)     (E)     (F)\n2%     2%     1%     2%     1%     2%`,
                        colSpan: 6,
                        styles: { halign: 'left', fillColor: PrimaryColor },
                    },
                    {
                        content: 'Total Deduction\n (A + B + C + D + E + F)',
                        styles: { halign: 'center', fillColor: PrimaryColor },
                    },
                ],
            ],
        })

        let month = moment(selectedMonth).format("MMMM YYYY")

        const docRefNo = `DSSD-${moment(selectedMonth).format("YYYYMM")}`

        doc.text(`DSSD Report - ${month}`, 110, 25)


        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()

        let finalY = doc.lastAutoTable.finalY + 5; // The y position on the page

        doc.setFontSize(7).text(15, finalY, "* Monthly Rate includes Mosquito Control, Termite Control and Common Pests.")

        finalY = finalY + 15;

        if (finalY > height - 30) {
            doc.addPage()
            finalY = 30;
        }

        doc.setFontSize(10).text(20, finalY, "Acknowledged By")
        finalY = finalY + 5
        doc.addImage(reportData.authImageUrl, 'jpeg', 20, finalY, 80, 15)
        // doc.line(20, finalY, 80, finalY);
        finalY = finalY + 20
        doc.setFontSize(15).text(20, finalY, reportData?.SignedBy || "")
        finalY = finalY + 5
        doc.setFontSize(10).text(20, finalY, `Date : ${moment(new Date()).format("DD/MM/YYYY")}`)

        finalY = finalY + 5


        doc.text("Pest-Pro Management Pte. Ltd.", width / 2, finalY, { align: 'center' })
        finalY = finalY + 5
        doc.text("3A International Business Park,#11-05 ICON@IBP,", width / 2, finalY, { align: 'center' })
        finalY = finalY + 5
        doc.text("Singapore 609935", width / 2, finalY, { align: 'center' })
        addFooters()
        doc.save(`${docRefNo}.pdf`)
    }

    const handleModalClose = () => {
        setAddButtonClicked(false)
    }

    useEffect(() => {
        // document.getElementById("ppp")?.click()
    }, [])


    return (

        <Box>

            <div style={{ padding: '10px 20px', maxWidth: '90%' }}>
                <Paper style={{ borderRadius: '10px', maxWidth: 'inherit' }}>
                    <Grid container spacing={3} style={{ padding: '10px' }}>

                        <Grid item xs={12} sm={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Month"
                                    views={['month', 'year']}
                                    value={selectedMonth}
                                    onChange={(newValue) => {
                                        setSelectedMonth(newValue)
                                    }}
                                    renderInput={(params) => <MuiTextField
                                        variant="outlined"
                                        id="selectedMonth"
                                        name="selectedMonth"
                                        fullWidth
                                        {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                style={{ marginTop: 10 }}
                                disabled={loading}
                                color="primary"
                                size="large"
                                variant="contained"
                                onClick={() => fetchReports()}
                            >
                                {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                {"Get Reports"}
                            </Button>
                        </Grid>

                    </Grid>
                </Paper>

                <Paper style={{ borderRadius: '10px', marginTop: '30px', width: '100%', overflowX: 'auto' }}>
                    {(loading) ?
                        <TablePlaceholder />
                        :
                        reports?.length > 0 ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <Toolbar
                                        sx={{
                                            pl: { sm: 2 },
                                            pr: { xs: 1, sm: 1 },
                                            backgroundColor: '#d9dffd',
                                        }}
                                    >
                                        <Typography
                                            sx={{ flex: '1 1 100%' }}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Summary Of DSSD Reports - {moment(selectedMonth).format('MMMM YYYY')}
                                        </Typography>
                                        <Typography
                                            sx={{ marginLeft: 'auto' }}
                                        >
                                            <Button id="ppp" color="primary" variant='contained' onClick={() => exportData()}>Export</Button>

                                        </Typography>
                                    </Toolbar>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ height: 'auto', width: '100%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Box
                                            sx={{
                                                width: 1,
                                                '& .MuiDataGrid-columnHeaders': {
                                                    backgroundColor: PrimaryColor,
                                                },
                                                '& .red': {
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    fontWeight: '600',
                                                },
                                                '& .lightgreen': {
                                                    backgroundColor: 'yellow',
                                                    color: 'white',
                                                    fontWeight: '600',
                                                },
                                            }}
                                        >
                                            <DataGrid
                                                getRowId={(row) => row.ReportDSSDID}
                                                rows={reports}
                                                columns={columns}
                                                pageSize={5}
                                                loading={loading}
                                                autoHeight
                                                rowsPerPageOptions={[5]}
                                            />
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item sm={12} md={6}>

                                </Grid>
                                <Grid style={{ textAlign: 'center', margin: 'auto' }} item sm={12} md={6}>
                                    {
                                        !!reportData.authImageUrl &&
                                        <div>
                                            <img
                                                src={reportData.authImageUrl}
                                                alt="Sign"
                                                width="80%"
                                            />
                                            <div style={{ textAlign: 'center', margin: 'auto', fontSize: 26, fontWeight: 'bold', paddingBottom: 10 }}>Signed By : {reportData.SignedBy}</div>
                                        </div>

                                    }
                                    {
                                        !isStaff && !!!reportData.isAuthorized &&
                                        checkIfMonthOver(another) && isProgClaimEligible() &&
                                        <Button
                                            color="primary"
                                            variant='contained'
                                            onClick={() => {
                                                setAddButtonClicked(true)
                                            }}
                                        >
                                            {"Authorize"}
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                            :
                            <h2 style={{ textAlign: 'center' }}>No Data Matches</h2>
                    }

                </Paper>

                <SignaturePad
                    show={addButtonClicked}
                    handleClose={() => handleModalClose()}
                    selectedMonth={selectedMonth}
                    fetchReports={() => fetchReports()}
                    reportType="DSSD"
                />
            </div>

            <ToastContainer />
        </Box>

    );
}
