let initialState = {
  isStaff : false,
  userId : null,
  userName : null,
  sideMenu : ""
};

const userReducer = (state = initialState, action) => {

  switch (action.type) {

    case "LOGOUT" : {
      return initialState
    }

    case "SET_USER_DATA" : 
      return{
        ...state,
        ...action.payload
    };    

    case "SET_SIDEMENU" : 
      return{
        ...state,
        sideMenu : action.payload
    };    

    
    default : return state
  }

}

export {userReducer}
