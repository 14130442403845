//@ts-nocheck
import {
  FormControlLabel,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { NumericFormat } from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserID, getDateTime, inputFieldVariant } from "../../utils";
import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


export const CreateQuotation = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(null)
  const [contacts, setContacts] = useState<Array<any>>([])
  const [sites, setSites] = useState<Array<any>>([])
  const [selectedContact, setSelectedContact] = useState<any>(null);

  const { handleSubmit, control, setValue } = useForm();


  const getSites = () => {
    axios
      .get(`${SERVER_IP}site`)
      .then((res) => {
        setSites(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getContactsForSite = (id) => {
    axios
      .get(`${SERVER_IP}contactbysite?SiteID="${id}"`)
      .then((res) => {
        setContacts(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };


  const createQuotation = async (data) => {

    let temp = {
      ...data,
      AddedByUserID: getCurrentUserID(),
      AddedDateTime: getDateTime(),
      Amount:amount
      // QuotationDate : dateFormat(data.QuotationDate, "YYYY/MM/DD"),
    }
    console.log(temp)
    setLoading(true)
    axios
      .post(
        `${SERVER_IP}SalesQuotation/save`,
        temp
      )
      .then((res) => {
        toast.success("Quotation Successfully Created");
      }).then(() => navigate("/Quotation"))
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(error?.response?.data?.message || "Couldn't Create Quotation");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          toast.error(error?.message || "Couldn't Create Quotation");
        }
      }).finally(() => setLoading(false))

  };


  useEffect(() => {
    getSites()
    // document.getElementById('ppp')?.click()
  }, []);


  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(createQuotation)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Add New Quotation"}
                  </Typography>
                </Grid>

                <Grid container spacing={3} style={{ margin: 'auto' }}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="QuotationNumber"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Quotation Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SiteID"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Site</InputLabel>
                          <Select
                            value={value}
                            label="Site"
                            onChange={(e) => {
                              getContactsForSite(e.target.value);
                              onChange(e);
                              setValue("ContactID", null)
                              setSelectedContact(null)
                            }}
                          >
                            {sites.map((e) => (
                              <MenuItem key={e.SiteID} value={e.SiteID}>{e.SiteName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={2}>

                    <Controller
                      name="ContactID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Contact</InputLabel>
                          <Select
                            value={value}
                            label="Contact"
                            onChange={(e) => {
                              let contact = contacts.filter(d => d.ContactID === e.target.value)[0]
                              setSelectedContact(contact)
                              onChange(e);
                            }}
                          >
                            {contacts.map((e) => (
                              <MenuItem key={e.ContactID} value={e.ContactID}>{e.ContactName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Button
                      color="primary"
                      style={{ marginTop: 10 }}
                      disabled={!!!selectedContact}
                      variant="contained"
                      onClick={(e) => {
                        navigate('/UpdateContact', {
                          state: {
                            data: selectedContact,
                            queryOptions: {},
                            pageSize: 5,
                            currentPage: 1,
                            url: "/GenerateQuotation"
                          }
                        });
                        e.stopPropagation()
                      }}
                    >
                      Details
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Validity"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Validity"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          inputProps={{ maxLength: 15 }}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="AccountNumber"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Account Number"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          inputProps={{ maxLength: 15 }}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required', maxLength : { value : 15, message : "Max 15 Chars"} }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Description"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextareaAutosize
                          placeholder="Description"
                          minRows={5}
                          maxLength={1024}
                          style={{ width: '100%' }}
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ margin: 'auto' }}>
                  <label>Amount</label>
                    <NumericFormat
                      required
                      className="custom-number-input"
                      placeholder="Enter the Amount"
                      onValueChange={(e)=>setAmount(e.formattedValue)}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="QuotationDate"
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Quotation Date"
                            inputFormat="dd/MM/yyyy"
                            // disableFuture
                            value={value || null}
                            onChange={onChange}
                            renderInput={(params) => <TextField
                              variant={inputFieldVariant}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              {...params} />
                            }
                          />
                        </LocalizationProvider>

                      )}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '10px' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                    {"Create Quotation"}
                  </Button>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "center" }}
                >
                  <MuiButton
                    color="primary"
                    size="small"
                    variant="contained"
                    id="ppp"
                    onClick={(e) => sendMail()}>
                    Generate Report
                  </MuiButton>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => navigate("/Quotation")}>
                    Cancel
                  </Button>

                </Grid>

              </Grid>
            </form>

            <ToastContainer />
          </Grid>
        </Grid>

      </div>
    </>

  );
};
