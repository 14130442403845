import {
  Grid,
} from "@material-ui/core";
import { Sidebar } from "../Sidebar";
import QuotationTable from "./QuotationTable";

export const QuotationPage = () => {
  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <QuotationTable />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
