// @ts-nocheck
import {
  Grid,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import axios from "axios";
import React, { FormEvent, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { getCurrentUserID, getDateTime, inputFieldVariant } from "../../utils";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ContactData from "./ContactData.json"
import { useForm, Controller } from 'react-hook-form';
import {
  FormHelperText,
  Box,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";

const columns: GridColDef[] = [
  {
    field: 'SiteID',
    headerName: 'Site ID',
    width: 70
  },
  {
    field: 'SiteName',
    headerName: 'Site Name',
    width: 200
  },
  {
    field: 'SiteZone',
    headerName: 'Site Zone',
    width: 130
  },
  {
    field: 'SiteType',
    headerName: 'Site Type',
    width: 230
  },
  {
    field: 'SiteStatus',
    headerName: 'Site Status',
    width: 130
  }
];

export const CreateContact = () => {

  const { handleSubmit, control } = useForm();
  const [contactType, setContactType] = useState<any>([])
  const [sites, setSites] = useState<Array<any>>([]);
  const [availableSites, setAvailableSites] = useState<Array<any>>([]);
  const [selectedSites, setSelectedSites] = useState<Array<any>>([]);
  const [linkedSites, setLinkedSites] = useState<Array<any>>([]);
  const [companies, setCompanies] = useState<Array<any>>([]);

  const fetchSite = async () => {
    setLoading(true)
    axios
      .get(
        `${SERVER_IP}site`
      )
      .then((res) => {
        setSites(res.data);
        setAvailableSites(res.data)
      })
      .catch((res) => {
      }).finally(() => setLoading(false))
  };


  const getCompanies = () => {
    axios
      .get(`${SERVER_IP}company/getAll`)
      .then((res) => {
        setCompanies(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [salutations, setSalutations] = useState<any>([]);
  const [contact, setContact] = useState(ContactData);

  const createContact = async (data) => {

    let LinkedSites = linkedSites.map((site) => {
      return {
        AddedByUserID: getCurrentUserID(),
        AddedDateTime: getDateTime(),
        SiteID: site
      }
    })

    let temp = {
      ...data,
      AddedByUserID: getCurrentUserID(),
      AddedDateTime: getDateTime(),
      LinkedSites: LinkedSites
    }
    delete temp["BillingAddress1"]
    delete temp["BillingAddress2"]
    delete temp["BillingPostCode"]
    // console.log(temp)
    setLoading(true)
    axios
      .post(`${SERVER_IP}contact`, temp)
      .then((res) => {
        toast.success("Contact Successfully Created");
        navigate('/Contact')
      })
      .catch((err) => {
        console.log(err)
        toast.error("Couldn't Create Contact!");
      }).finally(() => setLoading(false))
  };

  const fetchContactType = () => {
    axios
      .get(`${SERVER_IP}ContactType`)
      .then((res) => {
        setContactType(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  const getSalutations = () => {
    axios
      .get(`${SERVER_IP}saluation`)
      .then((res) => {
        setSalutations(res.data);
      }).catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    fetchContactType();
    getSalutations();
    fetchSite();
    getCompanies()
  }, []);
  return (
    <>
      <div style={{ marginTop: 80, display: 'flex' }}>
        <Sidebar />
        <Grid container direction="column" spacing={1} style={{ padding: '10px 50px 10px 30px' }}>
          <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>
            <form onSubmit={handleSubmit(createContact)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                    <AccountCircleIcon style={{ margin: 'auto 5px' }} />
                    {"Add New Contact"}
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ margin: 'auto', paddingBottom: '10px' }} >
                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="SalutationID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Salutation</InputLabel>
                          <Select
                            id="Salutation"
                            value={value}
                            label="Salutation"
                            onChange={onChange}
                          >

                            {salutations.map((e) => (
                              <MenuItem key={e.SalutationID} value={e.SalutationID}>{e.Salutation}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="ContactName"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Contact Name"
                          fullWidth
                          variant={inputFieldVariant}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <Controller
                      name="ContactTypeID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Contact Type</InputLabel>
                          <Select
                            id="ContactTypeID"
                            value={value}
                            label="Contact Type"
                            onChange={onChange}
                          >

                            {contactType.map((e) => (
                              <MenuItem key={e.ContactTypeID} value={e.ContactTypeID}>{e.ContactType}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="Mobile"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Mobile Number"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="Telephone"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Telephone"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="Status"
                      control={control}
                      defaultValue="Active"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Contact Status</InputLabel>
                          <Select
                            value={value}
                            label="Contact Status"
                            onChange={onChange}
                          >
                            {["Active", "InActive"].map((e) => (
                              <MenuItem key={e} value={e}>{e}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="CompanyID"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl variant={inputFieldVariant} fullWidth error={!!error}>
                          <InputLabel>Company</InputLabel>
                          <Select
                            value={value}
                            onChange={e => {
                              let temp = companies.filter(d => d.CompanyID === e.target.value)[0]
                              setContact({
                                ...contact,
                                BillingAddress1: temp.BillingAddress1,
                                BillingAddress2: temp.BillingAddress2,
                                BillingPostCode: temp.BillingPostCode
                              })
                              onChange(e)
                            }}
                          >

                            {companies.map((e) => (
                              <MenuItem key={e.CompanyID} value={e.CompanyID}>{e.CompanyName}</MenuItem>
                            ))}

                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Billing Address 1"
                      variant={inputFieldVariant}
                      value={contact.BillingAddress1}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Email1"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Email 1"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <TextField
                      label="Billing Address 2"
                      variant={inputFieldVariant}
                      value={contact.BillingAddress2}
                      disabled
                      fullWidth
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="Email2"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Email 2"
                          variant={inputFieldVariant}
                          value={value}
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Post Code"
                      variant={inputFieldVariant}
                      value={contact.BillingPostCode}
                      disabled
                      fullWidth
                    />
                  </Grid>


                  <Grid container>
                    <Grid item xs={12}>
                      <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                        Selected Sites
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Box
                          sx={{
                            height: 300,
                            width: 1,
                            '& .MuiDataGrid-columnHeaders': {
                              backgroundColor: PrimaryColor,
                            },
                          }}
                        >
                          <DataGrid
                            getRowId={(row) => row.SiteID}
                            rows={selectedSites}
                            columns={columns}
                            pageSize={4}
                            onSelectionModelChange={(newSelection) => {
                              if (!!newSelection.length) {
                                let temp = selectedSites.filter(site => newSelection.includes(site.SiteID))
                                setSelectedSites(temp)

                                let linked = temp.map(site => site.SiteID)
                                setLinkedSites(linked)

                                temp = sites.filter(site => !linked.includes(site.SiteID))
                                setAvailableSites(temp)
                              }
                              else {
                                setSelectedSites([])
                                setLinkedSites([])
                                setAvailableSites(sites)
                              }
                            }}
                            selectionModel={linkedSites}
                            loading={loading}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <Typography style={{ backgroundColor: PrimaryColor, color: 'white' }} variant="h6" gutterBottom>
                        Available Sites
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 300, width: '90%', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Box
                          sx={{
                            height: 300,
                            width: 1,
                            '& .MuiDataGrid-columnHeaders': {
                              backgroundColor: PrimaryColor,
                            },
                          }}
                        >
                          <DataGrid
                            getRowId={(row) => row.SiteID}
                            rows={availableSites}
                            columns={columns}
                            pageSize={4}
                            onSelectionModelChange={(newSelection) => {
                              if (!!newSelection.length) {
                                let temp = sites.filter(site => site.SiteID === newSelection[0])
                                let data = [...selectedSites]
                                data.push(temp[0])
                                setSelectedSites(data)

                                let linked = data.map(site => site.SiteID)
                                setLinkedSites(linked)
                                temp = sites.filter(site => !linked.includes(site.SiteID))
                                setAvailableSites(temp)
                              }
                            }}
                            // selectionModel={linkedSites}
                            loading={loading}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: '10px' }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                        {"Create Contact"}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button color="secondary" size="small" variant="contained" onClick={(e) => navigate("/Contact")}>
                        Cancel
                      </Button>
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
            </form>
          </Grid>
          <ToastContainer />
        </Grid>
      </div>
    </>
  );
};
