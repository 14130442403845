import {
    Grid
} from "@material-ui/core";
import axios from "axios";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Fade, Modal, CircularProgress } from '@mui/material';
import { SERVER_IP, PrimaryColor } from "../../variables";
import { Sidebar } from "../Sidebar";
import EditIcon from '@mui/icons-material/Edit';
import { Box, Paper, Toolbar, Typography, TextField } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { dateFormat, getCurrentUserID, getDateTime, inputFieldVariant, isSuperUser } from "../../utils";
import TablePlaceholder from "../TablePlaceholder";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxWidth: '90%',
    maxHeight: '90vh'
};

const defaultData = {
    SiteZoneID: "",
    Description: "",
    SiteZoneStatus: "Active"
}

export const SiteZone = () => {

    const [loading, setLoading] = useState(false)
    const [addButtonClicked, setAddButtonClicked] = useState(false)
    const [editButtonClicked, setEditButtonClicked] = useState(false)
    const [pageSize, setPageSize] = useState(5)
    const [siteZones, setSiteZones] = useState<Array<any>>([]);
    const [newName, setNewName] = useState<any>("");
    const [SiteZoneStatus, setSiteZoneStatus] = useState<any>(defaultData.SiteZoneStatus);
    const [selectedSiteZone, setSelectedSiteZone] = useState<any>(defaultData);


    const columns: GridColDef[] = [
        {
            field: 'SiteZoneID',
            headerName: 'ID',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            field: 'Description',
            headerName: 'Site Zone Name',
            width: 200,
            editable: true,
            flex: 1,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Site Status',
            field: 'SiteZoneStatus',
            width: 200,
            renderCell: (row) => row.value || "NA"
        },
        {
            headerName: 'Added On',
            field: 'AddedDateTime',
            width: 200,
            renderCell: (row) => row.value ? dateFormat(row.value) : "NA"
        },
        {
            field: 'Action',
            headerName: 'Action Buttons',
            sortable: false,
            filterable: false,
            width: 300,
            hide: !isSuperUser(),
            align: 'center',
            headerAlign: 'center',
            renderCell: (row) => {
                return (
                    <div key={row.id} style={{ display: 'flex' }}>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            style={{ marginTop: '15px', marginRight: '15px' }}
                            startIcon={<EditIcon />}
                            onClick={() => {
                                setSelectedSiteZone(row.row)
                                setEditButtonClicked(true)
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            color="error"
                            size="small"
                            variant="contained"
                            style={{ marginTop: '15px' }}
                            onClick={() => {
                                if (window.confirm("Are You Sure?")) {
                                    deleteSiteZone(row.id)
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                )
            }
        },
    ];

    const getSiteZones = () => {
        setLoading(true)
        axios
            .get(`${SERVER_IP}sitezone`)
            .then((res) => {
                setSiteZones(res.data);
            }).catch((err) => {
                console.log(err)
            }).finally(() => setLoading(false))
    };

    const createSiteZone = () => {
        if (!newName) {
            toast.error("Enter a Name")
            return
        }
        setLoading(true)
        let temp = {
            Description: newName,
            SiteZoneStatus: SiteZoneStatus,
            AddedByUserID: getCurrentUserID(),
            AddedDateTime: getDateTime(),
        }
        axios
            .post(
                `${SERVER_IP}sitezone`,
                temp
            )
            .then((res) => {
                toast.success("Created Successfully!");
                getSiteZones()
            })
            .catch((err) => {
                console.log(err)
                toast.error("Creation Failed!");
            }).finally(() => {
                setLoading(false)
                setAddButtonClicked(false)
            })
    };

    const updateSiteZone = async () => {

        if (!selectedSiteZone.Description) {
            toast.error("Enter a Name")
            return
        }

        setLoading(true)
        let temp = {
            ...selectedSiteZone,
            AddedByUserID: getCurrentUserID(),
            AddedDateTime: getDateTime(),
        }
        // console.log(temp)
        axios.put(`${SERVER_IP}sitezone?SiteZoneID=${temp.SiteZoneID}`, temp)
            .then((res) => {
                toast.success("Updated Successfully!");
                getSiteZones()
            })
            .catch((res) => {
                toast.error("Updation Failed!");
            }).finally(() => {
                handleModalClose()
                setLoading(false)
            })

    }

    const deleteSiteZone = async (id) => {
        setLoading(true)
        axios.delete(`${SERVER_IP}sitezone?SiteZoneID=${id}`)
            .then((res) => {
                toast.success("Deleted Successfully!");
                getSiteZones()
            })
            .catch((res) => {
                toast.error("Couldn't Delete!");
            }).finally(() => setLoading(false))
    }

    const handleModalClose = () => {
        setAddButtonClicked(false)
        setEditButtonClicked(false)
    }

    useEffect(() => {
        getSiteZones();
    }, []);

    return (
        <>
            <div style={{ marginTop: 80, display: 'flex' }}>
                <Sidebar />
                <Grid container spacing={1} style={{ padding: '10px 30px 10px 30px' }}>
                    <Grid container spacing={3} style={{ margin: '10px auto', borderRadius: '10px', border: `2px solid ${PrimaryColor}` }}>

                        <Grid container style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                            <Box sx={{ width: '100%' }}>
                                {(loading) ?
                                    <TablePlaceholder />
                                    :
                                    <div style={{ padding: '10px 20px' }}>
                                        <Paper style={{ width: '100%', borderRadius: '10px', overflowX: 'auto' }}>
                                            <Toolbar
                                                sx={{
                                                    pl: { sm: 2 },
                                                    pr: { xs: 1, sm: 1 },
                                                    backgroundColor: '#d9dffd',
                                                }}
                                            >
                                                <Typography
                                                    sx={{ flex: '1 1 50%' }}
                                                    variant="h6"
                                                    id="tableTitle"
                                                    component="div"
                                                >
                                                    Site Zone
                                                </Typography>
                                                <Typography
                                                    sx={{ marginLeft: 'auto' }}
                                                >
                                                    <Button
                                                        color="success"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => setAddButtonClicked(true)}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add New Site Zone
                                                    </Button>
                                                </Typography>
                                            </Toolbar>
                                            <Box
                                                sx={{
                                                    '& .MuiDataGrid-columnHeaders': {
                                                        backgroundColor: PrimaryColor,
                                                        color: 'white'
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    autoHeight
                                                    getRowId={(row) => row.SiteZoneID}
                                                    rows={siteZones}
                                                    columns={columns}
                                                    pageSize={pageSize}
                                                    rowsPerPageOptions={[5, 10, 15, siteZones.length > 100 ? 100 : siteZones.length]}
                                                    // checkboxSelection
                                                    disableSelectionOnClick
                                                    onPageSizeChange={(size) => setPageSize(size)}
                                                />
                                            </Box>

                                        </Paper>

                                    </div>
                                }
                                <ToastContainer />
                            </Box>

                        </Grid>

                        <ToastContainer />
                    </Grid>
                </Grid>

                <Modal
                    open={editButtonClicked}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={editButtonClicked}>
                        <Box sx={modalStyle}>
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    margin: "10px auto",
                                    borderRadius: "10px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        style={{ backgroundColor: PrimaryColor, color: "white" }}
                                        variant="h4"
                                        gutterBottom
                                    >
                                        {"Update Site Zone"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Site Zone ID"
                                        variant={inputFieldVariant}
                                        value={selectedSiteZone.SiteZoneID}
                                        fullWidth
                                        disabled
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Site Zone Name"
                                        variant={inputFieldVariant}
                                        value={selectedSiteZone.Description}
                                        fullWidth
                                        required
                                        onChange={(e) => setSelectedSiteZone({
                                            ...selectedSiteZone,
                                            Description: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Site Zone Status"
                                        variant={inputFieldVariant}
                                        value={selectedSiteZone.SiteZoneStatus}
                                        fullWidth
                                        onChange={(e) => setSelectedSiteZone({
                                            ...selectedSiteZone,
                                            SiteZoneStatus: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Button
                                            disabled={loading}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => updateSiteZone()}
                                        >
                                            {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                            Update
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Button
                                            color="secondary"
                                            size="small"
                                            variant="contained"
                                            onClick={(e) => handleModalClose()}>
                                            Cancel
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Box>
                    </Fade>
                </Modal>


                <Modal
                    open={addButtonClicked}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={addButtonClicked}>
                        <Box sx={modalStyle}>
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    margin: "10px auto",
                                    borderRadius: "10px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        style={{ backgroundColor: PrimaryColor, color: "white" }}
                                        variant="h4"
                                        gutterBottom
                                    >
                                        {"Add Site Zone"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Site Zone Name"
                                        variant={inputFieldVariant}
                                        value={newName}
                                        fullWidth
                                        onChange={(e) => setNewName(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Site Status"
                                        variant={inputFieldVariant}
                                        value={SiteZoneStatus}
                                        fullWidth
                                        onChange={(e) => setSiteZoneStatus(e.target.value)}
                                    />
                                </Grid>
                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Button
                                            disabled={loading}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => createSiteZone()}
                                        >
                                            {loading && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
                                            Add
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Button
                                            color="secondary"
                                            size="small"
                                            variant="contained"
                                            onClick={(e) => handleModalClose()}>
                                            Cancel
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Box>
                    </Fade>
                </Modal>


            </div>
        </>

    );
};
